import React, { Component } from 'react';
import withAnimateOnPropChange from './propChangeAnimation1'
import Animation from './animation'
import zIndex from '@material-ui/core/styles/zIndex';

class ViewHolder extends Component {

  render () {
    let ChildComponent = this.props.component
    return (
      <div style={{pointerEvents : this.props.animating ? 'none' : 'all'}}>
        <ChildComponent {...this.props} key={this.props.componentKey}/>
      </div>
    )
  }
}

export default withAnimateOnPropChange(ViewHolder);
