import React from "react";
import ClampLines from "react-clamp-lines";
import styles from "../css-modules/news.module.css";
import Pagination from "@material-ui/lab/Pagination";
import placeholder from "../resources/placeholder.jpg";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import LazyLoad from "react-lazy-load";
// import Image from 'react-graceful-image';

function PostBlock(props) {
  // console.log(props)
  const parseHeading = () => {
    const parser = new DOMParser();
    const parsedHeading = parser.parseFromString(props.heading, "text/html")
      .body.textContent;
    return parsedHeading;
  };
  const convertToFriendlyDate = (dateString) => {
    if (dateString) {
      const [day, month, year] = dateString.split("/"); // Split the date string into its components
      const date = new Date(`${year}-${month}-${day}`); // Create a new Date object using the components

      // Format the date in a friendly format
      const options = { year: "numeric", month: "long", day: "numeric" };
      const friendlyDate = date.toLocaleDateString(undefined, options);

      return friendlyDate;
    }
  };
  return (
    <div className={styles.blockContainer}>
      <div className={styles.blockImg}>
        <Link
          to={{ pathname: `/post/${props.id}`, postDetails: props.details }}
        >
          {props.image.length > 0 ? (
            <img src={props.image.replace(/fit=\d*%/)} alt="post" />
          ) : (
            <img src={placeholder} alt="post" />
          )}
        </Link>
        <div className={styles.blockTheme}>
          <p className={styles.block_theme_text}>{props.theme}</p>
          <p className={styles.block_date_text}>
            {/* {props.date} */}
            {convertToFriendlyDate(props.date)}
          </p>
        </div>
      </div>
      <div className={styles.blockContent}>
        <Link
          style={{ textDecoration: "none", color: "inherit" }}
          to={{ pathname: `/post/${props.id}`, postDetails: props.details }}
        >
          <h1 className={styles.blockHeader}>
            {/* {props.heading} */}
            {parseHeading()}
          </h1>
          <h2 className={styles.blockSubHeader}>{props.subHeading}</h2>
          <div className={styles.blockPara}>
            <ClampLines
              text={props.text + "..."}
              id={props.id}
              lines={3}
              ellipsis="..."
              innerElement="p"
              buttons={false}
            />
          </div>
        </Link>
      </div>
    </div>
  );
}

function SkeletonPostBlock(props) {
  return (
    <div className={styles.blockContainer}>
      <div className={styles.blockImgSkeleton}>
        <Skeleton height={130} />
      </div>
      <div className={styles.blockContent}>
        <h1 className={styles.blockSkeletonHeader}>
          <Skeleton count={window.innerWidth < 700 ? 1 : 2} />
        </h1>
        <div className={styles.blockSkeletonPara}>
          <Skeleton count={window.innerWidth < 700 ? 3 : 4} />
        </div>
      </div>
    </div>
  );
}

function PostsSection(props) {
  return (
    <div className={styles.blockSection}>
      {props.loadingPosts
        ? new Array(10).fill(null).map((d, i) => {
            return (
              <div key={i}>
                <SkeletonPostBlock />
                <div className={styles.post_block_separator}></div>
              </div>
            );
          })
        : props.posts.map((d, i) => {
            return (
              <div key={d.id}>
                <PostBlock {...d} details={d} />
                <div className={styles.post_block_separator}></div>
              </div>
            );
          })}
      {!props.loadingPosts && props.posts.length === 0 && (
        <h1>No Results Found</h1>
      )}
      {props.posts.length > 0 && (
        <Pagination
          size="small"
          page={props.currentPage}
          onChange={(e, p) => {
            props.setPage(p);
          }}
          count={props.totalPages}
          shape="rounded"
        />
      )}
    </div>
  );
}

export default PostsSection;
