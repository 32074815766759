import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import "./App.css";
import loadPostsMixin from "./mixins/loadPostsMixin";
import Home from "./views/home";
import About from "./views/about";
import Publications from "./views/publications";
import GallupInternational from "./views/gallupInternational";
import WhatWeDo from "./views/gallup";
import News from "./views/news";
import DataVis from "./views/datavis";
import Contact from "./views/contact";
import Post from "./views/post";
import SearchResults from "./views/searchResults";
import Topic from "./views/topic";
import ViewHolder from "./components/viewHolder";
import axios from "axios";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import GallupWhatsAppPopup from "./components/GallupWhatsAppPopup";

window.propArr = [];
function getHierarchyObject(dataArray, idDataMap) {
  let hierarchyObject = dataArray.reduce((acc, d) => {
    if (d.parent === 0) {
      acc[d.id] = {};
    }
    return acc;
  }, {});

  dataArray.forEach((d) => {
    let parentId = d.parent;
    if (parentId !== 0) {
      let grandParentId = idDataMap[parentId].parent;
      while (grandParentId !== 0) {
        parentId = grandParentId;
        grandParentId = idDataMap[grandParentId].parent;
      }
      hierarchyObject[parentId][d.id] = true;
    }
  });

  return hierarchyObject;
}

window.getHierarchyObject = getHierarchyObject;

const pageStateInitFunctions = {
  getSearchResInitState: () => ({
    loadedData: false,
    posts: [],
    postsToPage: [],
    currentPage: 1,
    filterState: {
      onMountFilter: false,
      filtered: false,
      categoriesFilterSet: new Set(),
      sectionFilterSet: new Set(),
      sliderKey: Math.random() + "",
      defaultYearRange: [1977, new Date().getFullYear()],
      currYearRange: [1977, new Date().getFullYear()],
      params: {},
    },
    firstLoad: true,
    loadingResults: false,
    tabValue: 0,
  }),
  getTopicInitState: () => ({
    loadedData: false,
    posts: [],
    postsToPage: [],
    currentPage: 1,
    filterState: {
      onMountFilter: false,
      filtered: false,
      categoriesFilterSet: new Set(),
      sliderKey: Math.random() + "",
      defaultYearRange: [1977, new Date().getFullYear()],
      currYearRange: [1977, new Date().getFullYear()],
      params: {},
    },
    firstLoad: true,
    loadingResults: false,
    tabValue: 0,
  }),
  getDataVizInitState: () => ({
    loadedData: false,
    posts: [],
    postsToPage: [],
    ytPosts: [],
    ytPostsToPage: [],
    currentPage: 1,
    ytCurrentPage: 1,
    ytPostsPerPage: 9,
    filterState: {
      onMountFilter: false,
      filtered: false,
      sectionFilterSet: new Set(),
      sliderKey: Math.random() + "",
      defaultYearRange: [1977, new Date().getFullYear()],
      currYearRange: [1977, new Date().getFullYear()],
      params: {},
    },
    firstLoad: true,
    loadingResults: false,
    tabValue: 0,
  }),
  getPublicationsInitState: () => ({
    loadedData: false,
    posts: [],
    postsToPage: [],
    currentPage: 1,
    filterState: {
      onMountFilter: false,
      filtered: false,
      categoriesFilterSet: new Set(),
      sectionFilterSet: new Set(),
      sliderKey: Math.random() + "",
      defaultYearRange: [1977, new Date().getFullYear()],
      currYearRange: [1977, new Date().getFullYear()],
      params: {},
    },
    firstLoad: true,
    loadingResults: false,
    tabValue: 0,
  }),
  getDailyPollsInitState: () => ({
    loadedData: false,
    posts: [],
    postsToPage: [],
    currentPage: 1,
    filterState: {
      onMountFilter: false,
      filtered: false,
      categoriesFilterSet: new Set(),
      sectionFilterSet: new Set(),
      sliderKey: Math.random() + "",
      defaultYearRange: [1977, new Date().getFullYear()],
      currYearRange: [1977, new Date().getFullYear()],
      params: {},
    },
    firstLoad: true,
    loadingResults: false,
    tabValue: 0,
  }),
  getNewsInitState: () => ({
    loadedData: false,
    posts: [],
    postsToPage: [],
    currentPage: 1,
    filterState: {
      onMountFilter: false,
      filtered: false,
      categoriesFilterSet: new Set(),
      sectionFilterSet: new Set(),
      sliderKey: Math.random() + "",
      defaultYearRange: [1977, new Date().getFullYear()],
      currYearRange: [1977, new Date().getFullYear()],
      params: {},
    },
    firstLoad: true,
    loadingResults: false,
    tabValue: 0,
  }),
  getGallupInternationalInitState: () => ({
    loadedData: false,
    latestPublications: [],
  }),
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: true,
      prevRoute: null,
      loadedData: false,
      categories: {},
      idCategoryMap: {},
      sections: {},
      idSectionMap: {},
      slugSectionMap: {},
      categoriesHierarchy: null,
      topicsByLetter: null,
      home: {
        loadedData: false,
        bannerPosts: null,
      },
      news: pageStateInitFunctions.getNewsInitState(),
      searchRes: pageStateInitFunctions.getSearchResInitState(),
      topic: pageStateInitFunctions.getTopicInitState(),
      dataViz: pageStateInitFunctions.getDataVizInitState(),
      publications: pageStateInitFunctions.getPublicationsInitState(),
      gallupInternational:
        pageStateInitFunctions.getGallupInternationalInitState(),
    };

    this.domParser = new DOMParser();
    this.worldMap = [];

    this.setPageState = this.setPageState.bind(this);
    this.setPageStateWithoutUpdate = this.setPageStateWithoutUpdate.bind(this);
    this.getResObj = this.getResObj.bind(this);
    this.closePopup = this.closePopup.bind(this);
  }

  getResObj(d) {
    let date = new Date(d.date);
    return {
      id: d.id,
      year: date.getFullYear(),
      date: `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`,
      heading: loadPostsMixin.decodeHtml(d.title.rendered),
      image: d.jetpack_featured_media_url,
      theme: this.state.idCategoryMap[d.categories[0]].name,
      text: loadPostsMixin.extractFirstPara(d.excerpt.rendered),
      content: d.content.rendered,
    };
  }

  componentDidMount() {
    const categoriesPromise = axios.get(
      "https://gallup.com.pk/wp/wp-json/wp/v2/categories?per_page=100"
    );

    let sectionPromises = [
      axios.get(
        "https://gallup.com.pk/wp/wp-json/wp/v2/section?per_page=100&page=1"
      ),
      axios.get(
        "https://gallup.com.pk/wp/wp-json/wp/v2/section?per_page=100&page=2"
      ),
      axios.get(
        "https://gallup.com.pk/wp/wp-json/wp/v2/section?per_page=100&page=3"
      ),
    ];

    this.setState({ showPopup: true });

    Promise.all([categoriesPromise, ...sectionPromises]).then((res) => {
      //process categories data
      let categoriesArr = res[0].data;

      let categories = categoriesArr.reduce((acc, d) => {
        d.name = loadPostsMixin.decodeHtml(d.name);
        acc[d.name] = d;
        return acc;
      }, {});

      let idCategoryMap = categoriesArr.reduce((acc, d) => {
        acc[d.id] = d;
        return acc;
      }, {});

      //get hierarchy of categories

      let categoriesHierarchy = getHierarchyObject(
        categoriesArr,
        idCategoryMap
      );

      //process section data
      let sectionArr = [...res[1].data, ...res[2].data, ...res[3].data];
      let sections = sectionArr.reduce((acc, d) => {
        d.name = loadPostsMixin.decodeHtml(d.name);
        acc[d.name] = d;
        return acc;
      }, {});
      let idSectionMap = sectionArr.reduce((acc, d) => {
        acc[d.id] = d;
        return acc;
      }, {});
      let slugSectionMap = sectionArr.reduce((acc, d) => {
        acc[d.slug] = d;
        return acc;
      }, {});

      let sectionHierarchy = getHierarchyObject(sectionArr, idSectionMap);

      let topicsByLetter = this.getTopicsByLetter(idSectionMap);

      this.setState({
        categories: categories,
        loadedData: true,
        idCategoryMap: idCategoryMap,
        categoriesHierarchy: categoriesHierarchy,
        sections: sections,
        idSectionMap: idSectionMap,
        sectionHierarchy: sectionHierarchy,
        topSections: Object.keys(sectionHierarchy),
        slugSectionMap: slugSectionMap,
        topicsByLetter: topicsByLetter,
      });
    });

    axios.defaults.headers.post["Authorization"] =
      "Bearer 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";
    axios.defaults.headers.post["Content-Type"] = "application/json";
  }

  setPageState(page, state) {
    //  console.log('setting page state')
    let newState = { ...this.state[page], ...state };
    this.setState({
      [page]: newState,
    });
  }

  setPageStateWithoutUpdate(page, state) {
    let newState = { ...this.state[page], ...state };
    this.state[page] = newState;
  }

  getTopicsByLetter(idSectionMap) {
    let sections = Object.values(idSectionMap).map((d) => ({
      name: loadPostsMixin.decodeHtml(this.capatalizeFirstLetter(d.name)),
      count: d.count,
      type: "section",
      id: d.id,
      slug: d.slug,
    }));

    let allTopics = sections.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });

    return "ABCDEFGHIJKLMNOPQRTUVWXYZ".split("").map((d) => ({
      letter: d,
      topics: allTopics.filter((e) => e.name.charAt(0) === d),
    }));
  }

  capatalizeFirstLetter(str) {
    return str
      .split(" ")
      .map((d) => d.charAt(0).toUpperCase() + d.slice(1).toLowerCase())
      .join(" ");
  }

  closePopup() {
    this.setState({ showPopup: false });
  }

  render() {
    //  console.log('rendering app');
    return (
      <div className="App">
        <MailchimpSubscribe />
        {this.state.loadedData && this.state.showPopup && (
          <GallupWhatsAppPopup onClose={this.closePopup} />
        )}
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => {
              //  console.log('rendering home')
              //  console.log(props);
              return (
                <ViewHolder
                  unmountOnExit
                  component={Home}
                  componentKey={props.location.key}
                  route={props.location.pathname}
                  categories={this.state.categories}
                  setPageState={this.setPageState}
                  loadedAppData={this.state.loadedData}
                  shouldStoreProps={(currProps, nextProps) => {
                    return currProps.route !== nextProps.route;
                  }}
                  getAnimation={fadeAnimation}
                  pageStateInitFunctions={pageStateInitFunctions}
                  setPageStateWithoutUpdate={this.setPageStateWithoutUpdate}
                  topSections={this.state.topSections}
                  idSectionMap={this.state.idSectionMap}
                  {...this.state.home}
                />
              );
            }}
          />
          <Route
            exact
            path="/about"
            render={(props) => {
              //  console.log('rendering about')
              return (
                <ViewHolder
                  unmountOnExit
                  route={props.location.pathname}
                  component={About}
                  shouldStoreProps={(currProps, nextProps) => {
                    return currProps.route !== nextProps.route;
                  }}
                  idSectionMap={this.state.idSectionMap}
                  topSections={this.state.topSections}
                  pageStateInitFunctions={pageStateInitFunctions}
                  setPageStateWithoutUpdate={this.setPageStateWithoutUpdate}
                  getAnimation={fadeAnimation}
                  loadedAppData={this.state.loadedData}
                />
              );
            }}
          />
          <Route
            exact
            path="/publications"
            render={(props) => {
              return (
                <ViewHolder
                  pageStateKey={"publications"}
                  unmountOnExit
                  route={props.location.pathname}
                  component={Publications}
                  defaultFilterParams={{
                    categories: [
                      [
                        "categories",
                        "437,454,460,849,829,889,890,830,861,440,474,469,463,450",
                      ],
                    ],
                  }}
                  getDefaultFilterState={() =>
                    pageStateInitFunctions.getPublicationsInitState()
                      .filterState
                  }
                  shouldStoreProps={(currProps, nextProps) => {
                    return currProps.route !== nextProps.route;
                  }}
                  getAnimation={fadeAnimation}
                  componentKey={props.location.key}
                  categories={this.state.categories}
                  topSections={this.state.topSections}
                  filterCategories={[
                    437, 454, 460, 849, 829, 889, 890, 830, 861, 440, 474, 469,
                    463, 450,
                  ]}
                  location={"/publications"}
                  setPageState={this.setPageState}
                  setPageStateWithoutUpdate={this.setPageStateWithoutUpdate}
                  loadedAppData={this.state.loadedData}
                  idCategoryMap={this.state.idCategoryMap}
                  sectionHierarchy={this.state.sectionHierarchy}
                  idSectionMap={this.state.idSectionMap}
                  topicsByLetter={this.state.topicsByLetter}
                  postsPerPage={10}
                  categoriesHierarchy={this.state.categoriesHierarchy}
                  pageStateInitFunctions={pageStateInitFunctions}
                  {...this.state.publications}
                />
              );
            }}
          />
          <Route
            exact
            path="/gallupinternational"
            render={(props) => {
              return (
                <ViewHolder
                  unmountOnExit
                  route={props.location.pathname}
                  component={GallupInternational}
                  shouldStoreProps={(currProps, nextProps) => {
                    return currProps.route !== nextProps.route;
                  }}
                  getAnimation={fadeAnimation}
                  loadedAppData={this.state.loadedData}
                  topSections={this.state.topSections}
                  idSectionMap={this.state.idSectionMap}
                  setPageState={this.setPageState}
                  setPageStateWithoutUpdate={this.setPageStateWithoutUpdate}
                  pageStateInitFunctions={pageStateInitFunctions}
                  {...this.state.gallupInternational}
                />
              );
            }}
          />
          <Route
            exact
            path="/gallup"
            render={(props) => {
              //  console.log('rendering whatwedo')
              return (
                <ViewHolder
                  unmountOnExit
                  route={props.location.pathname}
                  component={WhatWeDo}
                  categories={this.state.categories}
                  topSections={this.state.topSections}
                  idSectionMap={this.state.idSectionMap}
                  setPageState={this.setPageState}
                  loadedAppData={this.state.loadedData}
                  idCategoryMap={this.state.idCategoryMap}
                  pageStateInitFunctions={pageStateInitFunctions}
                  setPageStateWithoutUpdate={this.setPageStateWithoutUpdate}
                  shouldStoreProps={(currProps, nextProps) => {
                    return currProps.route !== nextProps.route;
                  }}
                  getAnimation={fadeAnimation}
                  {...this.state.gallup}
                />
              );
            }}
          />
          <Route
            exact
            path="/news"
            render={(props) => {
              return (
                <ViewHolder
                  pageStateKey={"news"}
                  unmountOnExit
                  route={props.location.pathname}
                  component={News}
                  topSections={this.state.topSections}
                  defaultFilterParams={{
                    categories: [["categories", "440,861,459,846,480,463"]],
                  }}
                  getDefaultFilterState={() =>
                    pageStateInitFunctions.getPublicationsInitState()
                      .filterState
                  }
                  shouldStoreProps={(currProps, nextProps) => {
                    return currProps.route !== nextProps.route;
                  }}
                  getAnimation={fadeAnimation}
                  componentKey={props.location.key}
                  categories={this.state.categories}
                  filterCategories={[440, 861, 459, 846, 480, 463]}
                  setPageState={this.setPageState}
                  setPageStateWithoutUpdate={this.setPageStateWithoutUpdate}
                  loadedAppData={this.state.loadedData}
                  idCategoryMap={this.state.idCategoryMap}
                  sectionHierarchy={this.state.sectionHierarchy}
                  idSectionMap={this.state.idSectionMap}
                  topicsByLetter={this.state.topicsByLetter}
                  postsPerPage={10}
                  categoriesHierarchy={this.state.categoriesHierarchy}
                  pageStateInitFunctions={pageStateInitFunctions}
                  {...this.state.news}
                />
              );
            }}
          />
          <Route
            path="/post/:id"
            render={(props) => {
              let postProps;
              if (!props.location.postDetails) {
                postProps = {
                  loaded: false,
                  id: props.match.params.id,
                };
              } else {
                postProps = {
                  loaded: true,
                  postDetails: props.location.postDetails,
                };
              }
              return (
                <ViewHolder
                  unmountOnExit
                  route={props.location.pathname}
                  component={Post}
                  topSections={this.state.topSections}
                  idSectionMap={this.state.idSectionMap}
                  shouldStoreProps={(currProps, nextProps) => {
                    return currProps.route !== nextProps.route;
                  }}
                  getAnimation={fadeAnimation}
                  loadedAppData={this.state.loadedData}
                  getResObj={this.getResObj}
                  pageStateInitFunctions={pageStateInitFunctions}
                  setPageStateWithoutUpdate={this.setPageStateWithoutUpdate}
                  idCategoryMap={this.state.idCategoryMap}
                  {...postProps}
                />
              );
            }}
          />
          <Route
            exact
            path="/datavis"
            render={(props) => {
              let q = "";
              return (
                <ViewHolder
                  pageStateKey={"dataViz"}
                  unmountOnExit
                  route={props.location.pathname}
                  component={DataVis}
                  topSections={this.state.topSections}
                  defaultFilterParams={{
                    categories: [["categories", "849,881"]],
                  }}
                  getDefaultFilterState={() =>
                    pageStateInitFunctions.getDataVizInitState().filterState
                  }
                  shouldStoreProps={(currProps, nextProps) => {
                    return currProps.route !== nextProps.route;
                  }}
                  getAnimation={fadeAnimation}
                  componentKey={props.location.key}
                  categories={this.state.categories}
                  setPageState={this.setPageState}
                  setPageStateWithoutUpdate={this.setPageStateWithoutUpdate}
                  loadedAppData={this.state.loadedData}
                  idCategoryMap={this.state.idCategoryMap}
                  sectionHierarchy={this.state.sectionHierarchy}
                  idSectionMap={this.state.idSectionMap}
                  topicsByLetter={this.state.topicsByLetter}
                  q={q}
                  postsPerPage={10}
                  categoriesHierarchy={this.state.categoriesHierarchy}
                  pageStateInitFunctions={pageStateInitFunctions}
                  {...this.state.dataViz}
                />
              );
            }}
          />
          <Route
            exact
            path="/contact"
            render={(props) => {
              return (
                <ViewHolder
                  unmountOnExit
                  route={props.location.pathname}
                  component={Contact}
                  topSections={this.state.topSections}
                  idSectionMap={this.state.idSectionMap}
                  shouldStoreProps={(currProps, nextProps) => {
                    return currProps.route !== nextProps.route;
                  }}
                  getAnimation={fadeAnimation}
                  loadedAppData={this.state.loadedData}
                  pageStateInitFunctions={pageStateInitFunctions}
                  setPageStateWithoutUpdate={this.setPageStateWithoutUpdate}
                />
              );
            }}
          />
          <Route
            exact
            path="/search/:q"
            render={(props) => {
              let q = props.match.params.q;
              return (
                <ViewHolder
                  pageStateKey={"searchRes"}
                  unmountOnExit
                  route={props.location.pathname}
                  component={SearchResults}
                  topSections={this.state.topSections}
                  defaultFilterParams={{ search: [["search", q]] }}
                  getDefaultFilterState={() =>
                    pageStateInitFunctions.getSearchResInitState().filterState
                  }
                  shouldStoreProps={(currProps, nextProps) => {
                    return currProps.route !== nextProps.route;
                  }}
                  getAnimation={fadeAnimation}
                  componentKey={props.location.key}
                  categories={this.state.categories}
                  setPageState={this.setPageState}
                  setPageStateWithoutUpdate={this.setPageStateWithoutUpdate}
                  loadedAppData={this.state.loadedData}
                  idCategoryMap={this.state.idCategoryMap}
                  sectionHierarchy={this.state.sectionHierarchy}
                  idSectionMap={this.state.idSectionMap}
                  topicsByLetter={this.state.topicsByLetter}
                  q={q}
                  postsPerPage={10}
                  categoriesHierarchy={this.state.categoriesHierarchy}
                  pageStateInitFunctions={pageStateInitFunctions}
                  {...this.state.searchRes}
                />
              );
            }}
          />
          <Route
            exact
            path="/search"
            render={(props) => {
              let q = "";
              return (
                <ViewHolder
                  pageStateKey={"searchRes"}
                  unmountOnExit
                  route={props.location.pathname}
                  component={SearchResults}
                  topSections={this.state.topSections}
                  getDefaultFilterState={() =>
                    pageStateInitFunctions.getSearchResInitState().filterState
                  }
                  shouldStoreProps={(currProps, nextProps) => {
                    return currProps.route !== nextProps.route;
                  }}
                  getAnimation={fadeAnimation}
                  componentKey={props.location.key}
                  categories={this.state.categories}
                  setPageState={this.setPageState}
                  setPageStateWithoutUpdate={this.setPageStateWithoutUpdate}
                  loadedAppData={this.state.loadedData}
                  idCategoryMap={this.state.idCategoryMap}
                  sectionHierarchy={this.state.sectionHierarchy}
                  idSectionMap={this.state.idSectionMap}
                  topicsByLetter={this.state.topicsByLetter}
                  q={q}
                  postsPerPage={10}
                  categoriesHierarchy={this.state.categoriesHierarchy}
                  pageStateInitFunctions={pageStateInitFunctions}
                  {...this.state.searchRes}
                />
              );
            }}
          />
          <Route
            exact
            path="/topics/:q"
            render={(props) => {
              let q = props.match.params.q;
              let sectionId =
                this.state.slugSectionMap[q] && this.state.slugSectionMap[q].id;
              let sectionName =
                this.state.slugSectionMap[q] &&
                this.state.slugSectionMap[q].name;

              return (
                <ViewHolder
                  unmountOnExit
                  route={props.location.pathname}
                  component={Topic}
                  topSections={this.state.topSections}
                  defaultFilterParams={{ section: [["section", sectionId]] }}
                  getDefaultFilterState={() => ({
                    filtered: false,
                    categoriesFilterSet: new Set(),
                    sliderKey: Math.random() + "",
                    defaultYearRange: [1977, 2020],
                    currYearRange: [1977, 2020],
                    params: {},
                  })}
                  shouldStoreProps={(currProps, nextProps) => {
                    return currProps.route !== nextProps.route;
                  }}
                  pageStateKey={"topic"}
                  getAnimation={fadeAnimation}
                  componentKey={props.location.key}
                  categories={this.state.categories}
                  setPageState={this.setPageState}
                  setPageStateWithoutUpdate={this.setPageStateWithoutUpdate}
                  loadedAppData={this.state.loadedData}
                  idCategoryMap={this.state.idCategoryMap}
                  sectionHierarchy={this.state.sectionHierarchy}
                  idSectionMap={this.state.idSectionMap}
                  topicsByLetter={this.state.topicsByLetter}
                  q={sectionName}
                  postsPerPage={10}
                  categoriesHierarchy={this.state.categoriesHierarchy}
                  pageStateInitFunctions={pageStateInitFunctions}
                  {...this.state.topic}
                />
              );
            }}
          />
          <Redirect to="/" />
        </Switch>
      </div>
    );
  }
}

function fadeAnimation(props, currRef, prevRef, cb) {
  //  console.log(currRef);
  //  console.log(prevRef);
  return new Promise((res, rej) => {
    prevRef.classList.add("fadeOut");
    prevRef.addEventListener("animationend", () => {
      setTimeout(res, 20);
    });
  });
}

export default App;
