import * as topojson from "topojson";
import * as d3Base from "d3";
import * as d3Multi from "d3-selection-multi";
import * as d3Geo from "d3-geo-projection";

const d3 = Object.assign(d3Base, d3Multi, d3Geo);

async function readAndDraw(topojsonFile){

  const projection = d3.geoRobinson()
                     	.scale(150 * 1.4)
                     	.center([-30,23])

  const path = d3.geoPath().projection(projection);

  const worldMap = await d3.json(topojsonFile);

  const worldFeatures = topojson.feature(worldMap, worldMap.objects.worldMap).features;

  let svg = d3.select('svg.worldMap');

  const worldPath = svg.selectAll('path.country')
    .data(worldFeatures)
    .enter()
    .append('path')
    .classed('country', true)
    .attr('d', d => path(d))
    .style('fill', '#eeeeee')
    .style('stroke-opacity', 0.7)
    .style('stroke', 'white')
    .style('stroke-width', '0.5px')
    .transition()
    .duration(1000)
    .style('fill', d => {
      const country = d.properties.ADMIN;
      if (country == "Pakistan"){
        return '#689f38';
      }
      else if (countries.includes(country)){
        return '#9e9e9e';
      }
      else {
        return '#ddd';
      }
    });

    let gallupCount = svg.selectAll('path.country').filter(d => countries.includes(d.properties.ADMIN));

    gallupCount.on('mouseover', function(d, i){
      const loc = d3.select(this).datum().properties.ADMIN;

      const eventX = d3.event.x;
      const eventY = d3.event.y;

      console.log('mouseover Gallup Int');

      d3.select('body')
        .append('div')
        .classed('tooltip', true)
        .styles({
          position: 'absolute',
          width: '100px',
          opacity: 0,
          left: eventX - 50 + 'px',
          top: eventY + 20 + 'px',
          'background-color': 'rgba(225, 225, 225, 0.75)',
        })
        .html(`<p class="location">${loc}</p>`)
        .transition()
        .duration(500)
        .style('opacity', 1)
    });

    gallupCount.on('mouseout', function(d, i){
      d3.select('div.tooltip').remove();
    })

}


const countries = [
  'Australia',
  'Armenia',
  'Albania',
  'Afghanistan',
  'Austria',
  'Azerbaijan',
  'Bosnia and Herzegovina',
  'Bulgaria',
  'Czechia',
  'Ecuador',
  'Ethiopia',
  'Georgia',
  'Ghana',
  'Hong Kong S.A.R.',
  'India',
  'Iraq',
  'Ivory Coast',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Macedonia',
  'Moldova',
  'New Zealand',
  'Niger',
  'Nigeria',
  'Palestine',
  'Pakistan',
  'Poland',
  'Romania',
  'Russia',
  'Republic of Serbia',
  'South Africa',
  'South Korea',
  'Syria',
  'Thailand',
  'United Kingdom',
  'Ukraine',
  'Saudi Arabia',
  'Switzerland',
  'Spain',
  'Kenya',
  'Kyrgyzstan',
  'Uganda'
]

export { readAndDraw };
