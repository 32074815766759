import React, { Component } from 'react';
import styles from '../css-modules/section_title.module.css';

class SectionTitle extends Component {
  render () {
    return (
      <div className={`${styles.section_title_container} ${this.props.postTitle && styles.post_title}`}>
        <h2>{this.props.title}</h2>
        {this.props.subtitle && <p className={styles.subtitle}>{this.props.subtitle}</p>}
        {this.props.standardDisclaimer ?
          <p className={styles.disclaimer}>Disclaimer: To search through Gallup Pakistan's content, click here</p> 
          :
          this.props.disclaimerCategs && <p className={styles.disclaimer}>Disclaimer: This page contains content from {this.props.disclaimerCategs} sections, to search through all of Gallup's content, click here.</p>
        }
      </div>
    )
  }
}

export default SectionTitle;
