import React, { Component } from "react";
import BounceLoader from "react-spinners/BounceLoader";
import Fade from "react-reveal/Fade";
import NavBar from "../components/navbar";
import SectionTitle from "../components/sectiontitle";
import Footer from "../components/footer";
import commonStyles from "../css-modules/common.module.css";
import styles from "../css-modules/contact.module.css";
import TextField from "@material-ui/core/TextField";
import ImageBack from "../resources/Untitled-1.png";
import ImageFront from "../resources/Untitled-2.png";
import Button from "@material-ui/core/Button";
import * as emailjs from "emailjs-com";
import applyBg from "../resources/sign_up_bg.jpg";
import newspaperLogo from "../resources/newsletter.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import jsonp from "jsonp";
import { withRouter } from "react-router-dom";

class Contact extends Component {
  state = {
    loading: false,
    disableInputs: false,
    thank: false,
    error: false,
    errorMessage:
      "Submission Error: Please make sure you are entering all required information",
    thankSubscription: false,
    errorSubscription: false,
    name: "",
    email: "",
    subject: "",
    message: "",
    number: "",
  };

  componentDidMount() {
    this.prev = document.getElementById("scroll_view").scrollTop;
    if (window.innerWidth > 1075) {
      document
        .getElementById("scroll_view")
        .addEventListener("scroll", this._handleScroll);
    }
  }

  componentWillUnmount() {
    if (window.innerWidth > 1075) {
      document
        .getElementById("scroll_view")
        .removeEventListener("scroll", this._handleScroll);
    }
  }

  _handleScroll = (event) => {
    var imageContainer = document.getElementById("contact_image_container");
    if (imageContainer !== null) {
      var currScrollTop = document.getElementById("scroll_view").scrollTop;
      if (currScrollTop > this.prev) {
        document.getElementById("contact_back").style.transform =
          "translate3d(0px, " +
          (document.getElementById("contact_back").getBoundingClientRect().top -
            0.98 *
              document.getElementById("contact_back").getBoundingClientRect()
                .top) +
          "px , 0px)";
        document.getElementById("contact_front").style.transform =
          "translate3d(0px, -" +
          (document.getElementById("contact_front").getBoundingClientRect()
            .top -
            0.95 *
              document.getElementById("contact_front").getBoundingClientRect()
                .top) +
          "px , 0px)";
      } else {
        document.getElementById("contact_back").style.transform =
          "translate3d(0px, " +
          (document.getElementById("contact_back").getBoundingClientRect().top -
            0.98 *
              document.getElementById("contact_back").getBoundingClientRect()
                .top) +
          "px , 0px)";
        document.getElementById("contact_front").style.transform =
          "translate3d(0px, -" +
          (document.getElementById("contact_front").getBoundingClientRect()
            .top -
            0.95 *
              document.getElementById("contact_front").getBoundingClientRect()
                .top) +
          "px , 0px)";
      }
      this.prev = document.getElementById("scroll_view").scrollTop;

      //  var updatedTop = (moveDown.getBoundingClientRect().top - (0.88*moveDown.getBoundingClientRect().top)).toString() + "px";
      //  moveDown.style.top = updatedTop;
    }
  };

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ disableInputs: true });
    const { name, email, subject, message, number } = this.state;
    let templateParams = {
      from_name: name,
      from_email: email,
      from_phone: number,
      to_name: "Bilal I Gilani",
      subject: subject,
      message_html: message,
    };

    const parent = this;

    if (name === "" || email === "" || subject === "" || message === "") {
      parent.setState({
        error: true,
        thank: false,
        disableInputs: false,
        errorMessage:
          "Submission Error: Please make sure you are entering all required information",
      });
    } else {
      if (!this.validateEmail(email)) {
        this.setState({
          error: true,
          errorMessage:
            "Submission Error: Please make sure your email is in correct format",
          thank: false,
          disableInputs: false,
        });
      } else {
        this.sendEmail(templateParams, parent);
      }
    }
  }

  sendEmail(templateParams, parent) {
    emailjs
      .send(
        "smtp_server",
        "template_wvkxGjqO",
        templateParams,
        "user_W4hSstXBD8b19EWJiSHLt"
      )
      .then(
        function (response) {
          parent.resetForm();
          parent.setState({
            thank: true,
            error: false,
            disableInputs: false,
            errorMessage:
              "Submission Error: Please make sure you are entering all required information",
          });
        },
        function (error) {
          parent.setState({
            error: true,
            thank: false,
            disableInputs: false,
            errorMessage:
              "Submission Error: Please make sure you are entering all required information",
          });
        }
      );
  }

  validateEmail(email) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  resetForm() {
    this.setState({
      name: "",
      email: "",
      subject: "",
      message: "",
      number: "",
    });
  }

  handleChange = (param, e) => {
    this.setState({ [param]: e.target.value });
  };

  handleContentSubscription() {
    // e.preventDefault();
    const email = this.state.subscriptionEmail;
    const url =
      "https://gmail.us21.list-manage.com/subscribe/post?u=8f85ae14836907882ab599e92&amp;id=115521ee40&amp;f_id=0045d6e6f0";
    jsonp(`${url}&EMAIL=${email}`, { param: "c" }, (err, data) => {
      console.log(data, "Hello");
      // const { msg, result } = data;

      // do something with response
      // do something with response
      // alert(msg);
    });
    setTimeout(
      this.setState({
        thankSubscription: true,
        subscriptionEmail: "",
      }),
      3000
    );
    // if (this.validateEmail(this.state.subscriptionEmail)) {
    //   axios
    //     .post(`https://api.sendgrid.com/v3/contactdb/recipients`, [
    //       {
    //         email: this.state.subscriptionEmail,
    //       },
    //     ])
    //     .then(() => {
    //       this.setState({
    //         thankSubscription: true,
    //         subscriptionEmail: "",
    //       });
    //     });
    // } else {
    //   this.setState({
    //     errorSubscription: true,
    //   });
    // }
  }

  openQuickLink(quickLinkId) {
    let defaultState =
      this.props.pageStateInitFunctions.getSearchResInitState();

    defaultState.filterState.onMountFilter = true;
    defaultState.filterState.filtered = true;

    defaultState.filterState.categoriesFilterSet.add(quickLinkId + "");
    defaultState.filterState.params.categories = [
      ["categories", quickLinkId + ""],
    ];

    this.props.setPageStateWithoutUpdate("searchRes", defaultState);

    this.props.history.push("/search");
  }

  render() {
    return (
      <div
        id="scroll_view"
        ref={this.props.animationRef}
        className={commonStyles.view}
      >
        {!this.props.loadedAppData ? (
          <div className="loading">
            <BounceLoader
              sizeUnit={"px"}
              size={40}
              color={"#1E4D79"}
              loading={!this.props.loadedAppData}
            />
          </div>
        ) : (
          <div>
            <NavBar
              links={[
                { linkText: "Home", to: "" },
                { linkText: "ABOUT US", to: "about" },
                { linkText: "What We Do", to: "gallup" },
                { linkText: "News & Updates", to: "news" },
                { linkText: "Publications", to: "publications" },
                {
                  linkText: "Daily Polls",
                  to: "daily-polls",
                  onClick: () => this.openQuickLink("437,438"),
                },
                { linkText: "Interactives", to: "datavis" },
                { linkText: "Gallup International", to: "gallupinternational" },
                { linkText: "Contact Us", to: "contact" },
              ]}
            />
            <SectionTitle
              postTitle
              title="Contact Us"
              subtitle="Have any Questions? We'd love to hear from you"
            />
            <div className="padded_section">
              <div className="content_container">
                <div className={styles.contact_form_elements}>
                  <div className={styles.contact_form_container}>
                    <div className={styles.input_container}>
                      <TextField
                        size={window.innerHeight < 675 ? "small" : "medium"}
                        disabled={this.state.disableInputs}
                        value={this.state.name}
                        onChange={this.handleChange.bind(this, "name")}
                        variant="outlined"
                        required
                        id="standard-basic"
                        label="Name"
                      />
                    </div>
                    <div className={styles.input_container}>
                      <TextField
                        size={window.innerHeight < 675 ? "small" : "medium"}
                        disabled={this.state.disableInputs}
                        value={this.state.email}
                        onChange={this.handleChange.bind(this, "email")}
                        variant="outlined"
                        required
                        id="standard-basic"
                        label="Email"
                      />
                    </div>
                    <div className={styles.input_container}>
                      <PhoneInput
                        disabled={this.state.disableInputs}
                        country={"pk"}
                        placeholder="Phone Number"
                        value={this.state.number}
                        onChange={(phone) =>
                          this.setState({ number: "+" + phone })
                        }
                      />
                    </div>
                    <div className={styles.input_container}>
                      <TextField
                        size={window.innerHeight < 675 ? "small" : "medium"}
                        disabled={this.state.disableInputs}
                        value={this.state.subject}
                        onChange={this.handleChange.bind(this, "subject")}
                        variant="outlined"
                        required
                        id="standard-basic"
                        label="Subject"
                      />
                    </div>
                    <div className={styles.input_container}>
                      <TextField
                        size={window.innerHeight < 675 ? "small" : "medium"}
                        disabled={this.state.disableInputs}
                        value={this.state.message}
                        onChange={this.handleChange.bind(this, "message")}
                        variant="outlined"
                        required
                        multiline
                        rows={4}
                        id="standard-basic"
                        label="Message"
                      />
                    </div>
                    {this.state.thank === true ? (
                      <Fade>
                        <p className={styles.contact_thankyou}>
                          Thank you for contacting us. You'll hear from us
                          shortly
                        </p>
                      </Fade>
                    ) : (
                      <div style={{ height: "32px" }}>
                        {this.state.error === true ? (
                          <Fade>
                            <p className={styles.contact_error}>
                              {this.state.errorMessage}
                            </p>
                          </Fade>
                        ) : (
                          <p></p>
                        )}
                      </div>
                    )}
                    <div>
                      <Button
                        onClick={(e) => this.handleSubmit(e)}
                        style={{ marginTop: "8px", alignSelf: "baseline" }}
                        className="blue_button_filled"
                        variant="contained"
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                  <div
                    id="contact_image_container"
                    className={styles.contact_images_container}
                  >
                    <img
                      src={ImageBack}
                      id="contact_back"
                      className={styles.contact_image}
                    />
                    <img
                      src={ImageFront}
                      id="contact_front"
                      className={styles.contact_image}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
            <Footer
              links={[
                { linkText: "Affiliations & Memberships", to: "about" },
                { linkText: "Contact Us", to: "contact" },
              ]}
              researchAreas={this.props.topSections.map((d) => {
                let data = this.props.idSectionMap[d];
                return { linkText: data.name, id: d };
              })}
              pageStateInitFunctions={this.props.pageStateInitFunctions}
              setPageStateWithoutUpdate={this.props.setPageStateWithoutUpdate}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(Contact);
