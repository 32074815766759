import React, { Component } from "react";
import BounceLoader from "react-spinners/BounceLoader";
import Fade from "react-reveal/Fade";
import Button from "@material-ui/core/Button";
import NavBar from "../components/navbar";
import Footer from "../components/footer";
import HomeBanner from "../components/homebanner";
import SearchBar from "../components/titlesearch";
import Trending from "../components/trending";
import HomeDailyPolls from "../components/homeDailyPolls";
import HomeNews from "../components/HomeNews";
import HomeVis from "../components/HomeVis";
import HomeMedia from "../components/homeMedia";
import axios from "axios";
import commonStyles from "../css-modules/common.module.css";
import { Link } from "react-router-dom";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import Particles from "react-particles-js";
import { withRouter } from "react-router-dom";

class Home extends Component {
  componentDidMount() {
    //Following request for id 886 is for posts with the tag "Banner". Banner tag has the ID of 886. Communicate to client.
    //The following request takes the last 3 banner posts (ordered desc by Date, WP default).
    if (!this.props.loadedData) {
      const bannerPostsPromise = axios.get(
        "https://gallup.com.pk/wp/wp-json/wp/v2/posts?tags=886&per_page=3&order=desc"
      );
      const quickLinksPromise = axios.get(
        "https://www.gallup.com.pk/wp/wp-json/wp/v2/quick_link"
      );

      const parent = this;

      Promise.all([bannerPostsPromise, quickLinksPromise]).then(function (res) {
        parent.props.setPageState("home", {
          loadedData: true,
          bannerPosts: res[0].data,
          quickLinks: res[1].data,
        });
      });
    }
  }

  openQuickLink(quickLinkId) {
    let defaultState =
      this.props.pageStateInitFunctions.getSearchResInitState();

    defaultState.filterState.onMountFilter = true;
    defaultState.filterState.filtered = true;

    defaultState.filterState.categoriesFilterSet.add(quickLinkId + "");
    defaultState.filterState.params.categories = [
      ["categories", quickLinkId + ""],
    ];

    this.props.setPageStateWithoutUpdate("searchRes", defaultState);

    this.props.history.push("/search");
  }

  render() {
    // console.log(this.props.dailyPolls)
    return (
      <div
        id="scroll_view"
        ref={this.props.animationRef}
        className={commonStyles.view}
      >
        {!(this.props.loadedData && this.props.loadedAppData) ? (
          <div className="loading">
            <BounceLoader
              sizeUnit={"px"}
              size={40}
              color={"#1E4D79"}
              loading={!(this.props.loadedData && this.props.loadedAppData)}
            />
          </div>
        ) : (
          <div>
            <NavBar
              links={[
                { linkText: "Home", to: "" },
                { linkText: "ABOUT US", to: "about" },
                { linkText: "What We Do", to: "gallup" },
                { linkText: "News & Updates", to: "news" },
                { linkText: "Publications", to: "publications" },
                {
                  linkText: "Daily Polls",
                  to: "daily-polls",
                  onClick: () => this.openQuickLink("437, 438"),
                },
                { linkText: "Interactives", to: "datavis" },
                { linkText: "Gallup International", to: "gallupinternational" },
                { linkText: "Contact Us", to: "contact" },
              ]}
            />
            <div className="hundredVH">
              <div className="absolute_particles_container">
                <Particles
                  height="100vh"
                  params={{
                    particles: {
                      number: {
                        value: 100,
                        density: {
                          enable: true,
                        },
                      },
                      color: {
                        value: "#ffeb3b",
                      },
                      size: {
                        value: 3,
                        random: true,
                        anim: {
                          speed: 4,
                          size_min: 0.3,
                        },
                      },
                      line_linked: {
                        enable: true,
                        color: "#757575",
                      },
                      move: {
                        random: true,
                        speed: 1,
                      },
                    },
                  }}
                />
              </div>
              <div className="landing_page_container">
                <div className="content_container landing_reverse_mobile">
                  <Fade top distance={"20px"} delay={225}>
                    <div className="video_container">
                      <HomeBanner
                        categories={Object.values(this.props.categories)}
                        withoutObj={this.props.categories}
                        bannerPosts={this.props.bannerPosts}
                      />
                    </div>
                  </Fade>
                  <SearchBar
                    titleSearch
                    setPageState={this.props.setPageState}
                    quickLinks={this.props.quickLinks}
                    //  quickLinks={[{linkText: 'Daily Polls', id: 438},{linkText: 'Gallup Pakistan Studies', id: 440},{linkText: 'Gallup in Press and Web', id: 459}]}
                    categories={Object.values(this.props.categories)}
                    pageStateInitFunctions={this.props.pageStateInitFunctions}
                    setPageStateWithoutUpdate={
                      this.props.setPageStateWithoutUpdate
                    }
                  />
                </div>
                <Trending
                // trendingPosts={this.props.trendingPosts}
                />
              </div>
            </div>

            <div className="padded_section">
              <div className="content_container">
                <div className="flexed_container" id="news_poll_flex">
                  <div className="container_70" id="news_updates">
                    <div className="home_section_title_container">
                      <p
                        className="section_title"
                        style={
                          {
                            // maxWidth: "200px"
                          }
                        }
                      >
                        Latest from Gallup Pakistan
                      </p>
                      <Link
                        to="publications"
                        style={{ textDecoration: "none" }}
                      >
                        <Button className="white_button" variant="outlined">
                          View All
                        </Button>
                      </Link>
                    </div>
                    <HomeNews
                      categories={Object.values(this.props.categories)}
                    />
                  </div>
                  <div className="container_30" id="daily_polls">
                    <div className="home_section_title_container">
                      <p className="section_title">Recent Polls</p>
                      <Link to="news" style={{ textDecoration: "none" }}>
                        <Button className="white_button" variant="outlined">
                          View All
                        </Button>
                      </Link>
                    </div>
                    <HomeDailyPolls />
                  </div>
                </div>
              </div>
            </div>

            <div className="padded_section">
              <HomeVis />
            </div>

            <div className="padded_section">
              <div className="content_container">
                <div className="flexed_container" id="behavioral_flex">
                  <div
                    className="container_20 centered_columned"
                    id="behavioral_heading"
                  >
                    <div className="publication_media_title_container">
                      <div className="publication_media_title_separator"></div>
                      <p className="section_title margined_title">
                        Behavioral Research
                      </p>
                      <Link to="news" style={{ textDecoration: "none" }}>
                        <Button className="white_button" variant="outlined">
                          View All
                        </Button>
                      </Link>
                    </div>
                  </div>
                  <div className="container_80" id="behavioral_posts">
                    <HomeMedia />
                  </div>
                </div>

                <div className="home_twitter_feed">
                  <TwitterTimelineEmbed
                    sourceType="profile"
                    screenName="GallupPak"
                    options={{ height: 400 }}
                  />
                </div>
              </div>
            </div>

            <Footer
              links={[
                { linkText: "Affiliations & Memberships", to: "about" },
                { linkText: "Contact Us", to: "contact" },
              ]}
              researchAreas={this.props.topSections.map((d) => {
                let data = this.props.idSectionMap[d];
                return { linkText: data.name, id: d };
              })}
              pageStateInitFunctions={this.props.pageStateInitFunctions}
              setPageStateWithoutUpdate={this.props.setPageStateWithoutUpdate}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(Home);
