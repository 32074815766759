import React, { Component } from "react";
import BounceLoader from "react-spinners/BounceLoader";
import Fade from "react-reveal/Fade";
import NavBar from "../components/navbar";
import RelatedPostsSection from "../components/relatedPosts";
import SectionTitle from "../components/sectiontitle";
import Footer from "../components/footer";
import ReactMarkdown from "react-markdown";
import Button from "@material-ui/core/Button";
import axios from "axios";
import loadPostsMixin from "../mixins/loadPostsMixin";
import styles from "../css-modules/post_details.module.css";
import commonStyles from "../css-modules/common.module.css";
import placeholder from "../resources/placeholder.jpg";
import classNames from "classnames";
import { Link } from "react-router-dom";
import researchBg from "../resources/research_bg.jpg";
import { withRouter } from "react-router-dom";

import { FaNewspaper, FaBook, FaChartBar } from "react-icons/fa";

class ArticleDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: !props.loaded,
      id: props.id,
      postDetails: props.postDetails,
      relatedPosts: null,
    };
  }

  openQuickLink(quickLinkId) {
    let defaultState =
      this.props.pageStateInitFunctions.getSearchResInitState();

    defaultState.filterState.onMountFilter = true;
    defaultState.filterState.filtered = true;

    defaultState.filterState.categoriesFilterSet.add(quickLinkId + "");
    defaultState.filterState.params.categories = [
      ["categories", quickLinkId + ""],
    ];

    this.props.setPageStateWithoutUpdate("searchRes", defaultState);

    this.props.history.push("/search");
  }

  openReport(url) {
    if (url.includes("pk/wp-content")) {
      url = url.replace("pk/wp-content", "pk/wp/wp-content");
    }
    window.open(url, "_blank");
  }

  async componentDidMount() {
    let id = this.props.route.split("/");
    id = id[id.length - 1];
    let res = await loadPostsMixin.loadRelatedPosts(id);
    this.setState({
      relatedPosts: res,
    });
  }

  async componentDidUpdate() {
    if (this.state.loading && this.props.loadedAppData) {
      let res = await axios.get(
        `https://gallup.com.pk/wp/wp-json/wp/v2/posts/${this.state.id}`
      );
      let postDetails = loadPostsMixin.getResObj(
        res.data,
        this.props.idCategoryMap
      );
      this.setState({
        postDetails: postDetails,
        loading: false,
      });
    }
  }

  open = (link) => {
    window.open(link, "articlepost");
  };

  parseHeading() {
    const parser = new DOMParser();
    const parsedHeading = parser.parseFromString(
      this.state.postDetails.heading,
      "text/html"
    ).body.textContent;
    return parsedHeading;
  }

  correctImageHref(content) {
    if (content.includes("pk/wp-content")) {
      content = content.replace("pk/wp-content", "pk/wp/wp-content");
    }
    return content;
  }

  render() {
    return (
      <div
        id="scroll_view"
        ref={this.props.animationRef}
        className={commonStyles.view}
      >
        {this.state.loading === true ? (
          <div className="loading">
            <BounceLoader
              sizeUnit={"px"}
              size={40}
              color={"#1E4D79"}
              loading={this.state.loading}
            />
          </div>
        ) : (
          <div>
            <NavBar
              links={[
                { linkText: "Home", to: "" },
                { linkText: "ABOUT US", to: "about" },
                { linkText: "What We Do", to: "gallup" },
                { linkText: "News & Updates", to: "news" },
                { linkText: "Publications", to: "publications" },
                {
                  linkText: "Daily Polls",
                  to: "daily-polls",
                  onClick: () => this.openQuickLink("437,438"),
                },
                { linkText: "Interactives", to: "datavis" },
                { linkText: "Gallup International", to: "gallupinternational" },
                { linkText: "Contact Us", to: "contact" },
              ]}
            />
            <SectionTitle
              postTitle
              title={this.state.postDetails.theme}
              subtitle={
                this.parseHeading() // {this.state.postDetails.heading}
              }
            />
            <div className="padded_section">
              <div className={styles.post_banner_image_container}>
                {this.state.postDetails.image && (
                  <img
                    className={styles.post_banner_image}
                    src={this.state.postDetails.image}
                  ></img>
                )}
                {!this.state.postDetails.image && (
                  <img
                    className={styles.post_banner_image}
                    src={placeholder}
                  ></img>
                )}
              </div>
              <div className="content_container">
                <h1 className={styles.post_heading}>
                  {/* {this.state.postDetails.heading} */}
                  {this.parseHeading()}
                </h1>
                <div className={styles.post_details_container}>
                  <ReactMarkdown
                    source={this.correctImageHref(
                      this.state.postDetails.content
                    )}
                    escapeHtml={false}
                  />
                  <div className={styles.button_container}>
                    {this.state.postDetails.english_pdf && (
                      <Button
                        style={
                          this.state.postDetails.english_pdf &&
                          this.state.postDetails.urdu_pdf
                            ? { marginRight: "25px" }
                            : { marginRight: "0px" }
                        }
                        onClick={() =>
                          this.openReport(
                            this.state.postDetails.english_pdf.url
                          )
                        }
                        variant="contained"
                        color="primary"
                      >
                        Report (English)
                      </Button>
                    )}
                    {this.state.postDetails.urdu_pdf && (
                      <Button
                        onClick={() =>
                          this.openReport(this.state.postDetails.urdu_pdf.url)
                        }
                        variant="contained"
                        color="primary"
                      >
                        Report (Urdu)
                      </Button>
                    )}
                  </div>
                </div>
                <RelatedPostsSection relatedPosts={this.state.relatedPosts} />
              </div>
            </div>

            <div className={styles.browseContent}>
              <img src={researchBg} className={styles.browseContent_bg} />
              <div className={styles.browseContent_content}>
                <Fade top delay={25}>
                  <p
                    className={classNames(
                      "section_title",
                      styles.paddedSectionTitle,
                      styles.yellow_title
                    )}
                  >
                    Browse More Content
                  </p>
                </Fade>
                <div className={styles.researchAreas_container}>
                  <Fade top delay={100}>
                    <Link to="/news" style={{ textDecoration: "none" }}>
                      <p className={styles.browseContent_heading}>
                        <FaNewspaper className={styles.icon} /> News & Updates
                      </p>
                    </Link>
                  </Fade>
                  <Fade top delay={175}>
                    <Link to="/publications" style={{ textDecoration: "none" }}>
                      <p className={styles.browseContent_heading}>
                        <FaBook className={styles.icon} /> Publications
                      </p>
                    </Link>
                  </Fade>
                  <Fade top delay={250}>
                    <Link to="/datavis" style={{ textDecoration: "none" }}>
                      <p className={styles.browseContent_heading}>
                        <FaChartBar className={styles.icon} /> Interactives
                      </p>
                    </Link>
                  </Fade>
                </div>
              </div>
            </div>

            <Footer
              links={[
                { linkText: "Affiliations & Memberships", to: "about" },
                { linkText: "Contact Us", to: "contact" },
              ]}
              researchAreas={[
                { linkText: "Social Trends", to: "home" },
                { linkText: "Legal", to: "home" },
                { linkText: "Crime & Society", to: "home" },
                { linkText: "Politics", to: "home" },
                { linkText: "Financial Research", to: "home" },
                { linkText: "Global Polling", to: "home" },
                { linkText: "Science & Technology", to: "home" },
                { linkText: "Religion", to: "home" },
                { linkText: "Economy", to: "home" },
                { linkText: "Media", to: "home" },
                { linkText: "Healthcare", to: "home" },
                { linkText: "Public Opinion on Current Affairs", to: "home" },
                { linkText: "Consumer Behaviour", to: "home" },
                { linkText: "Behavioural Research", to: "home" },
              ]}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(ArticleDetail);
