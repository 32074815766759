import React from "react";
import styles from "../css-modules/dataViz.module.css";
import Pagination from "@material-ui/lab/Pagination";
import placeholder from "../resources/placeholder.jpg";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
// import Image from "react-graceful-image";
import LazyLoad from "react-lazy-load";

function DataVizBlock(props) {
  const parseHeading = () => {
    const parser = new DOMParser();
    const parsedHeading = parser.parseFromString(props.heading, "text/html")
      .body.textContent;
    return parsedHeading;
  };

  const convertToFriendlyDate = (dateString) => {
    const [day, month, year] = dateString.split("/"); // Split the date string into its components
    const date = new Date(year, month - 1, day); // Create a new Date object using the components

    // Format the date using Intl.DateTimeFormat
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );

    return formattedDate;
  };

  const handleThemeClick = () => {
    props.onThemeClick(props.theme); // Send theme back to parent
  };

  return (
    <div className={`${styles.blockContainer}`}>
      <div className={styles.imageContainer}>
        <Link
          to={{ pathname: `/post/${props.id}`, postDetails: props.details }}
        >
          {props.image.length > 0 ? (
            <LazyLoad>
              <img
                src={props.image.replace(/fit=\d*%/, "fit=500%")}
                alt="post"
              />
            </LazyLoad>
          ) : (
            <LazyLoad>
              <img src={placeholder} alt="post" />
            </LazyLoad>
          )}
        </Link>
      </div>
      <div className={styles.dateThemeDiv}>
        <p
          onClick={handleThemeClick}
          style={{
            cursor: "pointer",
            position: "relative",
            zIndex: 1000,
          }}
          onMouseOver={(e) =>
            (e.currentTarget.style.textDecoration = "underline")
          }
          onMouseOut={(e) => (e.currentTarget.style.textDecoration = "none")}
        >
          {props.theme}
        </p>

        <p className={styles.blockTheme}>{convertToFriendlyDate(props.date)}</p>
      </div>
      <Link
        style={{ textDecoration: "none", color: "inherit" }}
        to={{ pathname: `/post/${props.id}`, postDetails: props.details }}
      >
        <h1 className={styles.blockTitle}>{parseHeading()}</h1>
      </Link>
    </div>
  );
}

function SkeletonDataVizBlock(props) {
  return (
    <div className={`${styles.blockContainer}`}>
      <div className={styles.imageContainer}>
        <Skeleton height={200} />
      </div>
      <div className={`${styles.dateThemeDiv} ${styles.skeleton}`}>
        <Skeleton style={{ width: "100%" }} />
      </div>
      <div className={`${styles.skeleton} ${styles.skeletonHeading}`}>
        <Skeleton width="100%" />
      </div>
    </div>
  );
}

function DataVizSection(props) {
  return (
    <div className={styles.blockSectionContainer}>
      <div className={styles.blockSection}>
        {props.loadingPosts
          ? new Array(10).fill(null).map((d, i) => {
              return <SkeletonDataVizBlock key={i} />;
            })
          : props.posts.map((d, i) => {
              return (
                <DataVizBlock
                  key={d.id}
                  {...d}
                  details={d}
                  onThemeClick={props.onThemeClick}
                />
              );
            })}
        {!props.loadingPosts && props.posts.length === 0 && (
          <h1>No Results Found</h1>
        )}
      </div>
      <div>
        {!props.loadingPosts && props.posts.length > 0 && (
          <Pagination
            size="small"
            page={props.currentPage}
            onChange={(e, p) => {
              props.setPage(p);
            }}
            count={props.totalPages}
            shape="rounded"
          />
        )}
      </div>
    </div>
  );
}

export default DataVizSection;
