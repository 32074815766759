import React, { Component } from "react";
import styles from "../css-modules/home_news.module.css";
import ReactMarkdown from "react-markdown";
import placeholder from "../resources/placeholder.jpg";
import { Link } from "react-router-dom";
import axios from "axios";
import ClampLines from "react-clamp-lines";
import LazyLoad from "react-lazy-load";
import loadPostsMixin from "../mixins/loadPostsMixin";
import Skeleton from 'react-loading-skeleton';

class HomeNews extends Component {
  constructor(props) {
    super(props);
    this.domParser = new DOMParser();
    this.state = { currIndex: 2, loading: true };

    const homeStudiesPromise = axios.get(
      "https://gallup.com.pk/wp/wp-json/wp/v2/posts?categories=440&per_page=1"
    );
    const homehistoryPromise = axios.get(
      "https://gallup.com.pk/wp/wp-json/wp/v2/posts?categories=454&per_page=1"
    );

    const homeStudiesPromise2 = axios.get(
      "https://gallup.com.pk/wp/wp-json/wp/v2/posts?categories=440&page=2&per_page=1"
    );
    const homeStudiesPromise3 = axios.get(
      "https://gallup.com.pk/wp/wp-json/wp/v2/posts?categories=454&page=3&per_page=1"
    );

    const latestPressRelease = axios.get(
      "https://gallup.com.pk/wp/wp-json/wp/v2/posts?categories=438&per_page=1"
    );

    const latestHistoryProject = axios.get(
      "https://gallup.com.pk/wp/wp-json/wp/v2/posts?categories=460&per_page=1"
    );

    Promise.all([
      homeStudiesPromise,
      homehistoryPromise,
      homeStudiesPromise2,
      homeStudiesPromise3,
      latestPressRelease,
      latestHistoryProject,
    ]).then((res) => {
      this.setState({
        homeStudies: res[0].data.concat(res[1].data),
        homeStudiesNoPics1: res[2].data,
        homeStudiesNoPics2: res[3].data,
        latestPressRelease: res[4].data[0],
        latestHistoryProject: res[5].data[0],
        loading: false
      });
      // console.log(res[4].data[0])
    });
  }

  getDate = (date) => {
    let dateInterp = new Date(date);
    return {
      date: `${dateInterp.getDate()}/${
        dateInterp.getMonth() + 1
      }/${dateInterp.getFullYear()}`,
    };
  };

  extractFirstPara = (htmlString) => {
    let parsedHTML = this.domParser.parseFromString(htmlString, "text/html");
    let firstPara = parsedHTML.getElementsByTagName("p")[0];
    let firstParaText = firstPara ? firstPara.innerText : "";
    return firstParaText || "No P tag";
  };

  convertToFriendlyDate = (dateString) => {
    if (dateString) {
      const date = new Date(dateString); // Create a new Date object using the date string

      // Format the date in a friendly format
      const options = { year: "numeric", month: "long", day: "numeric" };
      const friendlyDate = date.toLocaleDateString(undefined, options);

      return friendlyDate;
    }
  };

  

  render() {
    const {
      homeStudies,
      homeStudiesPromise2,
      homeStudiesPromise3,
      latestPressRelease,
      latestHistoryProject,
      loading
    } = this.state;
    // console.log(latestPressRelease)

    if (loading) {
      return (
        <div className={styles.home_news_container}>
          <SkeletonPostBlock />
          <SkeletonPostBlock />
          <SkeletonPostBlock />
          <SkeletonPostBlock />
        </div>
      );
    }

    var parent = this;
    return (
      <div className={styles.home_news_container}>
        <div className={styles.home_news_post_container}>
          <div className={styles.home_news_post}>
            <Link
              to={`/post/${latestPressRelease?.id}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div className={styles.home_news_image_container}>
                {latestPressRelease?.jetpack_featured_media_url.length > 0 ? (
                  <LazyLoad>
                    <img
                      src={latestPressRelease?.jetpack_featured_media_url}
                      className={styles.home_news_banner_image}
                      alt={latestPressRelease?.title.rendered}
                    />
                  </LazyLoad>
                ) : (
                  <LazyLoad>
                    <img
                      src={placeholder}
                      className={styles.home_news_banner_image}
                      alt={latestPressRelease?.title.rendered}
                    />
                  </LazyLoad>
                )}
                <div className={styles.date_category_container}>
                  <p className={styles.category_heading}>
                    {this.props.categories.filter(
                      (category) =>
                        category.id === latestPressRelease?.categories[0]
                    )[0]?.name || "Unknown Category"}
                  </p>
                  <p className={styles.date}>
                    {/* {this.getDate(latestPressRelease?.date).date} */}
                    {this.convertToFriendlyDate(latestPressRelease?.date)}
                  </p>
                </div>
              </div>
              <div className={styles.home_banner_text_container}>
                <div className={styles.date_category_container}>
                  <p className={styles.date}>{latestPressRelease?.date}</p>
                  <p className={styles.category_heading}>
                    {this.props.categories.filter(
                      (category) =>
                        category.id === latestPressRelease?.categories[0]
                    )[0]?.name || "Unknown Category"}
                  </p>
                </div>
                <div className={styles.banner_heading}>
                  <ReactMarkdown
                    source={latestPressRelease?.title.rendered}
                    escapeHtml={false}
                  />
                </div>
                <div className={styles.excerptArticle}>
                  <ClampLines
                    text={this.extractFirstPara(
                      latestPressRelease?.excerpt.rendered
                    )}
                    id={latestPressRelease?.id}
                    lines={3}
                    ellipsis="..."
                    innerElement="p"
                    buttons={false}
                  />
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className={styles.home_news_post_container}>
          <div className={styles.home_news_post}>
            <Link
              to={`/post/${latestHistoryProject?.id}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div className={styles.home_news_image_container}>
                {latestHistoryProject?.jetpack_featured_media_url.length > 0 ? (
                  <LazyLoad>
                    <img
                      src={latestHistoryProject?.jetpack_featured_media_url}
                      className={styles.home_news_banner_image}
                      alt={latestHistoryProject?.title.rendered}
                    />
                  </LazyLoad>
                ) : (
                  <LazyLoad>
                    <img
                      src={placeholder}
                      className={styles.home_news_banner_image}
                      alt={latestHistoryProject?.title.rendered}
                    />
                  </LazyLoad>
                )}
                <div className={styles.date_category_container}>
                  <p className={styles.category_heading}>
                    {this.props.categories.filter(
                      (category) =>
                        category.id === latestHistoryProject?.categories[0]
                    )[0]?.name || "Unknown Category"}
                  </p>
                  <p className={styles.date}>
                    {/* {this.getDate(latestHistoryProject?.date).date} */}
                    {this.convertToFriendlyDate(latestHistoryProject?.date)}
                  </p>
                </div>
              </div>
              <div className={styles.home_banner_text_container}>
                <div className={styles.banner_heading}>
                  <ReactMarkdown
                    source={latestHistoryProject?.title.rendered}
                    escapeHtml={false}
                  />
                </div>
                <div className={styles.excerptArticle}>
                  <ClampLines
                    text={this.extractFirstPara(
                      latestHistoryProject?.excerpt.rendered
                    )}
                    id={latestHistoryProject?.id}
                    lines={3}
                    ellipsis="..."
                    innerElement="p"
                    buttons={false}
                  />
                </div>
              </div>
            </Link>
          </div>
        </div>
        {homeStudies &&
          homeStudies.length > 0 &&
          homeStudies.map(function (homeStudy, index) {
            var noPicId = "homeStudiesNoPics" + (index + 1);
            //  console.log(noPicId);
            return (
              <div key={index} className={styles.home_news_post_container}>
                <div className={styles.home_news_post}>
                  <Link
                    key={homeStudy.id}
                    to={`/post/${homeStudy.id}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <div className={styles.home_news_image_container}>
                      {homeStudy.jetpack_featured_media_url.length > 0 ? (
                        <LazyLoad>
                          <img
                            src={homeStudy.jetpack_featured_media_url}
                            className={styles.home_news_banner_image}
                            alt={homeStudy.title.rendered}
                          />
                        </LazyLoad>
                      ) : (
                        <LazyLoad>
                          <img
                            src={placeholder}
                            className={styles.home_news_banner_image}
                            alt={homeStudy.title.rendered}
                          />
                        </LazyLoad>
                      )}
                      <div className={styles.date_category_container}>
                        <p className={styles.category_heading}>
                          {
                            parent.props.categories.filter(
                              (category) =>
                                category.id === homeStudy.categories[0]
                            )[0]?.name
                          }
                        </p>
                        <p className={styles.date}>
                          {/* {parent.getDate(homeStudy.date).date} */}
                          {parent.convertToFriendlyDate(homeStudy?.date)}
                        </p>
                      </div>
                    </div>
                    <div className={styles.home_banner_text_container}>
                      <div className={styles.banner_heading}>
                        <ReactMarkdown
                          source={homeStudy.title.rendered}
                          escapeHtml={false}
                        />
                      </div>
                      <div className={styles.excerptArticle}>
                        <ClampLines
                          text={parent.extractFirstPara(
                            homeStudy.excerpt.rendered
                          )}
                          id={homeStudy.id}
                          lines={3}
                          ellipsis="..."
                          innerElement="p"
                          buttons={false}
                        />
                      </div>
                    </div>
                  </Link>
                </div>
                {/*parent.props[noPicId] && parent.props[noPicId].length > 0 && parent.props[noPicId].map((noPic, index) => (
              <div key={noPic.id} className={styles.noImagePost_container}>
                <Link to={`/post/${noPic.id}`} style={{textDecoration: 'none', color: 'inherit'}}>
                  <p className={styles.noImagePost_categ}>{parent.props.categories.filter(category => category.id === noPic.categories[0])[0].name} | {parent.getDate(noPic.date).date}</p>
                  <div className={styles.noImagePost_heading}>
                    <ReactMarkdown
                      source={noPic.title.rendered}
                      escapeHtml={false}
                    />
                  </div>
                </Link>
              </div>
            )) */}
              </div>
            );
          })}
      </div>
    );
  }
}

function SkeletonPostBlock(props) {
  return (
    <div className={styles.blockContainer}>
      <div className={styles.blockImgSkeleton}>
        <Skeleton height={200} />
      </div>
      <div className={styles.blockContent}>
        <h1 className={styles.blockSkeletonHeader}>
          <Skeleton count={window.innerWidth < 700 ? 1 : 2} />
        </h1>
        <div className={styles.blockSkeletonPara}>
          <Skeleton count={window.innerWidth < 700 ? 3 : 4} />
        </div>
      </div>
    </div>
  );
}

export default HomeNews;