import * as topojson from "topojson";
import * as d3Base from "d3";
import * as d3Multi from "d3-selection-multi";
import pakCSV  from '../resources/map/gallupFieldCap.csv';
import pakJSON  from '../resources/map/PakCons.topojson';
import jkJSON  from '../resources/map/JAndKashmir.topojson';

const d3 = Object.assign(d3Base, d3Multi);

async function readAndDraw(){
  const projection = d3.geoMercator()
      .center([75.9, 32])
      .scale([150 * 12]);

  const path = d3.geoPath().projection(projection);

  const pakGeo = await d3.json(pakJSON);
  const JAndK = await d3.json(jkJSON);
  const gallupCap = await d3.csv(pakCSV);

  const pakFeatures = topojson.feature(pakGeo, pakGeo.objects.PakCons).features;
  const jKFeatures = topojson.feature(JAndK, JAndK.objects.JAndKashmir).features;

  let svg = d3.select('svg.PakGallup');

  const pakPath = svg.selectAll('path.Pak')
    .data(pakFeatures)
    .enter()
    .append('path')
    .classed('Pak', true)
    .attr('d', d => path(d))
    .style('fill', '#eeeeee')
    //.style('stroke', 'grey');

  const JKPath = svg.selectAll('path.JK')
    .data(jKFeatures)
    .enter()
    .append('path')
    .classed('JK', true)
    .attr('d', d => path(d))
    .style('fill', '#fAfAfA')
    .style('stroke', 'grey')
    .style('stroke-dasharray', 2);

  svg.selectAll('circle.capCircs')
    .data(gallupCap)
    .enter()
    .append('circle')
    .attr('class', 'capCircs')
    .attr('cx', d => projection([+d.Long, +d.Lat])[0])
    .attr('cy', d => projection([+d.Long, +d.Lat])[1])
    .attr('r', 0)
    .styles({
      fill: d => d.Type == 'Full Service' ? '#1c436a' : '#f57c00',
      'fill-opacity' : 0.6,
      'stroke': 'black',
      'stroke-opacity': '0.7',
      'stroke-width': '0.5px'
    })
    .transition()
    .delay((d, i) => i * 100)
    .duration(1800)
    .ease(d3.easeElastic)
    .attr('r', d => d.Type == 'Full Service' ? 10 : 4);


  // call the append interaction Voronoi function
  const vorCircs = appendIntVoronoi(
    {
      x: d => projection([+d.Long, +d.Lat])[0],
      y: d => projection([+d.Long, +d.Lat])[1]
    },
    {
      x: d => projection([+d.Long, +d.Lat])[0],
      y: d => projection([+d.Long, +d.Lat])[1]
    },
    [
      [0, 0],
      [600, 600]
    ],
    svg,
    gallupCap,
    18
  );

  vorCircs.on('mouseover', function(d, i){
    const loc = d3.select(this).datum().Location
    const type = d3.select(this).datum().Type

    const eventX = d3.event.x;
    const eventY = d3.event.y;

    d3.select('body')
      .append('div')
      .classed('tooltip', true)
      .styles({
        position: 'absolute',
        width: '100px',
        opacity: 0,
        left: eventX - 50 + 'px',
        top: eventY + 20 + 'px',
        'background-color': 'rgba(200, 200, 200, 0.5)',
      })
      .html(`<p class="location">${loc}</p><p class="officeType">${type} Office</p>`)
      .transition()
      .duration(500)
      .style('opacity', 1);
  });

  vorCircs.on('mouseout', function(d, i){
    d3.select('div.tooltip').remove();
  })
}

function appendIntVoronoi(cellXY, circleXY, voronoiExt, svgSelection, pointData, circleRadius){

    // defining a function to compute voronoi celss
    const voronoi = d3.voronoi()
                    .x(cellXY.x)
                    .y(cellXY.y)
                    .extent(voronoiExt);

    // appending clipPaths in defs. This method assumes that there is no defs in the SVG from the get go
    const polygon =  svgSelection.append("defs")
                                .selectAll(".clip")
                                .data(voronoi.polygons(pointData))
                                //First append a clipPath element
                                .enter()
                                .append("clipPath")
                                .attr("class", "clip")
                                //Make sure each clipPath will have a unique id (connected to the circle element)
                                .attr("id", (d, i) => `clip-index${i}`)
                                //Then append a path element that will define the shape of the clipPath
                                .append("path")
                                .attr("class", "clip-path-circle")
                                .attr("d", d => `M${d.join(",")}Z`);

      //Append larger circles and clip them
    const clipCircSelection =
    svgSelection.append('g')
        .attr('id', 'CCatcher-Grp')
          .selectAll("circle.circle-catcher")
          .data(pointData)
          .enter()
          .append("circle")
          .attr("class", (d, i) => `circle-catcher index${i}`)
          //Apply the clipPath element by referencing the one with the same countryCode
          .attr("clip-path", (d, i) => `url(#clip-index${i})`)
          //Bottom line for safari, which doesn't accept attr for clip-path
          .style("clip-path", (d, i) => `url(#clip-index${i})`)
          .attr("cx", circleXY.x)
          .attr("cy", circleXY.y)
          //Make the radius a lot bigger (or a function depending on data)
          .attr("r", circleRadius)
          .style("fill", "grey")
          .style("opacity", 0.0)
          .style("pointer-events", "all")
          //Notice that we now have the mousover events on these circles

    return clipCircSelection;
}


export { readAndDraw };
