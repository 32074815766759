import React, { Component } from "react";
import styles from "../css-modules/home_vis.module.css";
import background from "../resources/vis_bg.jpg";
import classNames from "classnames";
import placeholder from "../resources/placeholder.jpg";
import ReactMarkdown from "react-markdown";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import axios from "axios";
import LazyLoad from "react-lazy-load";

class HomeVis extends Component {
  constructor(props) {
    super(props);
    this.state = { currIndex: 2 };

    const dataVisPromise = axios.get(
      "https://gallup.com.pk/wp/wp-json/wp/v2/posts?categories=881,879&per_page=5"
    );

    Promise.all([dataVisPromise]).then((res) => {
      this.setState({ dataVis: res[0].data });
      // console.log(res[4].data[0])
    });
  }

  render() {
    const { dataVis } = this.state;
    return (
      <div className={styles.home_vis_container}>
        <img
          src={background}
          className={styles.absoluteBackground}
          alt=""
        ></img>
        <div
          className={classNames("content_container", styles.home_vis_content)}
        >
          <p
            className={classNames("section_title", styles.home_vis_title)}
            style={{ textAlign: "center", color: "white" }}
          >
            Interactives
          </p>
          <div className={styles.vis_container}>
            {dataVis &&
              dataVis.length > 0 &&
              dataVis.map((dataVis, index) => (
                <div key={index} className={styles.vis_post}>
                  <Link
                    to={`/post/${dataVis.id}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {dataVis.jetpack_featured_media_url.length > 0 ? (
                      <LazyLoad>
                        <img
                          src={dataVis.jetpack_featured_media_url}
                          className={styles.vis_image}
                          alt={dataVis.title.rendered}
                        />
                      </LazyLoad>
                    ) : (
                      <LazyLoad>
                        <img
                          src={placeholder}
                          className={styles.vis_image}
                          alt={dataVis.title.rendered}
                        />
                      </LazyLoad>
                    )}
                    <div className={styles.separator}></div>
                    <div className={styles.vis_title}>
                      <ReactMarkdown
                        source={dataVis.title.rendered}
                        escapeHtml={false}
                      />
                    </div>
                  </Link>
                </div>
              ))}
          </div>
          <Link to="datavis" style={{ textDecoration: "none" }}>
            <Button className="yellow_button" variant="outlined">
              View All
            </Button>
          </Link>
        </div>
        <div className={styles.absoluteBorder}>
          <div className={styles.yellow_border}></div>
        </div>
      </div>
    );
  }
}

export default HomeVis;
