import React from 'react';
import styles from '../css-modules/dataViz.module.css';
import Pagination from '@material-ui/lab/Pagination';
import placeholder from '../resources/placeholder.jpg';
import Skeleton from 'react-loading-skeleton';
import YTDialog from './ytDialog';
// import Image from 'react-graceful-image';
import LazyLoad from "react-lazy-load";

function DataVizBlock(props) {
  const convertToFriendlyDate = (dateString) => {
    if (dateString) {
      const [day, month, year] = dateString.split("/"); // Split the date string into its components
      const date = new Date(`${year}-${month}-${day}`); // Create a new Date object using the components

      // Format the date in a friendly format
      const options = { year: "numeric", month: "long", day: "numeric" };
      const friendlyDate = date.toLocaleDateString(undefined, options);

      return friendlyDate;
    }
  };
    return (
      <div className={`${styles.ytBlockContainer}`}>
        <div 
        onClick={()=>props.handleVidOpen(props.videoId, props.title)}
        className={styles.imageContainer}>
          <LazyLoad>
            <img
             src={props.image}
             alt="post"
            />
          {/* <Image
          noLazyLoad
          src={props.image}
          /> */}
          </LazyLoad>
        </div>
        <div className={styles.dateThemeDiv}>
          <p>
            {/* {props.date} */}
            {convertToFriendlyDate(props.date)}
          </p>
        </div>
        <h1 className={styles.blockTitle}>
          {props.title}
        </h1>
    </div>
  );
}

function SkeletonDataVizBlock(props) {
  return (
    <div className={`${styles.ytBlockContainer}`}>
      <div className={styles.imageContainer}>
        <Skeleton height={200} />
      </div>
      <div className={`${styles.dateThemeDiv} ${styles.skeleton}`}>
        <Skeleton style={{ width: "100%" }} />
      </div>
      <div className={`${styles.skeleton} ${styles.skeletonHeading}`}>
        <Skeleton width="100%" />
      </div>
    </div>
  );
}

class DataVizSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showVid: false,
      vidId: null,
      vidTitle: null,
    };

    this.handleVidClose = this.handleVidClose.bind(this);
    this.handleVidOpen = this.handleVidOpen.bind(this);
  }

  handleVidClose() {
    this.setState({
      showVid: false,
      vidId: null,
      vidTitle: null,
    });
  }

  handleVidOpen(id, title) {
    this.setState({
      showVid: true,
      vidId: id,
      vidTitle: title,
    });
  }

  render() {
    let props = this.props;
    return (
      <div>
        <YTDialog
          open={this.state.showVid}
          videoId={this.state.vidId}
          title={this.state.vidTitle}
          handleClose={this.handleVidClose}
        />
        <div className={styles.blockSection}>
          {props.loadingPosts
            ? new Array(9).fill(null).map((d, i) => {
                return <SkeletonDataVizBlock key={i} />;
              })
            : props.posts.map((d, i) => {
                return (
                  <DataVizBlock
                    handleVidClose={this.handleVidClose}
                    handleVidOpen={this.handleVidOpen}
                    key={d.id}
                    {...d}
                    details={d}
                  />
                );
              })}
          {!props.loadingPosts && props.posts.length === 0 && (
            <h1>No Results Found</h1>
          )}
        </div>
        <div>
          {!props.loadingPosts && props.posts.length > 0 && (
            <Pagination
              siblingCount={0}
              page={props.currentPage}
              onChange={(e, p) => {
                props.setPage(p);
              }}
              count={props.totalPages}
              shape="rounded"
            />
          )}
        </div>
      </div>
    );
  }
}

export default DataVizSection;
