import React, { Component } from "react";
import BounceLoader from "react-spinners/BounceLoader";
import Fade from "react-reveal/Fade";
import NavBar from "../components/navbar";
import SectionTitle from "../components/sectiontitle";
import CachedPrevRender from "../components/CachedPrevRenderDisplay";
import Footer from "../components/footer";
import managementConsulting from "../resources/presentation.png";
import behavior from "../resources/thinking.png";
import thirdParty from "../resources/pie-chart.png";
import marketExploration from "../resources/profit.png";
import impactAssessment from "../resources/analytics.jpg";
import financialInc from "../resources/Plain credit card.png";
import ict from "../resources/computer.png";
import healthcare from "../resources/doctor.png";
import media from "../resources/market-research.png";
import socialResearch from "../resources/document.png";
import history from "../resources/history.png";
import styles from "../css-modules/whatWeDo.module.css";
import commonStyles from "../css-modules/common.module.css";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import { withRouter } from "react-router-dom";

class WhatWeDo extends Component {
  state = {
    loading: false,
    value: "0",
  };

  JSX = {
    1: {
      jsx: (
        <div className={styles.section_content} value="0">
          <div className="content_container">
            <div className={styles.programmes_container}>
              <div className={styles.program_container}>
                <div
                  id={styles.fin_icon}
                  className={styles.program_title_icon_contianer}
                >
                  <img src={financialInc} className={styles.program_icon}></img>
                </div>
                <div className={styles.program_details_container}>
                  <Fade top delay={75}>
                    <p className={styles.program_title}>
                      Financial Inclusion Research
                    </p>
                  </Fade>
                  <Fade delay={200}>
                    <div className={styles.program_separator} />
                    <p className={styles.program_details}>
                      In recent years, as part of its re-engineering strategy,
                      Gallup Pakistan has started working in the field of
                      Financial Inclusion. This includes survey and consultancy
                      work in both Demand and Supply side Surveys for
                      conventional (Banking, Insurance, Investment Instruments)
                      as well as non-conventional instruments such as
                      Microfinance Institutions, Microfinance Banks and Mobile
                      Money. Gallup partners with prestigious local institutions
                      such as State Bank of Pakistan (conducting Access to
                      Finance Study) and Apex Microfinance Body, PPAF (Impact
                      Evaluation of Microfinance in Pakistan) as well as
                      international institutions such as The World Bank (Cost of
                      Retail Payments Study), GSMA, Insight to Impact (i2i).
                      Bill and Melinda Gates Foundation and Bankable Frontiers
                      Associates (BFA).
                    </p>
                  </Fade>
                </div>
              </div>

              <div className={styles.program_container}>
                <div className={styles.program_title_icon_contianer}>
                  <img src={media} className={styles.program_icon}></img>
                </div>
                <div className={styles.program_details_container}>
                  <Fade top delay={75}>
                    <p className={styles.program_title}>Media Research</p>
                  </Fade>
                  <Fade delay={200}>
                    <div className={styles.program_separator} />
                    <p className={styles.program_details}>
                      Gallup carries out both ad hoc and continuous research,
                      specifically TV viewers’ panel, Consumer Panel,
                      Advertising monitoring, Third Party Evaluation, Monitoring
                      Studies, and Opinion Polls. It provides a wide range of
                      quantitative and qualitative consumer research and carries
                      out Mystery Shopping for a number of clients on an ongoing
                      basis.
                    </p>
                    <p className={styles.program_details}>
                      Gallup is the market leader in Media Research in Pakistan.
                      It provides the country’s Television Ratings; it is
                      subscribed to by all of the top ten advertising agencies;
                      and it carries out extensive research on newspaper and
                      radio audiences. Gallup Media Research Consultancy is the
                      only Service available in Pakistan which combines primary
                      research from all four medias (TV, Newspaper, Radio and
                      Social Media) and helps its local and International
                      clients in 360º Media buying/planning decisions.
                    </p>
                  </Fade>
                </div>
              </div>

              <div className={styles.program_container}>
                <div className={styles.program_title_icon_contianer}>
                  <img src={ict} className={styles.program_icon}></img>
                </div>
                <div className={styles.program_details_container}>
                  <Fade top delay={75}>
                    <p className={styles.program_title}>
                      ICT & Telecom Research
                    </p>
                  </Fade>
                  <Fade delay={200}>
                    <div className={styles.program_separator} />
                    <p className={styles.program_details}>
                      In the ICT Domain, Gallup’s Flagship Projects are “Gallup-
                      KPMG IT Review 2001” and “National ICT Indicators Survey
                      2014”. Both surveys provide consultancy to Government of
                      Pakistan with respect to current and future ICT Policy for
                      Pakistan.
                    </p>
                  </Fade>
                </div>
              </div>

              <div className={styles.program_container}>
                <div className={styles.program_title_icon_contianer}>
                  <img src={healthcare} className={styles.program_icon}></img>
                </div>
                <div className={styles.program_details_container}>
                  <Fade top delay={75}>
                    <p className={styles.program_title}>Healthcare Research</p>
                  </Fade>
                  <Fade delay={200}>
                    <div className={styles.program_separator} />
                    <p className={styles.program_details}>
                      Gallup Pakistan has done extensive work on the subject of
                      Health, Hygiene and Sanitation with special reference to
                      their awareness. We also conducted several behavioral
                      studies regarding Polio Vaccine Confidence, Smoking,
                      Hepatitis, Dengue Virus and COVID-19. Our healthcare
                      research experts at the forefront of investigating the
                      correlation between lifestyles and diseases in Pakistan.
                    </p>
                  </Fade>
                </div>
              </div>

              <div className={styles.program_container}>
                <div className={styles.program_title_icon_contianer}>
                  <img src={behavior} className={styles.program_icon}></img>
                </div>
                <div className={styles.program_details_container}>
                  <Fade top delay={75}>
                    <p className={styles.program_title}>Behavioral Science</p>
                  </Fade>
                  <Fade delay={200}>
                    <div className={styles.program_separator} />
                    <p className={styles.program_details}>
                      Gallup Pakistan has setup Pakistan’s first behavioral
                      science research lab, working with a decision science
                      Research Fellow at Warwick University. Similarly, Gallup
                      Pakistan is working in the field of advance data analytics
                      and using concepts such as ‘Predictive Modeling’ for
                      providing better future insights to our clients. In
                      another landmark study, Gallup Pakistan’s researchers
                      assisted in a Yale University study in the application of
                      Conjoint Analysis on electoral outcomes.
                    </p>
                  </Fade>
                </div>
              </div>

              <div
                value="Gallup History Project"
                className={styles.program_container}
              >
                <div className={styles.program_title_icon_contianer}>
                  <img src={history} className={styles.program_icon}></img>
                </div>
                <div className={styles.program_details_container}>
                  <Fade top delay={75}>
                    <p className={styles.program_title}>
                      Gallup History Project
                    </p>
                  </Fade>
                  <Fade delay={200}>
                    <div className={styles.program_separator} />
                    <p className={styles.program_details}>
                      The Gallup Pakistan History Project aims to compile,
                      analyze and disseminate Public Opinion Polling data
                      gathered by Gallup Pakistan since 1980 in a form that is
                      useful for informed academic discussion and public policy
                      initiatives inside and outside Pakistan. Over the years,
                      several University departments, Ph.D. students, and
                      Government agencies have benefited from our data. We
                      regularly communicate with our partners to improve our
                      dissemination strategy and are always keen to explore ways
                      to fund our research initiatives.
                    </p>
                  </Fade>
                </div>
              </div>

              <div className={styles.program_container}>
                <div className={styles.program_title_icon_contianer}>
                  <img
                    src={socialResearch}
                    className={styles.program_icon}
                  ></img>
                </div>
                <div className={styles.program_details_container}>
                  <Fade top delay={75}>
                    <p className={styles.program_title}>
                      Social and Political Research
                    </p>
                  </Fade>
                  <Fade delay={200}>
                    <div className={styles.program_separator} />
                    <p className={styles.program_details}>
                      Gallup Pakistan regularly conducts public opinion polls to
                      gauge the present socio-political climate of the country.
                    </p>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    0: {
      jsx: (
        <div value="1" className={styles.gray_section}>
          <div className="content_container">
            <div className={styles.services_container}>
              <div className={styles.service_container}>
                <div className={styles.service_title_icon_contianer}>
                  <img src={behavior} className={styles.service_icon}></img>
                </div>
                <div className={styles.skewY}>
                  <div className={styles.skewYChild}></div>
                </div>
                <div className={styles.service_details_container}>
                  <p className={styles.service_title}>
                    Behavioral & Attitudinal Studies
                  </p>
                  <div className={styles.service_separator} />
                  <p className={styles.service_details}>
                    For decades, Gallup Pakistan has been a pioneer in assessing
                    individual beliefs and actions, identifying patterns and
                    drawing inferences as to what drives different behaviors
                    among respondents.
                  </p>
                </div>
              </div>

              <div className={styles.service_container}>
                <div className={styles.service_title_icon_contianer}>
                  <img
                    src={impactAssessment}
                    className={styles.service_icon}
                  ></img>
                </div>
                <div className={styles.skewY}>
                  <div className={styles.skewYChild}></div>
                </div>
                <div className={styles.service_details_container}>
                  <p className={styles.service_title}>
                    Impact Assessment Surveys
                  </p>
                  <div className={styles.service_separator} />
                  <p className={styles.service_details}>
                    Being a survey research organization with strong roots in
                    impact assessment, we regularly work with clients to assess
                    the efficacy of their particular projects, policies or
                    humanitarian interventions on the overall wellbeing of the
                    beneficiaries; individuals, households or firms.
                  </p>
                </div>
              </div>

              <div
                curr_service="Third-Party Evaluations"
                className={styles.service_container}
              >
                <div className={styles.service_title_icon_contianer}>
                  <img src={thirdParty} className={styles.service_icon}></img>
                </div>
                <div className={styles.skewY}>
                  <div className={styles.skewYChild}></div>
                </div>
                <div className={styles.service_details_container}>
                  <p className={styles.service_title}>
                    Third-Party Evaluations
                  </p>
                  <div className={styles.service_separator} />
                  <p className={styles.service_details}>
                    We provide third-party evaluation services to clients to
                    assess implementations and outcomes by using data collection
                    and analysis techniques adopting tailored approaches that
                    fit the client’s criteria and effectively gauge key areas of
                    interest.
                  </p>
                </div>
              </div>

              <div
                curr_service="Management Consulting"
                className={styles.service_container}
              >
                <div className={styles.service_title_icon_contianer}>
                  <img
                    src={managementConsulting}
                    className={styles.service_icon}
                  ></img>
                </div>
                <div className={styles.skewY}>
                  <div className={styles.skewYChild}></div>
                </div>
                <div className={styles.service_details_container}>
                  <p className={styles.service_title}>Management Consulting</p>
                  <div className={styles.program_separator} />
                  <p className={styles.service_details}>
                    Gallup Pakistan specializes in helping both private and
                    public organizations to achieve sustainable operational
                    performance by collecting relevant insights essential to
                    drive structural reform.
                  </p>
                </div>
              </div>

              <div
                curr_service="Market Exploration & Assessment Studies"
                className={styles.service_container}
              >
                <div className={styles.service_title_icon_contianer}>
                  <img
                    src={marketExploration}
                    className={styles.service_icon}
                  ></img>
                </div>
                <div className={styles.skewY}>
                  <div className={styles.skewYChild}></div>
                </div>
                <div className={styles.service_details_container}>
                  <p className={styles.service_title}>
                    Market Exploration & Assessment Studies
                  </p>
                  <div className={styles.service_separator} />
                  <p className={styles.service_details}>
                    We help clients explore new avenues of market entry, product
                    expansion, product distribution and channels to help firms
                    make more informed decisions.
                  </p>
                </div>
              </div>

              <div
                curr_service="Market & Market Research Studies"
                className={styles.service_container}
              >
                <div className={styles.service_title_icon_contianer}>
                  <img
                    src={socialResearch}
                    className={styles.service_icon}
                  ></img>
                </div>
                <div className={styles.skewY}>
                  <div className={styles.skewYChild}></div>
                </div>
                <div className={styles.service_details_container}>
                  <p className={styles.service_title}>
                    Market & Market Research Studies
                  </p>
                  <div className={styles.service_separator} />
                  <p className={styles.service_details}>
                    We collect detailed and specific consumer insights including
                    but not limited to market comparative, competitor, product
                    and territory analysis that are essential in aiding
                    businesses solve wide and complex market challenges.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
  };

  openQuickLink(quickLinkId) {
    let defaultState =
      this.props.pageStateInitFunctions.getSearchResInitState();

    defaultState.filterState.onMountFilter = true;
    defaultState.filterState.filtered = true;

    defaultState.filterState.categoriesFilterSet.add(quickLinkId + "");
    defaultState.filterState.params.categories = [
      ["categories", quickLinkId + ""],
    ];

    this.props.setPageStateWithoutUpdate("searchRes", defaultState);

    this.props.history.push("/search");
  }

  handleChange = (e, newValue) => {
    var value = newValue.toString();
    this.setState({
      value: value,
    });
  };

  render() {
    var jsxFunction;
    if (this.state.value) {
      jsxFunction = this.JSX[this.state.value].jsx;
    }
    return (
      <div
        id="scroll_view"
        ref={this.props.animationRef}
        className={commonStyles.view}
      >
        {!this.props.loadedAppData ? (
          <div className="loading">
            <BounceLoader
              sizeUnit={"px"}
              size={40}
              color={"#1E4D79"}
              loading={!this.props.loadedAppData}
            />
          </div>
        ) : (
          <div>
            <NavBar
              links={[
                { linkText: "Home", to: "" },
                { linkText: "ABOUT US", to: "about" },
                { linkText: "What We Do", to: "gallup" },
                { linkText: "News & Updates", to: "news" },
                { linkText: "Publications", to: "publications" },
                {
                  linkText: "Daily Polls",
                  to: "daily-polls",
                  onClick: () => this.openQuickLink("437,438"),
                },
                { linkText: "Interactives", to: "datavis" },
                { linkText: "Gallup International", to: "gallupinternational" },
                { linkText: "Contact Us", to: "contact" },
              ]}
            />
            <SectionTitle title="What We Do" />
            <div className="padded_section">
              <div className={styles.tabbed_content}>
                <div className={styles.tabs_container}>
                  <Paper square style={{ width: "fit-content" }}>
                    <Tabs
                      value={parseInt(this.state.value)}
                      indicatorColor="primary"
                      textColor="primary"
                      onChange={this.handleChange}
                    >
                      <Tab label="Services" />
                      <Tab label="Programmes" />
                    </Tabs>
                  </Paper>
                </div>
                <div className={styles.tab_content_wrapper}>
                  <CachedPrevRender
                    value={this.state.value}
                    shouldUpdate={(prevProps, currProps) => {
                      let update =
                        currProps.value && prevProps.value !== currProps.value;
                      return update;
                    }}
                  >
                    {jsxFunction}
                  </CachedPrevRender>
                </div>
              </div>
            </div>

            <Footer
              links={[
                { linkText: "Affiliations & Memberships", to: "about" },
                { linkText: "Contact Us", to: "contact" },
              ]}
              researchAreas={this.props.topSections.map((d) => {
                let data = this.props.idSectionMap[d];
                return { linkText: data.name, id: d };
              })}
              pageStateInitFunctions={this.props.pageStateInitFunctions}
              setPageStateWithoutUpdate={this.props.setPageStateWithoutUpdate}
            />
          </div>
        )}
      </div>
    );
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

export default withRouter(WhatWeDo);
