import React, { Component } from 'react';
import loadPostsMixin from '../mixins/loadPostsMixin';

function withFilter(WrappedComponent){
	return class withFilter extends Component {
		constructor(props){
			super(props);
			window.wF = this;
			
			this.toggleCategoryFilter = this.toggleCategoryFilter.bind(this);
			this.toggleSectionFilter = this.toggleSectionFilter.bind(this);
			this.toggleYearFilter = this.toggleYearFilter.bind(this);
			this.applyFilter = this.applyFilter.bind(this);
			this.clearFilter = this.clearFilter.bind(this);
			this.getFilterRenderArr = this.getFilterRenderArr.bind(this);
			this.loadFilterData = this.loadFilterData.bind(this);
			this.getFilterParams = this.getFilterParams.bind(this);
		}

		getFilterRenderArr(hierarchyObj, idDataMap, hierarchyOrder){

			hierarchyOrder = hierarchyOrder || Object.keys(hierarchyObj);
			return hierarchyOrder.map(d=>{
				let data = idDataMap[d];
				let children = Object.keys(hierarchyObj[d]).map(e => ({
					name : idDataMap[e].name,
					id : e + ''
				}));
				return {name : data.name, id : d + '', children : children}
			})
		}

		toggleCategoryFilter(id, checked){
			const totalFilters = this.props.categories.length;
			const filterSet = this.props.filterState.categoriesFilterSet;
			if(checked){
				filterSet.add(id)
			}else{
				filterSet.delete(id)
			}

			let filterParams = this.props.filterState.params;
	
			if(filterSet.size && totalFilters !== filterSet.size){
				filterParams['categories'] = [['categories', Array.from(filterSet.keys()).join(',')]]
			}else{
				delete filterParams['categories']
			}
	
			this.props.setPageState(this.props.pageStateKey,{
				filterState : {
					...this.props.filterState,
					params : filterParams				
				}
			})
		}
	
		toggleSectionFilter(id, checked){
			const totalFilters = Object.keys(this.props.idSectionMap).length;
			const filterSet = this.props.filterState.sectionFilterSet;
			if(checked){
				filterSet.add(id)
			}else{
				filterSet.delete(id)
			}

			let filterParams = this.props.filterState.params;
	
			if(filterSet.size && totalFilters !== filterSet.size){
				filterParams['section'] = [['section', Array.from(filterSet.keys()).join(',')]]
			}else{
				delete filterParams['section']
			}
	
			this.props.setPageState(this.props.pageStateKey,{
				filterState : {
					...this.props.filterState,
					params : filterParams				
				}
			})
		}
	
		toggleYearFilter(e, yearRange){
			const maxYearRange = this.props.filterState.defaultYearRange;

			let filterParams = this.props.filterState.params;
	
			if(yearRange[0] === maxYearRange[0] && yearRange[1] === maxYearRange[1]){
				delete filterParams['year'];
			}else{
				filterParams['year'] = [
					['before', new Date(yearRange[1] + 1,0,1).toISOString()],
					['after', new Date(yearRange[0] - 1,0,1).toISOString()]
				]
			}
	
			console.log(yearRange)
	
			this.props.setPageState(this.props.pageStateKey,{
				filterState : {
					...this.props.filterState,
					params : filterParams,
					currYearRange : yearRange
				}
			})
		}
	
		async applyFilter(clear){

			let size = Object.values(this.props.filterState.params).length;
	
			if(size){
				this.props.setPageState(this.props.pageStateKey,{
					loadingResults : true
				})
				let postsToPage = await this.loadFilterData();
				console.log(postsToPage);
				this.props.setPageState(this.props.pageStateKey,{
					postsToPage : postsToPage.posts,
					filterState : {
						...this.props.filterState,
						filtered : true
					},
					currentPage : 1,
					totalPostsToPage : postsToPage.totalPostsToPage,
					loadingResults : false
				})
			}else{
				this.clearFilter();
			}
		}
	
		async loadFilterData(page=1){
			let params = this.getFilterParams();
			console.log(params);
			let newPostsData = await loadPostsMixin.lazyLoadPosts(page,params, [], 
				this.props.postsPerPage, this.props.totalPosts, this.props.idCategoryMap);
			console.log(newPostsData);
			return newPostsData;
		}

		getFilterParams(){
			let params = {};

			if(this.props.defaultFilterParams){
				Object.values(this.props.defaultFilterParams).forEach(d => {
					d.forEach(param=>{
						params[param[0]] = param[1];
					});
				});
			}
			
			Object.values(this.props.filterState.params).forEach(d => {
				d.forEach(param=>{
					params[param[0]] = param[1];
				});
			});

			return params;
		}
	
		clearFilter(){
			console.log(this.props.getDefaultFilterState());
			this.props.setPageState(this.props.pageStateKey,{
				postsToPage : this.props.posts,
				filterState : this.props.getDefaultFilterState(),
				currentPage : 1,
				totalPostsToPage : this.props.totalPosts,
				loadedData : !this.props.filterState.onMountFilter,
				onMountFilter : false
			})
		}

		render(){
			
			return <WrappedComponent 
				{...this.props}
				toggleCategoryFilter={this.toggleCategoryFilter}
				toggleSectionFilter={this.toggleSectionFilter}
				toggleYearFilter={this.toggleYearFilter}
				applyFilter={this.applyFilter}
				clearFilter={this.clearFilter}
				getFilterRenderArr={this.getFilterRenderArr}
				loadFilterData={this.loadFilterData}
				getFilterParams={this.getFilterParams}
			/>
		} 
	}
}

export default withFilter;
