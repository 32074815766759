import React from "react";
import whatsapp from '../resources/whatsapp.svg';
import gallup from '../resources/gallup.svg';

const GallupWhatsAppPopup = ({ onClose }) => {
    const handleJoinClick = () => {
        const url = "https://www.whatsapp.com/channel/0029Vb2h2dKKgsNsekn29i40";
        window.open(url, "_blank");
        onClose();
    };

    return (
        <div style={styles.overlay}>
            <div style={styles.popup}>
                <button style={styles.closeButton} onClick={onClose}>
                    &times;
                </button>
                <div style={styles.header}>
                    <img
                        src={gallup}
                        alt="Gallup Pakistan Logo"
                        style={styles.logo}
                    />
                </div>
                <div style={styles.content}>
                    <div style={styles.iconContainer}>
                        <img
                            src={whatsapp}
                            alt="WhatsApp Icon"
                            style={styles.icon}
                        />
                    </div>
                    <h2 style={styles.title}>
                        Introducing Gallup Pakistan WhatsApp Channel!
                    </h2>
                    <p style={styles.text}>
                        Stay updated on the latest public opinion trends in Pakistan. Our
                        channel will share insights from our survey research, expert
                        analysis, and more.
                    </p>
                    <button style={styles.button} onClick={handleJoinClick}>
                        Join Us Now!
                    </button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
    },
    popup: {
        width: "400px",
        backgroundColor: "#fff",
        borderRadius: "1rem",
        boxShadow: "0 2px 2px rgba(0, 0, 0, 0.2)",
        overflow: "hidden",
        fontFamily: "Arial, sans-serif",
        position: "relative",
    },
    closeButton: {
        position: "absolute",
        right: "10px",
        background: "none",
        border: "none",
        fontSize: "1.9rem",
        cursor: "pointer",
        color: "#333",
    },
    header: {
        padding: "1rem",
        marginTop: "1rem",
    },
    logo: {
        height: "2rem",
    },
    content: {
        textAlign: "center",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        paddingBottom: "1rem",
    },
    iconContainer: {
        marginBottom: "15px",
    },
    icon: {
        width: "80px",
        height: "80px",
    },
    title: {
        fontSize: "1rem",
        fontWeight: "bold",
        color: "#333",
        marginBottom: "10px",
    },
    text: {
        fontSize: "0.8rem",
        color: "#555",
        marginBottom: "1rem",
        lineHeight: "1.6",
    },
    button: {
        backgroundColor: "#29A71A",
        color: "#fff",
        fontSize: "0.85rem",
        padding: "10px 16px",
        border: "none",
        borderRadius: "2rem",
        cursor: "pointer",
        fontWeight: "bold",
        minWidth: "80%"
    },
};

export default GallupWhatsAppPopup;
