/* eslint-disable no-undef */
import React, { Component } from "react";
import styles from "../css-modules/footer.module.css";
import newspaperLogo from "../resources/newsletter.png";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "../resources/logo.jpg";
import classNames from "classnames";
// import { TextField, InputLabel, Input } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import Button from "@material-ui/core/Button";
import Fade from "react-reveal/Fade";
import jsonp from "jsonp";
class Footer extends Component {
  constructor(props) {
    super(props);
    this.openResearchArea = this.openResearchArea.bind(this);
    this.state = {
      thankSubscription: false,
      errorSubscription: false,
      subscriptionEmail: "",
    };
  }

  gallupInt = () => {
    window.open(
      "https://www.gallup-international.com/",
      "Gallup International"
    );
  };

  twitter = () => {
    window.open("https://twitter.com/galluppak", "TwitterProfile");
  };

  facebook = () => {
    window.open("https://www.facebook.com/GallupPak", "FacebookProfile");
  };

  linkedIn = () => {
    window.open(
      "https://www.linkedin.com/company/gallup-pakistan/",
      "LinkedInProfile"
    );
  };

  youtube = () => {
    window.open("https://www.youtube.com/user/galluppk", "LinkedInProfile");
  };

  openResearchArea(id) {
    let defaultState =
      this.props.pageStateInitFunctions.getSearchResInitState();

    defaultState.filterState.onMountFilter = true;
    defaultState.filterState.filtered = true;

    defaultState.filterState.sectionFilterSet.add(id + "");
    defaultState.filterState.params.categories = [["section", id + ""]];

    this.props.setPageStateWithoutUpdate("searchRes", defaultState);
    this.props.history.push({ pathname: `/search` });
  }

  validateEmail(email) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  handleContentSubscription() {
    // e.preventDefault();
    const email = this.state.subscriptionEmail;
    console.log(email, "email")
    const url =
      "https://galluppakistan.us21.list-manage.com/subscribe/post?u=56aab0c8c219964727b1b6137&amp;id=19da041b4e&amp;f_id=00be5fe1f0";
    jsonp(`${url}&EMAIL=${email}`, { param: "c" }, (err, data) => {
      console.log(data, "Hello");
      // const { msg, result } = data;

      // do something with response
      // do something with response
      // alert(msg);
    });
    setTimeout(
      this.setState({
        thankSubscription: true,
        subscriptionEmail: "",
      }),
      3000
    );
    // if (this.validateEmail(this.state.subscriptionEmail)) {
    //   axios
    //     .post(`https://api.sendgrid.com/v3/contactdb/recipients`, [
    //       {
    //         email: this.state.subscriptionEmail,
    //       },
    //     ])
    //     .then(() => {
    //       this.setState({
    //         thankSubscription: true,
    //         subscriptionEmail: "",
    //       });
    //     });
    // } else {
    //   this.setState({
    //     errorSubscription: true,
    //   });
    // }
  }

  handleChange = (param, e) => {
    this.setState({ [param]: e.target.value });
  };

  render() {
    return (
      <div className={styles.footer_container}>
        <div className={styles.colored_footer}>
          <div className={styles.flexed_footer_container}>
            <div className={styles.gallup_pakistan_container}>
              <p className={styles.gallup_pakistan_title}>Gallup Pakistan</p>
              <p className={styles.gallup_pakistan_desc}>
                Gallup Pakistan, the Pakistani affiliate of Gallup
                International, is a leading survey research agency and an
                emerging Social Science Research Lab in Pakistan. Gallup
                Pakistan is a specialist in independent third party evaluation
                and ratings.
              </p>
              <img src={logo} className={styles.gallupLogo} />
            </div>
            <div className={styles.research_areas_container}>
              <div className={styles.rTitle}>
                <p className={styles.research_areas_title}>Research Areas</p>
              </div>
              {/* <p className={styles.research_areas_title}>Research Areas</p> */}
              {this.props.researchAreas &&
                this.props.researchAreas.length > 0 &&
                this.props.researchAreas.map((link, index) => (
                  <div
                    key={index}
                    onClick={() => this.openResearchArea(link.id)}
                    className={styles.research_areas_link}
                  >
                    <p style={{ cursor: "pointer" }}>{link.linkText}</p>
                  </div>
                ))}
            </div>
            <div className={styles.subscribe_container}>
              <div className={styles.subscribe_title_container}>
                <img src={newspaperLogo} className={styles.newspaperLogo} />
                <p className={styles.subscription_title}>Weekly Content</p>
              </div>
              <p className={styles.subscription_sub_title}>
                Subscribe to receive Gallup's weekly content
              </p>
              <div className={styles.subscription_input_container}>
                <div
                  className={classNames(
                    styles.margined_input,
                    "footer_input_container"
                  )}
                >
                  {/* <InputLabel htmlFor="subscription-email" className={styles.lable}>Email*</InputLabel> */}
                  <TextField
                    value={this.state.subscriptionEmail}
                    onChange={this.handleChange.bind(this, "subscriptionEmail")}
                    id="standard-basic"
                    label="Email"
                    name="EMAIL"
                    variant="standard"
                  />
                  {/* <TextField id="standard-basic" label="Email" variant="standard" /> */}
                </div>
                <Button
                  onClick={(e) => this.handleContentSubscription()}
                  style={{ marginTop: "8px" }}
                  className="yellow_button"
                  variant="outlined"
                >
                  Submit
                </Button>
              </div>
              {this.state.thankSubscription === true ? (
                <Fade>
                  <p className={styles.sub_thankyou}>Subscribed Successful</p>
                </Fade>
              ) : (
                <div style={{ height: "22px" }}>
                  {this.state.errorSubscription === true ? (
                    <Fade>
                      <p className={styles.sub_error}>Incorrect Email Format</p>
                    </Fade>
                  ) : (
                    <p className={styles.sub_thankyou}></p>
                  )}
                </div>
              )}
              <div className={styles.social_container}>
                <FaLinkedinIn
                  onClick={() => this.linkedIn()}
                  className={styles.social_icon}
                />
                <FaTwitter
                  onClick={() => this.twitter()}
                  className={styles.social_icon}
                />
                <FaFacebookF
                  onClick={() => this.facebook()}
                  className={styles.social_icon}
                />
                <FaYoutube
                  onClick={() => this.youtube()}
                  className={styles.social_icon}
                />
              </div>
            </div>
          </div>
          <div className={styles.disclaimer_container}>
            <p className={styles.disclaimer_title}>Disclaimer</p>
            <div className={styles.disclaimer_text_container}>
              <p className={styles.disclaimer_text_margined}>
                Gallup Pakistan is not related to Gallup Inc. headquartered in
                Washington D.C. USA. We require that our surveys be credited
                fully as Gallup Pakistan (not Gallup or Gallup Poll). We
                disclaim any responsibility for surveys pertaining to Pakistani
                public opinion except those carried out by Gallup Pakistan, the
                Pakistani affiliate of Gallup International Association.{" "}
              </p>
              <p className={styles.disclaimer_text}>
                For details on Gallup International Association see website:{" "}
              </p>
              <p
                onClick={() => this.gallupInt()}
                style={{ cursor: "pointer" }}
                className={classNames(
                  styles.disclaimer_text,
                  styles.gallupInternationalLink
                )}
              >
                www.gallup-international.com
              </p>
            </div>
          </div>
        </div>
        <div className={styles.white_footer}>
          <div className={styles.copyright_container}>
            <p>
              Copyright © {new Date().getFullYear()} Gallup Pakistan. All rights
              reserved.
            </p>
          </div>
          <div className={styles.white_footer_links}>
            {this.props.links &&
              this.props.links.length > 0 &&
              this.props.links.map((link, index) => (
                <Link
                  key={link.to}
                  to={link.to}
                  style={{ textDecoration: "none" }}
                >
                  <div className={styles.white_footer_link}>
                    <p>{link.linkText}</p>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Footer);
