import React, { Component } from 'react';
import { readAndDraw } from './d3-world-map';
import styles from '../css-modules/map.module.css';
import topojsonFile  from '../resources/map/worldMap.topojson';

class WorldMap extends Component {

  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
  }

  componentDidMount() {
    readAndDraw(topojsonFile);
  }

  render () {
    return (
      <div ref={this.mapRef} className={`${styles.mapContain}`}>
        <div className={`${styles.visContain} visContain`}>
          <svg className="worldMap" viewBox="0 0 1200 600" ></svg>
        </div>
      </div>
    )
  }
}

export default WorldMap;
