import React, { Component } from "react";
import styles from "../css-modules/home_daily_poll.module.css";
import ReactMarkdown from "react-markdown";
import { TwitterTweetEmbed } from "react-twitter-embed";
import { Link } from "react-router-dom";
import remarkStripHtml from "remark-strip-html";

const plugins = [remarkStripHtml];

class HomeDailyPolls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currIndex: 9,
      iframeWidth: "100%", // Initially, set to 100%
      dailyPolls: [],
    };
    this.dailyPollsContainerRef = React.createRef(); // Create a ref for the container

    fetch(
      "https://gallup.com.pk/wp/wp-json/wp/v2/posts?categories=438&per_page=10"
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ dailyPolls: data.slice(5, 10), currIndex: 2 });
      })
      .catch((error) => {
        console.error("Error fetching homebanner:", error);
      });
  }

  componentDidMount() {
    setTimeout(this.updateIframeWidth, 100); // Small delay to ensure layout is ready
    window.addEventListener("resize", this.updateIframeWidth); // Update on resize
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateIframeWidth); // Cleanup listener
  }

  updateIframeWidth = () => {
    // Use requestAnimationFrame to ensure the width calculation happens after the layout is stable
    const container = this.dailyPollsContainerRef.current;
    if (container) {
      const newWidth = `${container.offsetWidth}px`; // Get the width of the container
      if (newWidth !== this.state.iframeWidth) {
        this.setState({ iframeWidth: newWidth }); // Update iframe width in state
      }
    }
  };

  render() {
    const { dailyPolls, iframeWidth } = this.state;

    return (
      <div
        className={styles.daily_polls_container}
        ref={this.dailyPollsContainerRef} // Attach the ref here
      >
        <div className={styles.daily_polls}>
          {dailyPolls &&
            dailyPolls.length > 0 &&
            dailyPolls.map((dailyPoll, index) => (
              <Link
                key={index}
                to={{ pathname: `/post/${dailyPoll.id}` }}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <div className={styles.daily_poll}>
                  <div className={styles.absolute_daily_poll_border}></div>
                  <div className={styles.dailyPoll}>
                    <ReactMarkdown
                      plugins={plugins}
                      source={dailyPoll.title.rendered}
                      escapeHtml={false}
                    />
                  </div>
                </div>
              </Link>
            ))}
        </div>

        <div className={styles.facebook_feed_container}>
          <iframe
            src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FGallupPak%2F&tabs=timeline&height=400&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=519411567720225"
            width="440"
            height="400"
            scrolling="no"
            frameBorder="0"
            allowFullScreen={true}
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          ></iframe>
        </div>
      </div>
    );
  }
}

export default HomeDailyPolls;
