import React, { Component } from "react";
import styles from "../css-modules/news.module.css";
import { withRouter } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import commonStyles from "../css-modules/common.module.css";

class Topics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredTopics: props.topics,
      filterValue: "",
    };
    this.filterTopics = this.filterTopics.bind(this);
    this.setTopic = this.setTopic.bind(this);
  }

  setTopic(e) {
    this.props.setPageStateWithoutUpdate(
      "topic",
      this.props.pageStateInitFunctions.getTopicInitState()
    );
    this.props.history.push({ pathname: `/topics/${e.slug}` });
  }

  filterTopics(val) {
    let newFilterTopics = this.props.topics.map((d) => {
      return {
        letter: d.letter,
        topics: d.topics.filter((e) => e.name.match(new RegExp(val, "i"))),
      };
    });

    this.setState({
      filteredTopics: newFilterTopics,
      filterValue: val,
    });
  }

  render() {
    console.log(this.state.filteredTopics)
    return (
      <div>
        <div className={commonStyles.filter_text_container}>
          <TextField
            style={{ margin: "20px 0px" }}
            label="Filter"
            id="outlined-size-normal"
            value={this.state.filterValue}
            onChange={(e) => {
              return this.filterTopics(e.target.value);
            }}
          />
        </div>
        <div className={styles.topicsContainer}>
          <TopicColumn
            setTopic={this.setTopic}
            start={0}
            end={7}
            topics={this.state.filteredTopics}
          />
          <TopicColumn
            setTopic={this.setTopic}
            start={7}
            end={16}
            topics={this.state.filteredTopics}
          />
          <TopicColumn
            setTopic={this.setTopic}
            start={16}
            end={27}
            topics={this.state.filteredTopics}
          />
        </div>
      </div>
    );
  }
}

function TopicColumn(props) {
  let topics = props.topics.slice(props.start, props.end);

  let sliceTopics = topics.map((d) => {
    if (d.topics.length === 0) {
      return null;
    }
    return <LetterTopics key={d.letter} setTopic={props.setTopic} data={d} />;
  });

  if (sliceTopics.every((d) => d === null)) {
    return null;
  }

  return <div className={styles.topicsColumn}>{sliceTopics}</div>;
}

function LetterTopics(props) {
  let data = props.data;

  // Create a map to track duplicate names
  const nameMap = {};

  return (
    <div className={commonStyles.letter_topics_container}>
      <h1 className={commonStyles.letter_container}>{data.letter}</h1>
      <div className={commonStyles.letter_topic_separator}></div>
      <div className={commonStyles.letter_topics_topics}>
        {data.topics.map((e, i) => {
          const displayName = nameMap[e.name]
            ? `${e.name} (${e.slug})`
            : e.name;
          nameMap[e.name] = true; // Mark this name as encountered

          return (
            <p
              onClick={() => {
                props.setTopic(e);
              }}
              key={e.id}
            >
              {displayName} (<span>{e.count}</span>)
            </p>
          );
        })}
      </div>
    </div>
  );
}

export default withRouter(Topics);
