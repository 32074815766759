import React, { Component } from "react";
import BounceLoader from "react-spinners/BounceLoader";
import NavBar from "../components/navbar";
import SectionTitle from "../components/sectiontitle";
import commonStyles from "../css-modules/common.module.css";
import Footer from "../components/footer";
import PostsSection from "../components/posts";
import Paginator from "../components/paginator";
import Slider from "@material-ui/core/Slider";
import Button from "@material-ui/core/Button";
import loadPostsMixin from "../mixins/loadPostsMixin";
import withFilter from "../components/withFilter";
import Collapse from "@material-ui/core/Collapse";
import { MdKeyboardArrowDown } from "react-icons/md";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import Fab from "@material-ui/core/Fab";
import { MdEdit } from "react-icons/md";
import { MdClose } from "react-icons/md";
import Fade from "@material-ui/core/Fade";
import { withRouter } from "react-router-dom";

function defaultRender() {
  let showLoadingSkeleton = !this.props.loadedData || this.props.loadingResults;
  let categoriesHierarchy = Array.isArray(
    this.props.categoriesHierarchyRenderArr
  )
    ? this.props.categoriesHierarchyRenderArr
    : this.props.getFilterRenderArr(
        this.props.filterCategories.reduce((acc, d) => {
          acc[d] = [];
          return acc;
        }, {}),
        this.props.idCategoryMap,
        this.props.filterCategories
      );
  let sectionHierarchy = Array.isArray(this.props.sectionHierarchyRenderArr)
    ? this.props.sectionHierarchyRenderArr
    : this.props.getFilterRenderArr(
        this.props.sectionHierarchy,
        this.props.idSectionMap
      );

  return (
    <div
      id="scroll_view"
      ref={this.props.animationRef}
      className={commonStyles.view}
    >
      <div>
        <NavBar
          links={[
            { linkText: "Home", to: "" },
            { linkText: "ABOUT US", to: "about" },
            { linkText: "What We Do", to: "gallup" },
            { linkText: "News & Updates", to: "news" },
            { linkText: "Publications", to: "publications" },
            {
              linkText: "Daily Polls",
              to: "daily-polls",
              onClick: () => this.openQuickLink("437,438"),
            },
            { linkText: "Interactives", to: "datavis" },
            { linkText: "Gallup International", to: "gallupinternational" },
            { linkText: "Contact Us", to: "contact" },
          ]}
        />
        <SectionTitle title="News & Updates" />
        <div className={commonStyles.container}>
          <div className={commonStyles.contentFilterDiv}>
            <Paginator
              posts={this.props.postsToPage}
              postsPerPage={this.props.postsPerPage}
              totalPosts={this.props.totalPostsToPage}
              currentPage={this.props.currentPage}
              setPage={this.setPage}
              loadingPosts={showLoadingSkeleton}
            >
              <PostsSection />
            </Paginator>
            <FilterSection
              categoriesHierarchy={categoriesHierarchy}
              idCategoryMap={this.props.idCategoryMap}
              sectionHierarchy={sectionHierarchy}
              idSectionMap={this.props.idSectionMap}
              toggleSectionFilter={this.props.toggleSectionFilter}
              selectedSections={this.props.filterState.sectionFilterSet}
              toggleCategoryFilter={this.props.toggleCategoryFilter}
              selectedThemes={this.props.filterState.categoriesFilterSet}
              sliderKey={this.props.filterState.sliderKey}
              toggleYearFilter={this.props.toggleYearFilter}
              yearMin={this.props.filterState.defaultYearRange[0]}
              yearMax={this.props.filterState.defaultYearRange[1]}
              currYearRange={this.props.filterState.currYearRange}
              applyFilter={this.props.applyFilter}
              filtered={this.props.filterState.filtered}
              clearFilter={this.props.clearFilter}
              disabled={showLoadingSkeleton}
            />
          </div>
        </div>
        <Footer
          links={[
            { linkText: "Affiliations & Memberships", to: "about" },
            { linkText: "Contact Us", to: "contact" },
          ]}
          researchAreas={this.props.topSections.map((d) => {
            let data = this.props.idSectionMap[d];
            return { linkText: data.name, id: d };
          })}
          pageStateInitFunctions={this.props.pageStateInitFunctions}
          setPageStateWithoutUpdate={this.props.setPageStateWithoutUpdate}
        />
      </div>
    </div>
  );
}

function loadingAppDataRender() {
  return (
    <div ref={this.props.animationRef} className={commonStyles.view}>
      <div className="loading">
        <BounceLoader
          sizeUnit={"px"}
          size={40}
          color={"#222F55"}
          loading={true}
        />
      </div>
    </div>
  );
}

const componentStates = {
  LOADING: (function loadingMethodsModule() {
    function render() {
      if (!this.props.loadedAppData) {
        return loadingAppDataRender.call(this);
      } else {
        return defaultRender.call(this);
      }
    }

    async function loadPostsAndUpdateState() {
      let params = this.props.getFilterParams();

      let res = await this.loadPosts(params);

      this.props.setPageState(this.props.pageStateKey, {
        loadedData: true,
        posts: res.posts,
        totalPosts: res.totalPosts,
        postsToPage: res.posts,
        firstLoad: false,
        totalPostsToPage: res.totalPosts,
        categoriesHierarchyRenderArr: this.props.getFilterRenderArr(
          this.props.filterCategories.reduce((acc, d) => {
            acc[d] = [];
            return acc;
          }, {}),
          this.props.idCategoryMap,
          this.props.filterCategories
        ),
        sectionHierarchyRenderArr: this.props.getFilterRenderArr(
          this.props.sectionHierarchy,
          this.props.idSectionMap
        ),
      });
    }

    async function componentDidUpdate() {
      loadPostsAndUpdateState.call(this);
    }

    async function componentDidMount() {
      if (this.props.loadedAppData) {
        loadPostsAndUpdateState.call(this);
      }
    }

    return {
      name: "LOADING",
      render: render,
      componentDidUpdate: componentDidUpdate,
      componentDidMount: componentDidMount,
    };
  })(),
  LOADED: (function notFilteredMethodsModule() {
    return {
      name: "NOT_FILTERED",
      render: defaultRender,
    };
  })(),
};

class Publications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      componentState: null,
    };

    this.setPage = this.setPage.bind(this);
    this.setTabValue = this.setTabValue.bind(this);
    window.searchRes = this;
    this.openQuickLink = this.openQuickLink.bind(this);
  }

  openQuickLink(quickLinkId) {
    let defaultState =
      this.props.pageStateInitFunctions.getSearchResInitState();

    defaultState.filterState.onMountFilter = true;
    defaultState.filterState.filtered = true;

    defaultState.filterState.categoriesFilterSet.add(quickLinkId + "");
    defaultState.filterState.params.categories = [
      ["categories", quickLinkId + ""],
    ];

    this.props.setPageStateWithoutUpdate("searchRes", defaultState);

    this.props.history.push("/search");
  }

  setTabValue(val) {
    this.props.setPageState(this.props.pageStateKey, {
      tabValue: val,
    });
  }

  static getDerivedStateFromProps(props, state) {
    if (!(props.loadedAppData && props.loadedData)) {
      return {
        componentState: componentStates.LOADING,
      };
    }
    if (props.loadedAppData && props.loadedData) {
      return {
        componentState: componentStates.LOADED,
      };
    }
    return null;
  }

  async loadPosts(getParams) {
    return loadPostsMixin.loadPosts(getParams, this.props.idCategoryMap);
  }

  async setPage(page) {
    let pageFirstPost = (page - 1) * this.props.postsPerPage;
    if (!this.props.postsToPage[pageFirstPost]) {
      this.props.setPageState(this.props.pageStateKey, {
        loadingResults: true,
      });

      let newPosts = await this.props.loadFilterData(page);

      this.props.setPageState(this.props.pageStateKey, {
        currentPage: page,
        postsToPage: newPosts.posts,
        loadingResults: false,
      });
    } else {
      this.props.setPageState(this.props.pageStateKey, {
        currentPage: page,
      });
    }
  }

  componentDidUpdate() {
    this.callComponentStateMethod("componentDidUpdate");
  }

  componentDidMount() {
    //pass through props later
    this.callComponentStateMethod("componentDidMount");
  }

  open = (link) => {
    window.open(link, "articlepost");
  };

  callComponentStateMethod(methodName, ...args) {
    if (this.state.componentState[methodName]) {
      return this.state.componentState[methodName].call(this, ...args);
    }
  }

  //new Date(yearRange[1] + 1,0,1).toISOString()

  //filtering Section

  render() {
    return this.callComponentStateMethod("render");
  }
}

class FilterSection extends Component {
  constructor(props) {
    super(props);
    if (window.innerWidth > 1024) {
      this.state = { filterView: true };
    } else {
      this.state = { filterView: false };
    }
    var scrollListener = "";
  }

  componentDidMount() {
    const parent = this;
    this.scrollListener = window.addEventListener("resize", function () {
      if (window.innerWidth > 1024) {
        parent.setState({
          filterView: true,
        });
      } else {
        parent.setState({
          filterView: false,
        });
      }
    });
  }

  componentWillUnmount() {
    const parent = this;
    window.removeEventListener("resize", function () {
      if (window.innerWidth > 1024) {
        parent.setState({
          filterView: true,
        });
      } else {
        parent.setState({
          filterView: false,
        });
      }
    });
  }

  toggleFilterView = () => {
    if (this.state.filterView === true) {
      this.setState({
        filterView: false,
      });
    } else {
      this.setState({
        filterView: true,
      });
    }
  };

  toggleExpandStateByID = (id) => {
    if (!this.state[id]) {
      this.setState({
        [id]: true,
      });
      document.getElementById(id).style.transform = "rotate(180deg)";
    } else {
      this.setState({
        [id]: false,
      });
      document.getElementById(id).style.transform = "rotate(0deg)";
    }
  };

  filterButtonClick = () => {
    this.props.applyFilter();
    if (window.innerWidth < 1025) {
      this.toggleFilterView();
    }
  };

  render() {
    var props = this.props;
    return (
      <div className={commonStyles.filterSection_container}>
        <Fade in={this.state.filterView}>
          <div className={commonStyles.filterSection}>
            <div className={commonStyles.mobilecoloredFilter}>
              <p className={commonStyles.filterSectionHeading}>
                Filter Results
              </p>
              <div className={commonStyles.filterSectionSubHeading}>
                By Year
              </div>
              <div className={commonStyles.filterSubSection}>
                <div className="sliderSection">
                  <Slider
                    min={props.yearMin}
                    max={props.yearMax}
                    step={1}
                    key={props.sliderKey}
                    defaultValue={props.currYearRange}
                    onChangeCommitted={props.toggleYearFilter}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                  />
                  <div className="sliderYears">
                    <p>1977</p>
                    <p>{new Date().getFullYear()}</p>
                  </div>
                </div>
              </div>
              <React.Fragment>
                <div className={commonStyles.filterSectionSubHeading}>
                  By Categories
                </div>
                <div className={commonStyles.filterSubSection}>
                  {props.categoriesHierarchy.map((d) => {
                    return (
                      <div key={d.id}>
                        <div className={`${commonStyles.filterOptions}`}>
                          <div className={commonStyles.filterParent_container}>
                            <span className={commonStyles.filterCheck}>
                              <input
                                type="checkbox"
                                value={d.id + ""}
                                checked={props.selectedThemes.has(d.id)}
                                onChange={(event) => {
                                  props.toggleCategoryFilter(
                                    d.id,
                                    event.target.checked
                                  );
                                }}
                              />
                            </span>
                            <div className={commonStyles.filterCategory_Main}>
                              <ReactMarkdown
                                source={d.name}
                                escapeHtml={false}
                              ></ReactMarkdown>
                            </div>
                            {d.children.length > 0 && (
                              <MdKeyboardArrowDown
                                id={`expand${d.id}`}
                                onClick={() =>
                                  this.toggleExpandStateByID(`expand${d.id}`)
                                }
                                className={commonStyles.filterExpandIcon}
                              />
                            )}
                          </div>
                          <Collapse in={this.state[`expand${d.id}`]}>
                            {d.children.length > 0 && (
                              <div
                                className={commonStyles.filter_categ_separator}
                              />
                            )}
                            {d.children.map((e) => {
                              return (
                                <div
                                  key={e.id}
                                  className={commonStyles.filterSubCategory}
                                >
                                  <span>
                                    <input
                                      type="checkbox"
                                      size="small"
                                      value={e.id + ""}
                                      checked={props.selectedThemes.has(e.id)}
                                      onChange={(event) => {
                                        props.toggleCategoryFilter(
                                          e.id,
                                          event.target.checked
                                        );
                                      }}
                                    />
                                  </span>
                                  <div
                                    className={commonStyles.filterCategory_Sub}
                                  >
                                    <ReactMarkdown
                                      source={e.name}
                                      escapeHtml={false}
                                    ></ReactMarkdown>
                                  </div>
                                </div>
                              );
                            })}
                          </Collapse>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div
                  style={{ display: "none" }}
                  className={commonStyles.filterSectionSubHeading}
                >
                  By Section
                </div>
                <div
                  style={{ display: "none" }}
                  className={commonStyles.filterSubSection}
                >
                  {props.sectionHierarchy.map((d) => {
                    return (
                      <div key={d.id}>
                        <div className={`${commonStyles.filterOptions}`}>
                          <div className={commonStyles.filterParent_container}>
                            <span className={commonStyles.filterCheck}>
                              <input
                                type="checkbox"
                                value={d.id + ""}
                                checked={props.selectedSections.has(d.id)}
                                onChange={(event) => {
                                  props.toggleSectionFilter(
                                    d.id,
                                    event.target.checked
                                  );
                                }}
                              />
                            </span>
                            <div className={commonStyles.filterCategory_Main}>
                              <ReactMarkdown
                                source={d.name}
                                escapeHtml={false}
                              ></ReactMarkdown>
                            </div>
                            {d.children.length > 0 && (
                              <MdKeyboardArrowDown
                                id={`expand${d.id}`}
                                onClick={() =>
                                  this.toggleExpandStateByID(`expand${d.id}`)
                                }
                                className={commonStyles.filterExpandIcon}
                              />
                            )}
                          </div>
                          <Collapse in={this.state[`expand${d.id}`]}>
                            {d.children.length > 0 && (
                              <div
                                className={commonStyles.filter_categ_separator}
                              />
                            )}
                            {d.children.map((e) => {
                              return (
                                <div
                                  key={e.id}
                                  className={commonStyles.filterSubCategory}
                                >
                                  <span>
                                    <input
                                      type="checkbox"
                                      size="small"
                                      value={e.id + ""}
                                      checked={props.selectedSections.has(e.id)}
                                      onChange={(event) => {
                                        props.toggleSectionFilter(
                                          e.id,
                                          event.target.checked
                                        );
                                      }}
                                    />
                                  </span>
                                  <div
                                    className={commonStyles.filterCategory_Sub}
                                  >
                                    <ReactMarkdown
                                      source={e.name}
                                      escapeHtml={false}
                                    ></ReactMarkdown>
                                  </div>
                                </div>
                              );
                            })}
                          </Collapse>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </React.Fragment>
              <div className={commonStyles.filterSubSection}>
                <Button
                  disabled={props.disabled}
                  className="blue_button_filled"
                  onClick={() => {
                    this.filterButtonClick();
                  }}
                  variant="contained"
                  color="primary"
                >
                  Filter
                </Button>
                <span style={{ marginLeft: "10px" }}>
                  <Button
                    disabled={props.disabled}
                    className="white_button_filled"
                    onClick={props.clearFilter}
                    variant="contained"
                    color="default"
                  >
                    Clear Filter
                  </Button>
                </span>
              </div>

              <div className={commonStyles.filter_searchLinkContainer}>
                <p>
                  This page shows content from the categories listed above. To
                  browse and search all content by Gallup Pakistan,{" "}
                  <Link style={{ textDecoration: "none" }} to="/search">
                    <span className={commonStyles.filter_searchLink}>
                      click here
                    </span>
                  </Link>
                  .
                </p>
              </div>
            </div>
          </div>
        </Fade>
        <Fade in={!this.state.filterView}>
          <Fab
            onClick={() => this.toggleFilterView()}
            variant="extended"
            className={commonStyles.stickyButton}
          >
            <MdEdit className={commonStyles.editFilter_Icon} />
            Filter
          </Fab>
        </Fade>
        <Fade in={this.state.filterView}>
          <Fab
            color="secondary"
            onClick={() => this.toggleFilterView()}
            variant="extended"
            className={commonStyles.stickyButton_close}
          >
            <MdClose className={commonStyles.editFilter_Icon} />
            Close
          </Fab>
        </Fade>
      </div>
    );
  }
}

export default withRouter(withFilter(Publications));
