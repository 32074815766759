import React, { Component } from 'react';
import styles from '../css-modules/home_media.module.css';
import placeholder from '../resources/placeholder.jpg';
import ReactMarkdown from 'react-markdown';
import {Link} from 'react-router-dom';
import axios from "axios";
import LazyLoad from "react-lazy-load";

class HomeMedia extends Component {
  constructor(props) {
    super(props);
    this.state = {currIndex: 2}

    const mediaResearchPromise = axios.get(
      "https://gallup.com.pk/wp/wp-json/wp/v2/posts?categories=865&per_page=3"
    );

    Promise.all([mediaResearchPromise]).then((res) => {
      this.setState({ mediaResearch: res[0].data});
    });
  }
  
  render () {
    const { mediaResearch } = this.state;
    return (
      <div className={styles.home_media_container}>
        {mediaResearch && mediaResearch.length > 0 && mediaResearch.map((mediaResearch, index) => (
          <div key={index} className={styles.home_media}>
            <Link to={`/post/${mediaResearch.id}`} style={{textDecoration: 'none', color: 'inherit'}}>
              <div className={styles.separator}></div>
              <div className={styles.home_media_text_container}>
                <div className={styles.media_heading}>
                  <ReactMarkdown
                    source={mediaResearch.title.rendered}
                    escapeHtml={false}
                  />
                </div>
              </div>
              <div className={styles.home_media_banner_image_container}>
                {mediaResearch.jetpack_featured_media_url.length > 0 ?
                  <LazyLoad><img src={mediaResearch.jetpack_featured_media_url} className={styles.home_media_banner_image} alt={mediaResearch.title.rendered}/></LazyLoad>
                  :
                  <LazyLoad><img src={placeholder} className={styles.home_media_banner_image} alt={mediaResearch.title.rendered}/></LazyLoad>
                }
                {mediaResearch.jetpack_featured_media_url.length > 0 ?
                  <div className={styles.home_media_banner_image_bg} style={{backgroundImage: `url(${mediaResearch.jetpack_featured_media_url})`}}></div>
                  :
                  <div className={styles.home_media_banner_image_bg} style={{backgroundImage: `url(${placeholder})`}}></div>
                }
              </div>
            </Link>
          </div>
        ))}
      </div>
    )
  }
}

export default HomeMedia;
