import React from 'react';
import styles from '../css-modules/relatedPosts.module.css';
import Pagination from '@material-ui/lab/Pagination';
import placeholder from '../resources/placeholder.jpg';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import Image from 'react-graceful-image';

function parseHeading(props){
  const parser = new DOMParser();
  const parsedHeading = parser.parseFromString(props.title, 'text/html').body.textContent;
  return parsedHeading;
};

function RelatedPostBlock(props){
    return (
      <div className={`${styles.blockContainer}`}>
        <Link style={{textDecoration: 'none', color: 'inherit'}} to={{pathname : `/post/${props.id}`, postDetails : props.details }}>
          <h1 className={styles.blockTitle}>
            {/* {props.title} */}
            {parseHeading(props)}
          </h1>
        </Link>
      </div>
    )
  }
  
function SkeletonRelatedPostBlock(props) {
  return (
    <div className={`${styles.blockContainer}`}>
        <div className={`${styles.skeleton} ${styles.skeletonHeading}`}>
            <Skeleton width="100%" />
        </div>
    </div>
  )
}

function RelatedPostsSection(props) {
    return (
      <div className={styles.sectionContainer}>
        <p className={styles.mainTitle}>Related Articles</p>
        <div className={styles.blockSection}>
          {
            props.relatedPosts === null ?

            new Array(4).fill(null).map((d,i) => {
              return (
                  <SkeletonRelatedPostBlock key={i}/>
              )
            })

            : 
            props.relatedPosts.map((d, i) => {
              return (
                  <RelatedPostBlock key={d.id} {...d}/>
              )
            })
          }
        </div>
      </div>
    )
  }

  export default RelatedPostsSection;