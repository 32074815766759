import React, { Component } from 'react';
import logo from '../resources/logo.jpg';
import styles from '../css-modules/navbar.module.css'
import { MdMenu } from "react-icons/md";
import { MdCancel } from "react-icons/md";
import {Link, NavLink } from 'react-router-dom';
import Fade from '@material-ui/core/Fade';

class NavBar extends Component {

  constructor(props) {
    super(props);
    this.state = { scrollClass: styles.standardNav, mobileNavOpen: false, activeLink: '' // Track the active link
   }
    this.navRef = React.createRef();
  }

  componentDidMount(){
    let scrollElement = this.navRef.current.parentElement.parentElement;

    scrollElement.addEventListener('scroll', () => {
       let scrollClass = styles.standardNav;
       if(document.getElementById("scroll_view").scrollTop === 0){
        scrollClass = styles.standardNav
        this.setState({ scrollClass });
       }
       else {
        scrollClass = styles.shadowedNav
        if(this.state.scrollClass !== scrollClass){
          this.setState({ scrollClass });
        }
       }

    });
  }

  toggleNav = () => {
    if (this.state.mobileNavOpen) {
      this.setState({
        mobileNavOpen: false
      })
    }
    else {
      this.setState({
        mobileNavOpen: true
      })
    }
  }

  handleLinkClick = (link) => {
    this.setState({ activeLink: link });
    if (this.state.mobileNavOpen) {
      this.toggleNav();
    }
  };

  render (){
    return (
      <div ref={this.navRef} className={`${styles.navigation_bar} ${this.state.scrollClass}`}>
        <div className={styles.colored_bar}>
          <div className={styles.yellow_border}></div>
          <div className={styles.grey_border}></div>
        </div>
        <div className={styles.navbar_content}>
          <div className={styles.logo_container}>
            <Link  to="/" style={{textDecoration: 'none'}}>
              <img src={logo} className={styles.navbarLogo}/>
            </Link>
          </div>
          <div className={styles.navbar_options}>
            {this.props.links && this.props.links.length > 0 && this.props.links.map((link, index) => (
              // <Link key={link.to} to={'/' + link.to} style={{textDecoration: 'none'}}
              // className={link.to === this.state.activeLink ? styles.activeLink : ''}
              // onClick={() => this.setState({ activeLink: link.to })}>
              //   <div className={styles.navbar_link}>
              //     <p>{link.linkText}</p>
              //   </div>
              // </Link>
              <NavLink
                  key={link.to}
                  to={'/' + link.to}
                  exact
                  style={{ textDecoration: 'none' }}
                  className={styles.navbar_link}
                  activeClassName={styles.activeLink}
                  // onClick={() => this.handleLinkClick(link.to)}
                  onClick={(event) => {
                    // Condition to check if the link should not trigger handleLinkClick or routing
                    if (link.linkText === "Daily Polls") { // Replace with your specific condition
                      event.preventDefault(); // Prevent the default routing behavior
                      if (link.onClick) {
                        link.onClick(); // Call the onClick function if provided
                      }
                    } else {
                      this.handleLinkClick(link.to);
                    }
                  }}
                >
                  <p>{link.linkText}</p>
                </NavLink>
            ))}
          </div>
          <div className={styles.menu_icon}>
            <MdMenu onClick={() => this.toggleNav()}/>
          </div>
          <Fade in={this.state.mobileNavOpen}>
            <div className={styles.mobileNav_parent} style={{height: window.innerHeight}}>
              <div className={styles.mobileNav_container}>
                {this.props.links && this.props.links.length > 0 && this.props.links.map((link, index) => (
                  <Link key={link.to} to={'/' + link.to} style={{textDecoration: 'none'}}>
                    <div className={styles.navbar_link} id={styles['mobile' + index]}>
                      <p>{link.linkText}</p>
                    </div>
                  </Link>
                ))}
              </div>
              <div className={`${styles.menu_icon} ${styles.menu_close}`}>
                <MdCancel onClick={() => this.toggleNav()}/>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    )
  }
}

export default NavBar;
