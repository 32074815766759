import React, { Component } from 'react';
import styles from '../css-modules/section_title.module.css';

let animationStates = {
    animating : 0,
    normal : 1
}

let id = 0;
function withAnimateOnPropChange(WrappedComponent){
	return class PropChangeAnimation extends Component{
        constructor(props){
            super(props);
            this.state = {
                animationState : animationStates.normal,
                exitElementProps : props,
                displayedElementId : Math.random(),
                exitElementId : null
            }

            this.exitRef = React.createRef();
            this.displayRef = React.createRef();
            window.PropChangeAnimation = this;
            this.id = (id)++;
        }

        static getDerivedStateFromProps(props,state){
            if(state.animationState !== animationStates.animating &&
                state.exitElementProps.route !== props.route){
                console.log(state.animationState, 'should render 2 vh');
                return {
                    animationState : animationStates.animating,
                    exitElementId : state.displayedElementId,
                    displayedElementId : Math.random()
                }
            }

            return {
                animationState : animationStates.normal,
                exitElementProps : props
            }
        }

        async componentDidUpdate(){
			if(this.state.animationState === animationStates.animating){
                await this.props.getAnimation(this.props, this.displayRef.current, this.exitRef.current);

                console.log('about to change state and remove previous vh');
                
                this.setState({
                    animationState : animationStates.normal,
                    exitElementProps : this.props
                })
			}
		}

        render(){
            console.log('rendering Prop change animation', this.id);
            return (
            <React.Fragment>
                <WrappedComponent key={this.state.displayedElementId} {...this.props} animationRef={this.displayRef} animating={this.state.animationState === animationStates.animating}/>
                {
                this.state.animationState === animationStates.animating
                &&
                <WrappedComponent key={this.state.exitElementId} {...this.state.exitElementProps} animationRef={this.exitRef} animating={this.state.animationState === animationStates.animating}/>
                }
			</React.Fragment>
            );
        }

    }
}




export default withAnimateOnPropChange;
