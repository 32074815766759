import React, { Component } from "react";
import BounceLoader from "react-spinners/BounceLoader";
import Fade from "react-reveal/Fade";
import NavBar from "../components/navbar";
import SectionTitle from "../components/sectiontitle";
import Footer from "../components/footer";
import what_we_do_styles from "../css-modules/whatWeDo.module.css";
import styles from "../css-modules/about_view.module.css";
import classNames from "classnames";
import WIN_Internationl from "../resources/win_conference.png";
import { FaLinkedin } from "react-icons/fa";
import researchBg from "../resources/research_bg.jpg";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import commonStyles from "../css-modules/common.module.css";
import IjazGIl from "../resources/Dr. Ijaz Gilani.jpg";
import BilalGil from "../resources/Bilal Gilani.jpg";
import Babar from "../resources/Babar Aziz.jpg";
import Jamshed from "../resources/Jamshed.png";
import Umar from "../resources/Umar Taj.jpeg";
import Sara from "../resources/Dr. Sara Furqan.jpg";
import Mohsin from "../resources/Mohsin Muslim.jpg";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CachedPrevRender from "../components/CachedPrevRenderDisplay";
import PakistanMap from "../components/PakMap";
import { withRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import {
  FaMoneyBill,
  FaMobileAlt,
  FaHospital,
  FaBroadcastTower,
  FaBrain,
  FaGlobe,
  FaHistory,
  FaSearch,
  FaChartBar,
} from "react-icons/fa";

class About extends Component {
  state = {
    loading: false,
    value: "0",
  };

  handleChange = (e, newValue) => {
    var value = newValue.toString();
    this.setState({
      value: value,
    });
  };

  JSX = {
    0: {
      jsx: (
        <div className={styles.section_content} value="0">
          <div className="content_container">
            <p className={what_we_do_styles.program_details}>
              Gallup Pakistan, the Pakistani affiliate of Gallup International,
              is a leading survey research and consultancy firm in Pakistan.
              Gallup Pakistan is a specialist in independent third-party
              evaluation and ratings. The company is widely attributed to be the
              founder of science of polling in Pakistan and brings over 40 years
              of experience in conducting and analyzing opinion polling in
              Pakistan.
            </p>
            <p className={what_we_do_styles.program_details}>
              As the oldest national research and public opinion organization,
              Gallup Pakistan has worked extensively with public and private
              sector partners within and outside Pakistan. Each year, Gallup
              Pakistan conducts several high-profile studies for the Government
              of Pakistan and international agencies. It has worked for a number
              of UN agencies in Pakistan and has occasionally provided research
              support for projects conducted by leading academic institutions
              such as Harvard, MIT, Oxford, Yale, Warwick, Brown University,
              University of Washington and others.
            </p>
            <p className={what_we_do_styles.program_details}>
              Gallup Pakistan’s international repute for accuracy, integrity and
              quality has been creditably maintained and recognized in the
              country since its establishment.
            </p>

            <div className={styles.about_section_container}>
              <p
                className={classNames(
                  "section_title",
                  styles.paddedSectionTitle
                )}
              >
                Our History
              </p>
              <p className={what_we_do_styles.program_details}>
                Created in London in 1947, Gallup International is networked to
                collaborate in over 60 countries of the world spread over 5
                continents including America, Europe, Asia, Africa & Australia.
                Gallup Pakistan is operating in Pakistan since 1980 thus
                bringing in more than 40 years of professional experience.
              </p>
              <p className={what_we_do_styles.program_details}>
                Gallup Pakistan was established in 1980 in Pakistan by Dr Ijaz
                Shafi Gilani, Ph.D. from MIT in Political Science. With humble
                beginnings, within few years of its inception in Pakistan,
                Gallup Pakistan had broken the previously held taboo that only
                Government agencies could do survey work in Pakistan. Using
                innovative technologies of the time like Telex and Postal
                system, Gallup Pakistan set up field networks of capable
                individuals around the country so that field happened with
                knowledge of local laws and customs.
              </p>
              <p className={what_we_do_styles.program_details}>
                During the 1985 elections, Gallup Pakistan conducted its first
                Exit Poll in the country and got widespread publicity on
                National and International Media. Public Opinion Polling was
                still a novelty for Pakistan as well as for the wider developing
                countries.
              </p>
              <p className={what_we_do_styles.program_details}>
                Since that time Gallup Pakistan, has been a nursery for training
                various types of Human Resource affiliated with survey industry.
                We are proud of our Alumni who have gone on to strengthen the
                survey industry in Pakistan including in Market Research,
                Political Research and wider Socio-Economic Research field.
              </p>
              <p className={what_we_do_styles.program_details}>
                Gallup Pakistan is proud of its history and is eager to pave the
                way for a thriving empirical and evidence-based policy and
                strategy for all sorts of corporate and government institutions.
              </p>
            </div>

            <div
              className={`${styles.about_section_container} ${styles.map_section_container}`}
            >
              <PakistanMap />
            </div>
          </div>

          <div className={styles.researchSection}>
            <img src={researchBg} className={styles.researchSection_bg} />
            <div className={styles.researchSection_content}>
              <Fade top delay={25}>
                <p
                  className={classNames(
                    "section_title",
                    styles.paddedSectionTitle,
                    styles.yellow_title
                  )}
                >
                  Our Research
                </p>
              </Fade>
              {/* <div className={styles.researchAreas_container}>
                            <Fade top delay={100}>
                              <p className={styles.researchArea}>• Financial Inclusion</p>
                            </Fade>
                            <Fade top delay={175}>
                              <p className={styles.researchArea}>• ICT & Telecom</p>
                            </Fade>
                            <Fade top delay={250}>
                              <p className={styles.researchArea}>• Healthcare</p>
                            </Fade>
                            <Fade top delay={325}>
                              <p className={styles.researchArea}>• Media</p>
                            </Fade>
                            <Fade top delay={400}>
                              <p className={styles.researchArea}>• Behavioral Science</p>
                            </Fade>
                            <Fade top delay={475}>
                              <p className={styles.researchArea}>• Social and Political</p>
                            </Fade>
                            <Fade top delay={550}>
                              <p className={styles.researchArea}>• History</p>
                            </Fade>
                            <Fade top delay={625}>
                              <p className={styles.researchArea}>• Market Exploration</p>
                            </Fade>
                            <Fade top delay={700}>
                              <p className={styles.researchArea}>• Market Research</p>
                            </Fade>
                          </div> */}
              <div className={styles.researchAreas_container}>
                <Fade top delay={100}>
                  <p className={styles.researchArea}>
                    <FaMoneyBill /> Financial Inclusion
                  </p>
                </Fade>
                <Fade top delay={175}>
                  <p className={styles.researchArea}>
                    <FaMobileAlt /> ICT & Telecom
                  </p>
                </Fade>
                <Fade top delay={250}>
                  <p className={styles.researchArea}>
                    <FaHospital /> Healthcare
                  </p>
                </Fade>
                <Fade top delay={325}>
                  <p className={styles.researchArea}>
                    <FaBroadcastTower /> Media
                  </p>
                </Fade>
                <Fade top delay={400}>
                  <p className={styles.researchArea}>
                    <FaBrain /> Behavioral Science
                  </p>
                </Fade>
                <Fade top delay={475}>
                  <p className={styles.researchArea}>
                    <FaGlobe /> Social and Political
                  </p>
                </Fade>
                <Fade top delay={550}>
                  <p className={styles.researchArea}>
                    <FaHistory /> History
                  </p>
                </Fade>
                <Fade top delay={625}>
                  <p className={styles.researchArea}>
                    <FaSearch /> Market Exploration
                  </p>
                </Fade>
                <Fade top delay={700}>
                  <p className={styles.researchArea}>
                    <FaChartBar /> Market Research
                  </p>
                </Fade>
              </div>
              <Fade bottom delay={300}>
                <Link to="gallup" style={{ textDecoration: "none" }}>
                  <Button className="yellow_button" variant="outlined">
                    Explore Details
                  </Button>
                </Link>
              </Fade>
            </div>
          </div>

          <div className="content_container">
            <div
              className={styles.about_section_container}
              style={{ marginTop: "0px" }}
            >
              <p
                className={classNames(
                  "section_title",
                  styles.paddedSectionTitle
                )}
              >
                Affiliations & Memberships
              </p>
              <div
                className={classNames(
                  "flexed_container",
                  styles.centered_flex_container
                )}
              >
                <div
                  className={classNames("container_100", styles.container_75)}
                >
                  <p className={styles.sub_heading}>WIN International</p>
                  <p className={what_we_do_styles.program_details}>
                    Gallup Pakistan is the sole representative of WIN
                    International in Pakistan. Our global affiliation has
                    enabled us to draw on a distinguished network of research
                    agencies spread across the world in more than 45 countries
                    of Americas, Europe, Asia, Africa and Australasia. Gallup
                    Pakistan’s reputation for accuracy, integrity and quality
                    has been creditably maintained and recognized in the country
                    since its establishment. As an affiliate of WIN
                    International, Global World Standard Quality Procedures are
                    meticulously followed by Gallup Pakistan.
                  </p>
                </div>
                {/*   <div className={classNames("container_25", styles.container_25)}>
                            <img src={WIN_Internationl} className={styles.win_conference}/>
                            <p className={styles.photo_sub_title}>WIN International Annual Conference, 2019</p>
                          </div> */}
              </div>
              <p className={styles.sub_heading}>ESOMAR Research</p>
              <p className={what_we_do_styles.program_details}>
                Gallup Pakistan is a member of ESOMAR, the world association of
                Research Professionals. As a member, Gallup Pakistan adheres to
                the ESOMAR’s Code of Marketing and Social research. Chairman of
                Gallup Pakistan, Dr. Ijaz Shafi Gilani has been Country
                Representative in Pakistan.
              </p>
            </div>

            <div className={styles.about_section_container}>
              <p
                className={classNames(
                  "section_title",
                  styles.paddedSectionTitle
                )}
              >
                Local Memberships
              </p>
              <p className={styles.sub_heading}>
                Marketing Research Society of Pakistan
              </p>
              <p className={what_we_do_styles.program_details}>
                The Marketing Research Society of Pakistan provides a platform
                for the marketers and research agencies of Pakistan to engage in
                mutual interaction. Gallup Pakistan is a founding and active
                member of the Association. Dr. Ijaz Shafi Gilani, CEO Gallup
                Pakistan, is a former president of the Society. Gallup Pakistan
                has played an active role in the events organized by the
                Society.
              </p>
              <p className={styles.sub_heading}>
                Marketing Association of Pakistan
              </p>
              <p className={what_we_do_styles.program_details}>
                Marketing Association of Pakistan aims to improve and develop
                the technical and general knowledge of persons engaged in the
                profession of marketing. MAP brings together persons interested
                and connected with marketing activities by arranging
                professional meetings each month. Gallup Pakistan has been an
                active member of the Association and actively participates in
                the events organized by the Association. Mohsin Muslim Head of
                Customized Research at Gallup Pakistan is currently Vice
                President of the Association.
              </p>
            </div>
          </div>
        </div>
      ),
    },
    1: {
      jsx: (
        <div className={styles.section_content} value="1">
          <div className="content_container">
            <div
              className={styles.about_section_container}
              style={{ marginTop: "0px" }}
            >
              <div className={styles.team_members_wrapper}>
                <div className={styles.team_member_container}>
                  <img className={styles.team_member_avatar} src={IjazGIl} />
                  <p className={styles.team_member_name}>
                    Dr. Ijaz Shafi Gilani
                  </p>
                  <p className={styles.team_member_desig}>
                    Founder & Chairperson
                  </p>
                </div>

                <div className={styles.team_member_container}>
                  <img className={styles.team_member_avatar} src={BilalGil} />
                  <p className={styles.team_member_name}>Bilal I Gilani</p>
                  <p className={styles.team_member_desig}>Executive Director</p>
                  <div className={styles.team_member_connect_container}>
                    <p className={styles.team_member_connect}>Connect</p>
                    <FaLinkedin
                      onClick={() =>
                        window.open(
                          "https://www.linkedin.com/in/bilal-i-gilani-2827102a/"
                        )
                      }
                      className={styles.team_member_linkedIn}
                    />
                  </div>
                </div>

                <div className={styles.team_member_container}>
                  <img className={styles.team_member_avatar} src={Umar} />
                  <p className={styles.team_member_name}>Dr. Umar Taj</p>
                  <p className={styles.team_member_desig}>
                    Behavioral Research Lead
                  </p>
                  <div className={styles.team_member_connect_container}>
                    <p className={styles.team_member_connect}>Connect</p>
                    <FaLinkedin
                      onClick={() =>
                        window.open("https://www.linkedin.com/in/dr-umar-taj/")
                      }
                      className={styles.team_member_linkedIn}
                    />
                  </div>
                </div>

                <div className={styles.team_member_container}>
                  <img className={styles.team_member_avatar} src={Mohsin} />
                  <p className={styles.team_member_name}>Mohsin Muslim</p>
                  <p className={styles.team_member_desig}>
                    Senior Research Consultant
                  </p>
                  <div className={styles.team_member_connect_container}>
                    <p className={styles.team_member_connect}>Connect</p>
                    <FaLinkedin
                      onClick={() =>
                        window.open("https://www.linkedin.com/in/mohsinmuslim/")
                      }
                      className={styles.team_member_linkedIn}
                    />
                  </div>
                </div>

                <div className={styles.team_member_container}>
                  <img className={styles.team_member_avatar} src={Sara} />
                  <p className={styles.team_member_name}>Dr. Sara Furqan</p>
                  <p className={styles.team_member_desig}>
                    Health Research Lead
                  </p>
                </div>

                <div className={styles.team_member_container}>
                  <img className={styles.team_member_avatar} src={Babar} />
                  <p className={styles.team_member_name}>Babar Aziz</p>
                  <p className={styles.team_member_desig}>
                    Media Research Lead
                  </p>
                </div>

                <div className={styles.team_member_container}>
                  <img className={styles.team_member_avatar} src={Jamshed} />
                  <p className={styles.team_member_name}>Jamshed Khan</p>
                  <p className={styles.team_member_desig}>
                    Director Administration
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    2: {
      jsx: (
        <div value="2" className={styles.gray_section}>
          <div className="content_container">
            <p
              className={classNames("section_title", styles.paddedSectionTitle)}
            >
              Code of Ethics
            </p>
            <p className={what_we_do_styles.program_details}>
              Gallup Pakistan complies with the{" "}
              <a
                href="https://www.esomar.org/"
                style={{
                  textDecoration: "none",
                  color: "#616161",
                  fontWeight: "600",
                }}
              >
                ICC/ESOMAR Code of Ethics
              </a>{" "}
              which is a global body of Marketing and Opinion Research
              Organizations. Gallup Pakistan is a member of the organization for
              over two decades now. Our senior leadership has worked as country
              representative for many years. The Code of Ethics is as follows:
            </p>
            <p className={styles.gray_secHeading}>
              Responsibilities to data subjects
            </p>
            {/* ////////////// */}
            {/* <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    Accordion Item #1
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    Placeholder content for this accordion, which is intended to
                    demonstrate the <code>.accordion-flush</code> class. This is
                    the first item's accordion body.
                  </div>
                </div>
              </div>
            </div> */}
            {/* ////////////// */}
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    Article 1: Duty of care
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <ul className={styles.ordered_list}>
                      <li>
                        Researchers must ensure that data subjects are not
                        harmed as a direct result of their personal data being
                        used for research.
                      </li>
                      <li>
                        Researchers must exercise special care when the nature
                        of the research is sensitive or the circumstances under
                        which the data was collected might cause a data subject
                        to become upset or disturbed.
                      </li>
                      <li>
                        Researchers must remain mindful that research relies on
                        public confidence in the integrity of research and the
                        confidential treatment of the information provided for
                        its success, and therefore must remain diligent in
                        maintaining the distinction between research and
                        non-research activities.
                      </li>
                      <li>
                        If researchers engage in non-research activities, for
                        example promotional or commercial activities directed at
                        individual data subjects, they must clearly distinguish
                        and separate those activities from research.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* <p className={styles.article_heading}>Article 1: Duty of care</p>
            <ul className={styles.ordered_list}>
              <li>
                Researchers must ensure that data subjects are not harmed as a
                direct result of their personal data being used for research.
              </li>
              <li>
                Researchers must exercise special care when the nature of the
                research is sensitive or the circumstances under which the data
                was collected might cause a data subject to become upset or
                disturbed.
              </li>
              <li>
                Researchers must remain mindful that research relies on public
                confidence in the integrity of research and the confidential
                treatment of the information provided for its success, and
                therefore must remain diligent in maintaining the distinction
                between research and non-research activities.
              </li>
              <li>
                If researchers engage in non-research activities, for example
                promotional or commercial activities directed at individual data
                subjects, they must clearly distinguish and separate those
                activities from research.
              </li>
            </ul> */}
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse2"
                    aria-expanded="false"
                    aria-controls="flush-collapse2"
                  >
                    Article 2: Children, young people and other vulnerable
                    individuals
                  </button>
                </h2>
                <div
                  id="flush-collapse2"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingTwo"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <ul className={styles.ordered_list}>
                      <li>
                        Researchers must obtain the consent of the parent or
                        responsible adult when collecting personal data from
                        children or anyone for whom a legal guardian has been
                        appointed.
                      </li>
                      <li>
                        Researchers must take special care when considering
                        whether to involve children and young people in
                        research. The questions asked must take into account
                        their age and level of maturity.
                      </li>
                      <li>
                        When working with other vulnerable individuals,
                        researchers must ensure that such individuals are
                        capable of making informed decisions and are not unduly
                        pressured to cooperate with a research request.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* <p className={styles.article_heading}>
              Article 2: Children, young people and other vulnerable individuals
            </p>
            <ul className={styles.ordered_list}>
              <li>
                Researchers must obtain the consent of the parent or responsible
                adult when collecting personal data from children or anyone for
                whom a legal guardian has been appointed.
              </li>
              <li>
                Researchers must take special care when considering whether to
                involve children and young people in research. The questions
                asked must take into account their age and level of maturity.
              </li>
              <li>
                When working with other vulnerable individuals, researchers must
                ensure that such individuals are capable of making informed
                decisions and are not unduly pressured to cooperate with a
                research request.
              </li>
            </ul> */}
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-heading3">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse3"
                    aria-expanded="false"
                    aria-controls="flush-collapse3"
                  >
                    Article 3: Data minimisation
                  </button>
                </h2>
                <div
                  id="flush-collapse3"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-heading3"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <ul className={styles.ordered_list}>
                      <li>
                        Researchers must limit the collection and/or processing
                        of personal data to those items that are relevant to the
                        research.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* <p className={styles.article_heading}>
              Article 3: Data minimisation
            </p>
            <ul className={styles.ordered_list}>
              <li>
                Researchers must limit the collection and/or processing of
                personal data to those items that are relevant to the research.
              </li>
            </ul> */}
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-heading4">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse4"
                    aria-expanded="false"
                    aria-controls="flush-collapse4"
                  >
                    Article 4: Primary data collection
                  </button>
                </h2>
                <div
                  id="flush-collapse4"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-heading4"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <ul className={styles.ordered_list}>
                      <li>
                        When collecting personal data directly from a data
                        subject for the purpose of research:
                        <ul>
                          <li>
                            Researchers must identify themselves promptly and
                            data subjects must be able to verify the identity
                            and bona fides of the researcher without difficulty.
                          </li>
                          <li>
                            Researchers must clearly state the general purpose
                            of the research as soon as methodologically
                            possible.
                          </li>
                          <li>
                            Researchers must ensure that participation is
                            voluntary and based on information about the general
                            purpose and nature of the research that is adequate
                            and not misleading.
                          </li>
                          <li>
                            Researchers must inform data subjects if there is
                            any activity that will involve re-contact and data
                            subjects must agree to be re-contacted. The only
                            exception to this is re-contact for quality control
                            purposes.
                          </li>
                          <li>
                            Researchers must respect the right of data subjects
                            to refuse requests to participate in research.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Researchers must allow data subjects to withdraw from
                        the research at any time and access or rectify personal
                        data held about them.
                      </li>
                      <li>
                        Passive data collection should be based on the consent
                        of the data subject and meet all conditions in Article
                        4(a).
                      </li>
                      <li>
                        When using passive data collection methods where it is
                        not possible to obtain consent, researchers must have
                        legally permissible grounds to collect the data and they
                        must remove or obscure any identifying characteristics
                        as soon as operationally possible.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* <p className={styles.article_heading}>
              Article 4: Primary data collection
            </p>
            <ul className={styles.ordered_list}>
              <li>
                When collecting personal data directly from a data subject for
                the purpose of research:
                <ul>
                  <li>
                    Researchers must identify themselves promptly and data
                    subjects must be able to verify the identity and bona fides
                    of the researcher without difficulty.
                  </li>
                  <li>
                    Researchers must clearly state the general purpose of the
                    research as soon as methodologically possible.
                  </li>
                  <li>
                    Researchers must ensure that participation is voluntary and
                    based on information about the general purpose and nature of
                    the research that is adequate and not misleading.
                  </li>
                  <li>
                    Researchers must inform data subjects if there is any
                    activity that will involve re-contact and data subjects must
                    agree to be re-contacted. The only exception to this is
                    re-contact for quality control purposes.
                  </li>
                  <li>
                    Researchers must respect the right of data subjects to
                    refuse requests to participate in research.
                  </li>
                </ul>
              </li>
              <li>
                Researchers must allow data subjects to withdraw from the
                research at any time and access or rectify personal data held
                about them.
              </li>
              <li>
                Passive data collection should be based on the consent of the
                data subject and meet all conditions in Article 4(a).
              </li>
              <li>
                When using passive data collection methods where it is not
                possible to obtain consent, researchers must have legally
                permissible grounds to collect the data and they must remove or
                obscure any identifying characteristics as soon as operationally
                possible.
              </li>
            </ul> */}
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-heading5">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse5"
                    aria-expanded="false"
                    aria-controls="flush-collapse5"
                  >
                    Article 5: Use of secondary data
                  </button>
                </h2>
                <div
                  id="flush-collapse5"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-heading5"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <ul className={styles.ordered_list}>
                      <li>
                        When using secondary data that includes personal data
                        researchers must ensure that:
                        <ul>
                          <li>
                            The intended use is compatible with the purpose for
                            which the data was originally collected.
                          </li>
                          <li>
                            The data was not collected in violation of
                            restrictions imposed by law, through deception, or
                            in ways that were not apparent to or reasonably
                            discernible and anticipated by the data subject.
                          </li>
                          <li>
                            The intended use was not specifically excluded in
                            the privacy notice provided at the time of original
                            collection.
                          </li>
                          <li>
                            Any requests from individual data subjects that
                            their data not be used for other purposes are
                            honoured.
                          </li>
                          <li>
                            Use of the data will not result in harm to data
                            subjects and there are measures in place to guard
                            against such harm.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* <p className={styles.article_heading}>
              Article 5: Use of secondary data
            </p>
            <ul className={styles.ordered_list}>
              <li>
                When using secondary data that includes personal data
                researchers must ensure that:
                <ul>
                  <li>
                    The intended use is compatible with the purpose for which
                    the data was originally collected.
                  </li>
                  <li>
                    The data was not collected in violation of restrictions
                    imposed by law, through deception, or in ways that were not
                    apparent to or reasonably discernible and anticipated by the
                    data subject.
                  </li>
                  <li>
                    The intended use was not specifically excluded in the
                    privacy notice provided at the time of original collection.
                  </li>
                  <li>
                    Any requests from individual data subjects that their data
                    not be used for other purposes are honoured.
                  </li>
                  <li>
                    Use of the data will not result in harm to data subjects and
                    there are measures in place to guard against such harm.
                  </li>
                </ul>
              </li>
            </ul> */}
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-heading6">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse6"
                    aria-expanded="false"
                    aria-controls="flush-collapse6"
                  >
                    Article 6: Data protection and privacy
                  </button>
                </h2>
                <div
                  id="flush-collapse6"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-heading6"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <ul className={styles.ordered_list}>
                      <li>
                        If researchers plan to collect personal data for
                        research that may also be used for a non-research
                        purpose, this must be made clear to data subjects prior
                        to data collection and their consent for the
                        non-research use obtained.
                      </li>
                      <li>
                        Researchers must not share a data subject’s personal
                        data with a client unless the data subject has given
                        consent to do so and has agreed to the specific purpose
                        for which it will be used.
                      </li>
                      <li>
                        Researchers must have a privacy notice that is readily
                        accessible by data subjects and is easily understood.
                      </li>
                      <li>
                        Researchers must ensure that personal data cannot be
                        traced nor an individual’s identity inferred via
                        deductive disclosure (for example, through
                        cross-analysis, small samples or combination with other
                        data such as a client’s records or secondary data in the
                        public domain).
                      </li>
                      <li>
                        Researchers must take all reasonable precautions to
                        ensure that personal data is held securely. It must be
                        protected against risks such as loss, unauthorized
                        access, destruction, misuse, manipulation or disclosure.
                      </li>
                      <li>
                        Personal data is to be held no longer than is necessary
                        for the purpose for which it was collected or used.
                      </li>
                      <li>
                        If personal data is to be transferred to subcontractors
                        or other service providers, researchers must ensure that
                        the recipients employ at least an equivalent level of
                        security measures.
                      </li>
                      <li>
                        Researchers must take particular care to maintain the
                        data protection rights of data subjects whose personal
                        data is transferred from one jurisdiction to another.
                        Such transfers must not be made without the consent of
                        the data subject or other legally permissible grounds.
                        In addition, researchers must take all reasonable steps
                        to ensure that adequate security measures are observed
                        and that the data protection principles of this Code are
                        complied with.
                      </li>
                      <li>
                        In the event of a data breach containing personal data
                        researchers have a duty of care for the data subjects
                        involved and must follow all applicable data breach
                        notification laws.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* <p className={styles.article_heading}>
              Article 6: Data protection and privacy
            </p>
            <ul className={styles.ordered_list}>
              <li>
                If researchers plan to collect personal data for research that
                may also be used for a non-research purpose, this must be made
                clear to data subjects prior to data collection and their
                consent for the non-research use obtained.
              </li>
              <li>
                Researchers must not share a data subject’s personal data with a
                client unless the data subject has given consent to do so and
                has agreed to the specific purpose for which it will be used.
              </li>
              <li>
                Researchers must have a privacy notice that is readily
                accessible by data subjects and is easily understood.
              </li>
              <li>
                Researchers must ensure that personal data cannot be traced nor
                an individual’s identity inferred via deductive disclosure (for
                example, through cross-analysis, small samples or combination
                with other data such as a client’s records or secondary data in
                the public domain).
              </li>
              <li>
                Researchers must take all reasonable precautions to ensure that
                personal data is held securely. It must be protected against
                risks such as loss, unauthorized access, destruction, misuse,
                manipulation or disclosure.
              </li>
              <li>
                Personal data is to be held no longer than is necessary for the
                purpose for which it was collected or used.
              </li>
              <li>
                If personal data is to be transferred to subcontractors or other
                service providers, researchers must ensure that the recipients
                employ at least an equivalent level of security measures.
              </li>
              <li>
                Researchers must take particular care to maintain the data
                protection rights of data subjects whose personal data is
                transferred from one jurisdiction to another. Such transfers
                must not be made without the consent of the data subject or
                other legally permissible grounds. In addition, researchers must
                take all reasonable steps to ensure that adequate security
                measures are observed and that the data protection principles of
                this Code are complied with.
              </li>
              <li>
                In the event of a data breach containing personal data
                researchers have a duty of care for the data subjects involved
                and must follow all applicable data breach notification laws.
              </li>
            </ul> */}

            <p className={styles.gray_secHeading}>
              Responsibilities to clients
            </p>
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-heading7">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse7"
                    aria-expanded="false"
                    aria-controls="flush-collapse7"
                  >
                    Article 7: Transparency
                  </button>
                </h2>
                <div
                  id="flush-collapse7"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-heading7"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <ul className={styles.ordered_list}>
                      <li>
                        Researchers must design research to the specification
                        and quality agreed with the client and in accordance
                        with Article 9(a).
                      </li>
                      <li>
                        Researchers must ensure that findings and any
                        interpretation of them are clearly and adequately
                        supported by data.
                      </li>
                      <li>
                        Researchers must on request allow clients to arrange for
                        independent checks on the quality of data collection and
                        data preparation.
                      </li>
                      <li>
                        Researchers must provide clients with sufficient
                        technical information about the research to enable them
                        to assess the validity of the results and any
                        conclusions drawn.
                      </li>
                      <li>
                        When reporting on the results of research, researchers
                        must make a clear distinction between the findings, the
                        researchers’ interpretation of those findings and any
                        conclusions drawn, or recommendations made.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* <p className={styles.article_heading}>Article 7: Transparency</p>
            <ul className={styles.ordered_list}>
              <li>
                Researchers must design research to the specification and
                quality agreed with the client and in accordance with Article
                9(a).
              </li>
              <li>
                Researchers must ensure that findings and any interpretation of
                them are clearly and adequately supported by data.
              </li>
              <li>
                Researchers must on request allow clients to arrange for
                independent checks on the quality of data collection and data
                preparation.
              </li>
              <li>
                Researchers must provide clients with sufficient technical
                information about the research to enable them to assess the
                validity of the results and any conclusions drawn.
              </li>
              <li>
                When reporting on the results of research, researchers must make
                a clear distinction between the findings, the researchers’
                interpretation of those findings and any conclusions drawn, or
                recommendations made.
              </li>
            </ul> */}

            <p className={styles.gray_secHeading}>
              Responsibilities to the general public
            </p>
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-heading8">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse8"
                    aria-expanded="false"
                    aria-controls="flush-collapse8"
                  >
                    Article 8: Publishing findings
                  </button>
                </h2>
                <div
                  id="flush-collapse8"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-heading8"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <ul className={styles.ordered_list}>
                      <li>
                        When publishing research findings, researchers must
                        ensure that the public has access to sufficient basic
                        information to assess the quality of the data used and
                        the validity of the conclusions.
                      </li>
                      <li>
                        Researchers must make available upon request the
                        additional technical information necessary to assess the
                        validity of any published findings, unless contractually
                        prohibited.
                      </li>
                      <li>
                        When the client plans to publish the findings of a
                        research project, researchers should ensure that they
                        are consulted as to the form and content of publication.
                        Both the client and the researcher have a responsibility
                        to ensure that published results are not misleading.
                      </li>
                      <li>
                        Researchers must not allow their name or that of their
                        organization to be associated with the dissemination of
                        conclusions from a research project unless those
                        conclusions are adequately supported by the data.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* <p className={styles.article_heading}>
              Article 8: Publishing findings
            </p>
            <ul className={styles.ordered_list}>
              <li>
                When publishing research findings, researchers must ensure that
                the public has access to sufficient basic information to assess
                the quality of the data used and the validity of the
                conclusions.
              </li>
              <li>
                Researchers must make available upon request the additional
                technical information necessary to assess the validity of any
                published findings, unless contractually prohibited.
              </li>
              <li>
                When the client plans to publish the findings of a research
                project, researchers should ensure that they are consulted as to
                the form and content of publication. Both the client and the
                researcher have a responsibility to ensure that published
                results are not misleading.
              </li>
              <li>
                Researchers must not allow their name or that of their
                organization to be associated with the dissemination of
                conclusions from a research project unless those conclusions are
                adequately supported by the data.
              </li>
            </ul> */}

            <p className={styles.gray_secHeading}>
              Responsibilities to the research profession
            </p>
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-heading9">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse9"
                    aria-expanded="false"
                    aria-controls="flush-collapse9"
                  >
                    Article 9: Professional responsibility
                  </button>
                </h2>
                <div
                  id="flush-collapse9"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-heading9"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <ul className={styles.ordered_list}>
                      <li>
                        Researchers must be honest, truthful and objective and
                        ensure that their research is carried out in accordance
                        with appropriate scientific research principles, methods
                        and techniques.
                      </li>
                      <li>
                        Researchers must always behave ethically and must not do
                        anything that might unjustifiably damage the reputation
                        of research or lead to a loss of public confidence in
                        it.
                      </li>
                      <li>
                        Researchers must be straightforward and honest in all of
                        their professional and business dealings.
                      </li>
                      <li>
                        Researchers must not unjustifiably criticize other
                        researchers.
                      </li>
                      <li>
                        Researchers must not make false or otherwise misleading
                        statements about their skills, experience or activities,
                        or about those of their organization.
                      </li>
                      <li>
                        Researchers must conform to the generally accepted
                        principles of fair competition.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* <p className={styles.article_heading}>
              Article 9: Professional responsibility
            </p>
            <ul className={styles.ordered_list}>
              <li>
                Researchers must be honest, truthful and objective and ensure
                that their research is carried out in accordance with
                appropriate scientific research principles, methods and
                techniques.
              </li>
              <li>
                Researchers must always behave ethically and must not do
                anything that might unjustifiably damage the reputation of
                research or lead to a loss of public confidence in it.
              </li>
              <li>
                Researchers must be straightforward and honest in all of their
                professional and business dealings.
              </li>
              <li>
                Researchers must not unjustifiably criticize other researchers.
              </li>
              <li>
                Researchers must not make false or otherwise misleading
                statements about their skills, experience or activities, or
                about those of their organization.
              </li>
              <li>
                Researchers must conform to the generally accepted principles of
                fair competition.
              </li>
            </ul> */}
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-heading10">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse10"
                    aria-expanded="false"
                    aria-controls="flush-collapse10"
                  >
                    Article 10: Legal responsibility
                  </button>
                </h2>
                <div
                  id="flush-collapse10"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-heading10"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <ul className={styles.ordered_list}>
                      <li>
                        Researchers must conform to all applicable international
                        and national laws, and local codes of conduct and
                        professional standards or rules.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* <p className={styles.article_heading}>
              Article 10: Legal responsibility
            </p>
            <ul className={styles.ordered_list}>
              <li>
                Researchers must conform to all applicable international and
                national laws, and local codes of conduct and professional
                standards or rules.
              </li>
            </ul> */}
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-heading11">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse11"
                    aria-expanded="false"
                    aria-controls="flush-collapse11"
                  >
                    Article 11: Compliance
                  </button>
                </h2>
                <div
                  id="flush-collapse11"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-heading11"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <ul className={styles.ordered_list}>
                      <li>
                        Researchers must ensure that research is carried out in
                        accordance with this Code, that clients and other
                        parties to the research agree to comply with its
                        requirements, and that the Code is applied, where
                        appropriate, by all organizations, companies and
                        individuals at all stages of the research.
                      </li>
                      <li>
                        Correction of a breach of this Code by a researcher,
                        while desirable, does not excuse the breach.
                      </li>
                      <li>
                        Failure by an ESOMAR member to co-operate with a
                        disciplinary investigation by ESOMAR into a possible
                        breach of this Code, will be considered a breach of this
                        Code. This also applies to members of other
                        self-regulatory bodies implementing this Code.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* <p className={styles.article_heading}>Article 11: Compliance</p>
            <ul className={styles.ordered_list}>
              <li>
                Researchers must ensure that research is carried out in
                accordance with this Code, that clients and other parties to the
                research agree to comply with its requirements, and that the
                Code is applied, where appropriate, by all organizations,
                companies and individuals at all stages of the research.
              </li>
              <li>
                Correction of a breach of this Code by a researcher, while
                desirable, does not excuse the breach.
              </li>
              <li>
                Failure by an ESOMAR member to co-operate with a disciplinary
                investigation by ESOMAR into a possible breach of this Code,
                will be considered a breach of this Code. This also applies to
                members of other self-regulatory bodies implementing this Code.
              </li>
            </ul> */}
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-heading12">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapse12"
                    aria-expanded="false"
                    aria-controls="flush-collapse12"
                  >
                    Article 12: Implementation
                  </button>
                </h2>
                <div
                  id="flush-collapse12"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-heading12"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <ul className={styles.ordered_list}>
                      <li>
                        This Code and the principles enshrined in it should be
                        adopted, and if it is adopted, must be implemented,
                        nationally and internationally by the relevant local,
                        national and international self-regulatory bodies.
                        Researchers and clients also should familiarize
                        themselves with relevant local self-regulatory documents
                        on research and with decisions taken by the appropriate
                        self-regulatory body.
                      </li>
                      <li>
                        Requests for interpretation of the principles contained
                        in this Code must be submitted to the ESOMAR
                        Professional Standards Committee or the ICC Commission
                        on Marketing and Advertising for interpretation.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* <p className={styles.article_heading}>Article 12: Implementation</p>
            <ul className={styles.ordered_list}>
              <li>
                This Code and the principles enshrined in it should be adopted,
                and if it is adopted, must be implemented, nationally and
                internationally by the relevant local, national and
                international self-regulatory bodies. Researchers and clients
                also should familiarize themselves with relevant local
                self-regulatory documents on research and with decisions taken
                by the appropriate self-regulatory body.
              </li>
              <li>
                Requests for interpretation of the principles contained in this
                Code must be submitted to the ESOMAR Professional Standards
                Committee or the ICC Commission on Marketing and Advertising for
                interpretation.
              </li>
            </ul> */}
          </div>
        </div>
      ),
    },
  };

  openQuickLink(quickLinkId) {
    let defaultState =
      this.props.pageStateInitFunctions.getSearchResInitState();

    defaultState.filterState.onMountFilter = true;
    defaultState.filterState.filtered = true;

    defaultState.filterState.categoriesFilterSet.add(quickLinkId + "");
    defaultState.filterState.params.categories = [
      ["categories", quickLinkId + ""],
    ];

    this.props.setPageStateWithoutUpdate("searchRes", defaultState);

    this.props.history.push("/search");
  }

  render() {
    var jsxFunction;
    if (this.state.value) {
      jsxFunction = this.JSX[this.state.value].jsx;
    }
    return (
      <div
        ref={this.props.animationRef}
        id="scroll_view"
        className={commonStyles.view}
        style={{ minHeight: window.innerHeight }}
      >
        {!this.props.loadedAppData ? (
          <div className="loading">
            <BounceLoader
              sizeUnit={"px"}
              size={40}
              color={"#1E4D79"}
              loading={!this.props.loadedAppData}
            />
          </div>
        ) : (
          <div>
            <NavBar
              links={[
                { linkText: "Home", to: "" },
                { linkText: "ABOUT US", to: "about" },
                { linkText: "What We Do", to: "gallup" },
                { linkText: "News & Updates", to: "news" },
                { linkText: "Publications", to: "publications" },
                {
                  linkText: "Daily Polls",
                  to: "daily-polls",
                  onClick: () => this.openQuickLink("437,438"),
                },
                { linkText: "Interactives", to: "datavis" },
                { linkText: "Gallup International", to: "gallupinternational" },
                { linkText: "Contact Us", to: "contact" },
              ]}
            />
            <SectionTitle title="About Us" />
            <div className="padded_section">
              <div className="content_container">
                <div className={styles.about_tabs_container}>
                  <Paper square style={{ width: "fit-content" }}>
                    <Tabs
                      value={parseInt(this.state.value)}
                      indicatorColor="primary"
                      textColor="primary"
                      onChange={this.handleChange}
                      scrollButtons="auto"
                      variant="scrollable"
                    >
                      <Tab label="Gallup Pakistan" />
                      <Tab label="Our Team" />
                      <Tab label="Code of Ethics" />
                    </Tabs>
                  </Paper>
                </div>
              </div>
              <div className={styles.tab_content_wrapper}>
                <CachedPrevRender
                  curr_service={this.state.value}
                  shouldUpdate={(prevProps, currProps) => {
                    let update =
                      currProps.value && prevProps.value !== currProps.value;
                    return update;
                  }}
                >
                  {jsxFunction}
                </CachedPrevRender>
              </div>
            </div>
            <Footer
              links={[
                { linkText: "Affiliations & Memberships", to: "about" },
                { linkText: "Contact Us", to: "contact" },
              ]}
              researchAreas={this.props.topSections.map((d) => {
                let data = this.props.idSectionMap[d];
                return { linkText: data.name, id: d };
              })}
              pageStateInitFunctions={this.props.pageStateInitFunctions}
              setPageStateWithoutUpdate={this.props.setPageStateWithoutUpdate}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(About);
