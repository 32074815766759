import React from 'react';

class Paginator extends React.Component{

    render(){
      const props = this.props;
      const child = React.Children.only(this.props.children)
      return React.cloneElement(child, {
        posts : props.posts.slice((props.currentPage - 1) * props.postsPerPage, (props.currentPage) * props.postsPerPage),
        setPage : props.setPage,
        totalPages : Math.ceil(props.totalPosts/props.postsPerPage),
        currentPage : this.props.currentPage,
        loadingPosts : this.props.loadingPosts
      })
    }
  }

  export default Paginator;