import React, { Component } from 'react';
import {TweenMax} from 'gsap';

class CachePrevRender extends Component {

  constructor(props){
    super(props);
    this.prevElement = null;
    this.prevProps = null;
    this.currRef = React.createRef();
    this.prevRef = React.createRef();
    window.cacheComp = this;
  }

  standardAnim = (prevRef, currRef) => {
    TweenMax.fromTo(prevRef, {autoAlpha: 1, display: 'block'}, {
      autoAlpha : 0,
      duration : 0,
      display: 'none',
      y: 0
    })

    TweenMax.fromTo(currRef,{autoAlpha : 0, display: 'none'}, {
      autoAlpha : 1,
      display: 'block',
      duration : 0.8,
    })
  }

  componentDidUpdate(prevProps){
    if (prevProps.value && this.props.value) {
      if(this.prevRef.current){
        this.standardAnim(this.prevRef.current, this.currRef.current);
      }
    }
    else {
      if(this.prevRef.current){
        this.standardAnim(this.prevRef.current, this.currRef.current);
      }
    }
  }

  componentDidMount(){

  }

  shouldComponentUpdate(nextProps, nextState) {
    return Boolean(this.props.shouldUpdate(this.prevProps, nextProps.children.props));
  }

  render() {
    this.child = React.Children.only(this.props.children)

    let jsx = (
      <React.Fragment>
        {React.cloneElement(this.child, {ref : this.currRef})}
        {
        this.prevElement
        &&
        React.cloneElement(this.prevElement, {ref : this.prevRef})
        }
      </React.Fragment>
    )

    this.prevElement = React.cloneElement(this.child);
    this.prevProps = Object.keys(this.child.props).reduce((acc,e)=>{
      acc[e] = this.child.props[e]
      return acc;
    }, {});
    window.prevProps = this.prevProps;

    return jsx;
  }
}

export default CachePrevRender;
