import React, { Component } from 'react';
import { readAndDraw } from './d3-pak-map';
import styles from '../css-modules/map.module.css';

class PakistanMap extends Component {

  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
  }

  componentDidMount() {
    let scrollElement = this.mapRef.current.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;
    let isMapDrawn = false;
    if (this.mapRef.current.getBoundingClientRect().y - this.mapRef.current.offsetHeight < -80) {
      readAndDraw();
      isMapDrawn = true;
    }
    scrollElement.addEventListener('scroll', () => {
      if(!this.mapRef.current){
        return;
      }

      if((this.mapRef.current.getBoundingClientRect().y - this.mapRef.current.offsetHeight < -80) && (!isMapDrawn)){
        readAndDraw();
        isMapDrawn = true;
      }
    });
  }

  render () {
    return (
      <div ref={this.mapRef} className={`${styles.mapContain} ${styles.flexed_map_container}`}>
        <p className="section_title" style={{textAlign: 'center'}}>Field Capacity Map</p>
        <div className={`${styles.visContain_Pak} visContain`}>
          <svg className ="PakGallup" viewBox="0 0 600 600" style={{maxWidth: '700px'}} ></svg>
        </div>
      </div>
    )
  }
}

export default PakistanMap;
