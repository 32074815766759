import axios from 'axios';

const domParser = new DOMParser()

async function loadYTStats(getParams, idCategoryMap){

	let getString = `https://www.googleapis.com/youtube/v3/channels?key=AIzaSyCuy434XZ6_Lcu747pB06NNvHRSNCa5qPw&part=statistics&forUsername=galluppk`

	console.log(getString);

	const res = await axios.get(getString);
	console.log(res);
	return res.items.statistics;
};

window.loadYTStats = loadYTStats;

async function loadYTPostsPage(token){

	let getString = `https://www.googleapis.com/youtube/v3/playlistItems?playlistId=UUwcv8nnacJuSGwl_lA6d6Ng&key=AIzaSyCuy434XZ6_Lcu747pB06NNvHRSNCa5qPw&part=snippet&maxResults=50`;

	if(token){
		getString += `&pageToken=${token}`
	}

	console.log(getString);

	const res = await axios.get(getString);
	console.log(res);
	return {items : res.data.items, nextPageToken : res.data.nextPageToken, totalResults : res.data.totalResults};
};

async function loadYTPosts(){

	let token = null;
	let items = [];

	do{
		let res = await loadYTPostsPage(token);
		token = res.nextPageToken;
		items = items.concat(res.items);
		console.log(token);
	}while(token)

	return items.map((d)=>{
		let date = new Date(d.snippet.publishedAt);
		return {
			image : d.snippet.thumbnails.medium.url,
			title : d.snippet.title,
			date : `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`,
			videoId : d.snippet.resourceId.videoId
		}
	});
}

async function loadRelatedPosts(id){

	let getString = `https://www.gallup.com.pk/wp/wp-json/custom/v1/post/related/${id}`;

	const res = await axios.get(getString);
	console.log(res);
	return res.data.map((d)=>({
		id : d.ID,
		title : d.post_title
	}));
};

async function loadPosts(getParams, idCategoryMap){

	let paramsString = Object.keys(getParams).map((key)=>{
		let val = getParams[key];
		return `${key}=${val}`
	}).join('&')

	paramsString = '&' + paramsString;

	let getString = `https://www.gallup.com.pk/wp/wp-json/wp/v2/posts?per_page=100${paramsString}`

	console.log(getString);

	const res = await axios.get(getString);
	console.log(res);
	return {
		posts: res.data.map(d => getResObj(d, idCategoryMap)),
		totalPosts: res.headers['x-wp-total']
	};
};

function getResObj(d, idCategoryMap) {
	let date = new Date(d.date);

	return {
		id : d.id,
		year : date.getFullYear(),
		date: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`,
		heading: decodeHtml(d.title.rendered),
		image: d.jetpack_featured_media_url,
		theme: idCategoryMap?.[d?.categories?.[0]]?.name,
		text: extractFirstPara(d.excerpt.rendered),
		categories: d?.categories,
		content : d.content.rendered,
		english_pdf : d.english_pdf,
		urdu_pdf : d.urdu_pdf
	}
}

function extractFirstPara(htmlString) {
	let parsedHTML = domParser.parseFromString(htmlString, 'text/html');
	let firstPara = parsedHTML.getElementsByTagName('p')[0];
	let firstParaText = firstPara ? firstPara.innerText : '';
	return firstParaText || 'No P tag';
}

var txt = document.createElement("textarea");

function decodeHtml(html) {
	txt.innerHTML = html;
	return txt.value;
}

async function lazyLoadPosts(page, getParams, postsToPage, postsPerPage, totalPosts, idCategoryMap) {
	let offset = (page - 1) * postsPerPage;
	//make make efficent with sliding window
	for(let i = 1; i < 6 ; i++){
		let firstPrevPagePost = postsPerPage * (page - i);
		if(firstPrevPagePost > -1 && firstPrevPagePost < totalPosts && !postsToPage[firstPrevPagePost]){
			offset = firstPrevPagePost;
		}
	}
	console.log(offset);
	getParams.offset = offset;
	let res = await this.loadPosts(getParams,idCategoryMap);

	let newPostsToPage = [...postsToPage];
	res.posts.forEach((d,i)=>{
		newPostsToPage[i + offset] = d;
	})

	return {
		posts : newPostsToPage,
		totalPostsToPage : res.totalPosts
	}
}

export default {
	lazyLoadPosts,
	loadPosts,

	decodeHtml,
	extractFirstPara,
	loadYTPosts,
	loadYTStats,
	getResObj,
	loadRelatedPosts
}
