import React, { Component } from 'react';
import withAnimateOnPropChange from './propChangeAnimation'
import styles from '../css-modules/section_title.module.css';

class Animation extends Component {

	constructor(props){
		super(props);
		this.state = {
			animationState : null,
			animating : false,
			animationRefs : {}
		}
		this.addAnimationRef = this.addAnimationRef.bind(this);
	}

	addAnimationRef = (key) => (el) =>{
		this.state.animationRefs[key] = el;
	}

	static getDerivedStateFromProps(props,state){
		if(props.begin && !state.animating){
			return {
				animationState : 0,
				animating : true
			}
		}

		return null;
	}

	async componentDidUpdate(){
		await new Promise((res)=>{
			this.props.animationStateFunctions[this.state.animationState](this.state.animationRefs, res);
		})

		if(this.state.animationState < this.props.animationStates.length - 1){
			this.setState({
				animationState : this.state.animationState + 1,
				animationRefs : {}
			})
		}
	}

  render () {
		let childFunction = this.props.children;
		return childFunction(this.props.animationStates[this.state.animationState], this.addAnimationRef)
  }
}

export default Animation;
