import React, { Component } from "react";
import styles from "../css-modules/home_banner.module.css";
import ReactMarkdown from "react-markdown";
import { MdChevronRight } from "react-icons/md";
import { MdChevronLeft } from "react-icons/md";
import { TimelineMax, Power3 } from "gsap";
import placeholder from "../resources/placeholder.jpg";
import { Link } from "react-router-dom";
import ClampLines from "react-clamp-lines";
import classNames from "classnames";
import LazyLoad from "react-lazy-load";

class HomeBanner extends Component {
  constructor(props) {
    super(props);
    this.state = { currIndex: 2 };
    this.domParser = new DOMParser();
  }

  componentDidMount() {
    this.setState({
      bannerPosts: this.props.bannerPosts,
      currIndex: 2,
    });

    document.getElementById("1").style.opacity = "0";
    document.getElementById("0").style.opacity = "0";

    /*  const parent=this;

    this.intervalID = setInterval(function(){
      parent.increaseIndex();
    }, 7500); */
  }

  /*  componentWillUnmount() {
    clearInterval(this.intervalID);
  } */

  increaseIndex = () => {
    var currIndex = this.state.currIndex;
    this.setState({
      disabled: true,
    });
    if (!this.state.disabled) {
      if (currIndex === 2) {
        this.timelineFunction(
          document.getElementById("2"),
          document.getElementById("1"),
          document.getElementById("0"),
          1
        );
      } else if (currIndex === 1) {
        this.timelineFunction(
          document.getElementById("1"),
          document.getElementById("0"),
          document.getElementById("2"),
          0
        );
      } else if (currIndex === 0) {
        this.timelineFunction(
          document.getElementById("0"),
          document.getElementById("2"),
          document.getElementById("1"),
          2
        );
      }
    }
  };

  decreaseIndex = () => {
    var currIndex = this.state.currIndex;
    this.setState({
      disabled: true,
    });
    if (!this.state.disabled) {
      if (currIndex === 2) {
        this.timelineFunction(
          document.getElementById("2"),
          document.getElementById("0"),
          document.getElementById("1"),
          0
        );
      } else if (currIndex === 1) {
        this.timelineFunction(
          document.getElementById("1"),
          document.getElementById("2"),
          document.getElementById("0"),
          2
        );
      } else if (currIndex === 0) {
        this.timelineFunction(
          document.getElementById("0"),
          document.getElementById("1"),
          document.getElementById("2"),
          1
        );
      }
    }
  };

  timelineFunction = (node, nextNode, thirdNode, nextIndex) => {
    new TimelineMax({
      onComplete: this.setState({ currIndex: nextIndex, disabled: false }),
    })
      .to(
        node,
        0.5,
        {
          autoAlpha: 1,
          ease: Power3.easeInOut,
        },
        0
      )
      .to(
        node,
        0.5,
        {
          "z-index": 1,
          ease: Power3.easeInOut,
        },
        0
      )
      .fromTo(
        node.childNodes[0].childNodes[0],
        0.6,
        {
          autoAlpha: 1,
        },
        {
          autoAlpha: 0,
          ease: Power3.easeInOut,
        },
        0
      )
      .fromTo(
        node.childNodes[0].childNodes[1],
        0.75,
        {
          width: "100%",
        },
        {
          width: 0,
          ease: Power3.easeInOut,
        },
        0
      )
      .fromTo(
        node,
        1,
        {
          background: "white",
        },
        {
          background: "none",
          ease: Power3.easeInOut,
        },
        0
      )
      .fromTo(
        node.childNodes[1],
        0.9,
        {
          autoAlpha: "1",
          y: "0",
        },
        {
          autoAlpha: 0,
          y: "-30",
          ease: Power3.easeInOut,
        },
        0
      )
      .fromTo(
        thirdNode,
        0.5,
        {
          autoAlpha: "0",
        },
        {
          autoAlpha: 0,
          ease: Power3.easeInOut,
        },
        0
      )
      .to(
        nextNode,
        0.5,
        {
          autoAlpha: 1,
          ease: Power3.easeInOut,
        },
        0
      )
      .to(
        nextNode,
        0.75,
        {
          "z-index": 2,
          ease: Power3.easeInOut,
        },
        0
      )
      .fromTo(
        nextNode.childNodes[0].childNodes[0],
        0.9,
        {
          autoAlpha: 0,
        },
        {
          autoAlpha: 1,
          ease: Power3.easeInOut,
        },
        0.7
      )
      .fromTo(
        nextNode.childNodes[0].childNodes[1],
        0.75,
        {
          width: "0",
        },
        {
          width: "100%",
          ease: Power3.easeInOut,
        },
        0.7
      )
      .fromTo(
        nextNode.childNodes[1],
        0.9,
        {
          autoAlpha: "0",
          y: "-30",
        },
        {
          autoAlpha: 1,
          y: "0",
          ease: Power3.easeInOut,
        },
        0.7
      );
  };

  extractFirstPara = (htmlString) => {
    let parsedHTML = this.domParser.parseFromString(htmlString, "text/html");
    let firstPara = parsedHTML.getElementsByTagName("p")[0];
    let firstParaText = firstPara ? firstPara.innerText : "";
    return firstParaText || "No P tag";
  };

  render() {
    // const { bannerPosts } = this.state;
    // if(bannerPosts){
    //   bannerPosts.map((item, index) => {
    //     console.log(index);
    //     console.log(item);
      
    //     console.log(item.id);
    //     console.log(item.date);
    //   });
    // }
    return (
      <div className={styles.home_banner_container}>
        <div
          onClick={() => this.decreaseIndex()}
          className={classNames(styles.desktopChev, styles.prevSlideChev)}
        >
          <MdChevronLeft />
        </div>
        <div className={styles.home_banner_article_container}>
          {this.props.bannerPosts &&
            this.props.bannerPosts.length > 0 &&
            this.props.bannerPosts
              .slice(0)
              .reverse()
              .map((bannerPost, index) => (
                <div
                  key={index}
                  id={index}
                  className={styles.home_banner_article}
                >
                  <div className={styles.home_banner_image_container}>
                    <div className={styles.category_container}>
                      <p className={styles.category_heading}>
                        <i>
                          {
                            this.props.categories.filter(
                              (category) =>
                                category.id === bannerPost.categories[0]
                            )[0].name
                          }
                        </i>
                      </p>
                    </div>
                    {bannerPost.jetpack_featured_media_url.length > 0 ? (
                      <LazyLoad><img
                        src={bannerPost.jetpack_featured_media_url}
                        className={styles.home_banner_image}
                        alt="post banner"
                      /></LazyLoad>
                    ) : (
                      <LazyLoad><img
                        src={placeholder}
                        className={styles.home_banner_image}
                        alt="post banner"
                      /></LazyLoad>
                    )}
                  </div>
                  <div className={styles.home_banner_text_container}>
                    <div className={styles.banner_heading}>
                      <ReactMarkdown
                        source={bannerPost.title.rendered}
                        escapeHtml={false}
                      />
                    </div>
                    <div className={styles.excerptArticle}>
                      <ClampLines
                        text={this.extractFirstPara(
                          bannerPost.excerpt.rendered
                        )}
                        id={bannerPost.id}
                        lines={3}
                        ellipsis="..."
                        innerElement="p"
                        buttons={false}
                      />
                      <Link
                        to={{ pathname: `/post/${bannerPost.id}` }}
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <p className={styles.readMore}>
                          <i>Read More</i>
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
        </div>
        <div
          onClick={() => this.increaseIndex()}
          className={classNames(styles.desktopChev, styles.nextSlideChev)}
        >
          <MdChevronRight />
        </div>
        <div className={styles.mobile_chevs}>
          <div
            onClick={() => this.decreaseIndex()}
            className={styles.prevSlideChev}
          >
            <MdChevronLeft />
          </div>
          <div
            onClick={() => this.increaseIndex()}
            className={styles.nextSlideChev}
          >
            <MdChevronRight />
          </div>
        </div>
      </div>
    );
  }
}

export default HomeBanner;
