import React, { Component } from "react";
import styles from "../css-modules/trending.module.css";
import { TimelineMax, Power3 } from "gsap";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import remarkStripHtml from 'remark-strip-html';

const plugins = [remarkStripHtml];

class Trending extends Component {
  constructor(props) {
    super(props);
    this.state = { currIndex: 9 };

    fetch(
      "https://gallup.com.pk/wp/wp-json/wp/v2/posts?categories=438&per_page=10"
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        this.setState({ trendingPosts: data, currIndex: 2 });
      })
      .catch((error) => {
        console.error("Error fetching homebanner:", error);
      });
  }

  intervalID = 0;

  componentDidMount() {
    var parent = this;
    this.setState({ currIndex: 9 });
    this.intervalID = setInterval(function () {
      var currIndex = parent.state.currIndex;
      var nextIndex;
      var currId;
      var nextId;
      if (currIndex !== 0) {
        nextIndex = currIndex - 1;
        currId = "trending" + currIndex;
        nextId = "trending" + nextIndex;
      } else {
        nextIndex = 9;
        currId = "trending" + currIndex;
        nextId = "trending" + nextIndex;
      }
      parent.timelineFunction(
        document.getElementById(currId),
        document.getElementById(nextId),
        nextIndex
      );
    }, 3500);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  timelineFunction = (node, nextNode, nextIndex) => {
    new TimelineMax({
      onComplete: this.setState({ currIndex: nextIndex }),
    })
      .fromTo(
        node,
        1.1,
        {
          opacity: "1",
          y: 0,
        },
        {
          opacity: 0,
          y: -50,
          ease: Power3.easeInOut,
        },
        0
      )
      .fromTo(
        nextNode,
        1.1,
        {
          opacity: "0",
          y: "50",
        },
        {
          opacity: 1,
          y: "0",
          ease: Power3.easeInOut,
        },
        0
      );
  };

  onMouseOver = () => {
    clearInterval(this.intervalID);
  };

  onMouseOut = () => {
    var parent = this;
    this.intervalID = setInterval(function () {
      var currIndex = parent.state.currIndex;
      var nextIndex;
      var currId;
      var nextId;
      if (currIndex !== 0) {
        nextIndex = currIndex - 1;
        currId = "trending" + currIndex;
        nextId = "trending" + nextIndex;
      } else {
        nextIndex = 9;
        currId = "trending" + currIndex;
        nextId = "trending" + nextIndex;
      }
      parent.timelineFunction(
        document.getElementById(currId),
        document.getElementById(nextId),
        nextIndex
      );
    }, 3000);
  };

  render() {
    const { trendingPosts } = this.state;

    return (
      <div className={styles.trending_Container}>
        <div className={styles.trending_title}>
          <p>
            <i>Trending</i>
            {this.props.trending}
          </p>
        </div>
        <div className={styles.trending_post}>
          {trendingPosts &&
            trendingPosts.length > 0 &&
            trendingPosts
              .slice(0)
              .reverse()
              .map((trendingPost, index) => (
                <div
                  key={index}
                  id={"trending" + index}
                  onMouseOver={this.onMouseOver}
                  onMouseOut={this.onMouseOut}
                  className={styles.trending_post_text_container}
                  style={
                    index === this.state.currIndex
                      ? { opacity: "1" }
                      : { opacity: "0" }
                  }
                >
                  <Link
                    to={{ pathname: `/post/${trendingPost.id}` }}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <div className={styles.trending_post_text}>
                      <ReactMarkdown
                    source={trendingPost.title.rendered}
                    escapeHtml={false}
                    plugins={plugins}
                  />
                      {/* <ClampLines
                        text={trendingPost.title.rendered}
                        id={"trending" + index}
                        lines={3} // Adjust the number of lines as needed
                        ellipsis="..."
                        // innerElement="p"
                        buttons={false}
                      /> */}
                    </div>
                  </Link>
                </div>
              ))}
        </div>
      </div>
    );
  }
}

export default Trending;
