import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styles from "../css-modules/search.module.css";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import classNames from "classnames";
import Fade from "react-reveal/Fade";
import Popover from "@material-ui/core/Popover"; // Import Popover component
import axios from "axios";
import _ from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: 0,
      quickLinks: [],
      searchResults: [],
      showPopover: false,
      anchorEl: null,
      loading: false,
    };
    this.inputRef = React.createRef();

    this.changeCategory = this.changeCategory.bind(this);
    this.executeSearch = this.executeSearch.bind(this);
    this.openQuickLink = this.openQuickLink.bind(this);
    this.handlePostSelection = this.handlePostSelection.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

    // Fetch quick links
    fetch("https://www.gallup.com.pk/wp/wp-json/wp/v2/quick_link")
      .then((response) => response.json())
      .then((data) => {
        const updatedData = data.map((item) => {
          if (
            item.slug === "coronavirus" &&
            item.title.rendered === "Coronavirus"
          ) {
            return {
              ...item,
              slug: "daily-polls",
              title: { rendered: "Daily Polls" },
              acf: {
                ...item.acf,
                link_text: "Daily Polls",
                id: "437,438",
              },
            };
          }
          return item;
        });

        this.setState({ quickLinks: updatedData });
      })
      .catch((error) => {
        console.error("Error fetching quick links:", error);
      });

    this.debouncedSearch = _.debounce(this.executeSearch, 500);
  }

  openQuickLink(quickLinkId) {
    let defaultState =
      this.props.pageStateInitFunctions.getSearchResInitState();

    defaultState.filterState.onMountFilter = true;
    defaultState.filterState.filtered = true;

    defaultState.filterState.categoriesFilterSet.add(quickLinkId + "");
    defaultState.filterState.params.categories = [
      ["categories", quickLinkId + ""],
    ];

    this.props.setPageStateWithoutUpdate("searchRes", defaultState);
    this.props.history.push({ pathname: `/search` });
  }

  changeCategory(e) {
    const category = e.target.value;
    this.setState({
      category: category + "",
    });
  }

  handleInputChange(e) {
    const term = e.target.value;
    this.inputRef.current.value = term;
    this.debouncedSearch();
  }

  executeSearch() {
    const term = window.encodeURIComponent(this.inputRef.current.value);
    if (!term) {
      this.setState({ searchResults: [], showPopover: false });
      return;
    }

    this.setState({
      loading: true,
      showPopover: true,
      anchorEl: this.inputRef.current,
    });

    axios
      .get(`https://gallup.com.pk/wp/wp-json/wp/v2/posts?search=${term}`)
      .then((response) => {
        this.setState({
          searchResults: response.data,
          showPopover: true,
          anchorEl: this.inputRef.current,
          loading: false,
        });
      })
      .catch((error) => {
        console.error("Error fetching posts:", error);
        this.setState({ loading: false, showPopover: true });
      });
  }

  Search() {
    const term = window.encodeURIComponent(this.inputRef.current.value);
    if (!term) {
      this.setState({ searchResults: [], showPopover: false });
      return;
    }
    this.props.history.push(`/search/${term}`);
  }

  handlePostSelection(postId) {
    this.props.history.push(`/post/${postId}`);
    this.setState({ showPopover: false });
  }

  handlePopoverClose = () => {
    this.setState({ showPopover: false });
  };
  parseHeading = (title) => {
    const parser = new DOMParser();
    const parsedHeading = parser.parseFromString(title, "text/html").body
      .textContent;
    return parsedHeading;
  };
  render() {
    const { quickLinks, searchResults, showPopover, anchorEl, loading } =
      this.state;
    return (
      <div>
        {this.props.titleSearch && (
          <div className={styles.title_search_container}>
            <div
              className={classNames(
                styles.margined_input,
                "input_container_title",
                "title_input_container"
              )}
            >
              <TextField
                inputRef={this.inputRef}
                color="primary"
                id="standard-basic"
                label="What are you searching for?"
                onChange={this.handleInputChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    this.Search();
                  }
                }}
              />
              <Popover
                open={showPopover}
                anchorEl={anchorEl}
                onClose={this.handlePopoverClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <div className={styles.popoverContent}>
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : searchResults?.length > 0 ? (
                    searchResults?.map((post) => (
                      <div
                        key={post.id}
                        onClick={() => this.handlePostSelection(post.id)}
                        className={styles.popoverItem}
                      >
                        <p className={styles.popoverTitle}>
                          {this.parseHeading(post?.title?.rendered)}
                        </p>
                      </div>
                    ))
                  ) : (
                    <p>No results found</p>
                  )}
                </div>
              </Popover>

              <div className={styles.quick_links}>
                {quickLinks &&
                  quickLinks.length > 0 &&
                  quickLinks.map((quickLink, index) => (
                    <Fade key={index} bottom delay={(index + 1) * 500}>
                      <div
                        onClick={() => this.openQuickLink(quickLink.acf.id)}
                        className={styles.quick_link}
                      >
                        <p>{quickLink.acf.link_text}</p>
                      </div>
                    </Fade>
                  ))}
              </div>
            </div>
            <div
              className={classNames(
                styles.margined_input,
                "drop_down_container"
              )}
            >
              <FormControl>
                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  defaultValue={0}
                  onChange={this.changeCategory}
                >
                  <MenuItem value={0}>All Categories</MenuItem>
                  {this.props.categories &&
                    this.props.categories.length > 0 &&
                    this.props.categories
                      .filter((d) => d.parent === 0)
                      .map((category, index) => (
                        <MenuItem key={category.id} value={category.id}>
                          {category.name}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </div>
            <Button
              onClick={this.Search}
              style={{ marginTop: "8px", alignSelf: "baseline" }}
              className={`${"yellow_button"} ${"home_yellow_button"}`}
              variant="outlined"
            >
              Search
            </Button>
          </div>
        )}
        {this.props.searchResult && (
          <div className={styles.search_container}>
            <div
              className={classNames(styles.margined_input, "input_container")}
            >
              <TextField
                inputRef={this.inputRef}
                color="secondary"
                id="standard-basic"
                label="Change search term"
                onChange={this.handleInputChange}
              />
            </div>
            <Button
              onClick={this.Search}
              style={{ marginTop: "8px" }}
              className="blue_button_filled"
              variant="contained"
            >
              Search
            </Button>
          </div>
        )}
        {this.props.standardSearch && (
          <div className={styles.search_container}>
            <div
              className={classNames(styles.margined_input, "input_container")}
            >
              <TextField
                inputRef={this.inputRef}
                color="secondary"
                id="standard-basic"
                label="What are you searching for?"
                onChange={this.handleInputChange}
              />
            </div>
            <Button
              onClick={this.executeSearch}
              style={{ marginTop: "8px" }}
              className="blue_button_filled"
              variant="contained"
            >
              Search
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(SearchBar);
