import React, { Component } from 'react';
import styles from '../css-modules/section_title.module.css';

let animationStates = {
	MOUNT_COMPONENT : 0,
	MOUNT_ENTER_ELEMENT : 1,
	ANIMATE : 2,
	UNMOUNT_EXIT_ELEMENT : 3
}
function withAnimateOnPropChange(WrappedComponent){
	return class PropChangeAnimation extends Component {
		constructor(props){
			super(props);
			this.state = {
				animationState : animationStates.MOUNT_ENTER_ELEMENT,
				prevProps : null,
				animationStartProps : null,
				shouldAnimate : false
			}
			this.currRef = React.createRef();
			this.prevRef = React.createRef();
			window.cacheComp = this;
		}

		static getDerivedStateFromProps(props, state){
			if(state.prevProps && props.shouldStoreProps(state.prevProps, props)){
				return {
					animationStartProps : state.prevProps,
					prevProps : props
				}
			}
			return {
				prevProps : props,
				animationStartProps : null
			}
		}



		componentDidUpdate(){
			if(this.state.animationStartProps){
				this.props.getAnimation(this.props, this.currRef.current, this.prevRef.current, (function(){
					if(this.props.unmountOnExit){
						this.setState({
							animationStartProps : null
						})
					}
				}).bind(this))
			}
		}

		componentWillUnmount(){
		}

		render () {
			let jsx = (
				<React.Fragment>
					<WrappedComponent key={this.props.route} {...this.props} animationRef={this.currRef}/>
					{
					this.state.animationStartProps
					&&
					<WrappedComponent key={this.state.animationStartProps.route} {...this.state.animationStartProps} animationRef={this.prevRef}/>
					}
				</React.Fragment>
			)
			return jsx;
		}
	}
}



export default withAnimateOnPropChange;
