import React, { Component } from 'react';
import styles from '../css-modules/home_media.module.css';
import placeholder from '../resources/placeholder.jpg';
import ReactMarkdown from 'react-markdown';
import {Link} from 'react-router-dom';
import { withRouter } from 'react-router-dom';

class PublicationsRow extends Component {

	constructor(props){
		super(props);
		window.pr = this;
		this.showAllInternationalPubs = this.showAllInternationalPubs.bind(this);
  }

  componentDidMount() {
    console.log(this.props.latestPublications);
  }

  showAllInternationalPubs(){
    let defaultState = this.props.pageStateInitFunctions.getPublicationsInitState();

    defaultState.filterState.onMountFilter = true;
    defaultState.filterState.filtered = true;

    defaultState.filterState.categoriesFilterSet.add('450');
    defaultState.filterState.params.categories = [['categories', '450']]

    this.props.setPageStateWithoutUpdate('publications',defaultState);
    this.props.history.push({pathname : `/publications`});
  }

  render () {
    return (
			<div>
				<div className={styles.home_media_container}>
					{this.props.latestPublications &&  this.props.latestPublications.map((mediaResearch, index) => (
						<div key={index} className={styles.home_media}>
							<Link to={`/post/${mediaResearch.id}`} style={{textDecoration: 'none', color: 'inherit'}}>
								<div className={styles.separator}></div>
								<div className={styles.home_media_text_container}>
									<div className={styles.media_heading}>
										<ReactMarkdown
											source={mediaResearch.title.rendered}
											escapeHtml={false}
										/>
									</div>
								</div>
								<div className={styles.home_media_banner_image_container}>
									{mediaResearch.jetpack_featured_media_url.length > 0 ?
										<img src={mediaResearch.jetpack_featured_media_url} className={styles.home_media_banner_image} alt={mediaResearch.title.rendered}/>
										:
										<img src={placeholder} className={styles.home_media_banner_image} alt={mediaResearch.title.rendered}/>
									}
									{mediaResearch.jetpack_featured_media_url.length > 0 ?
										<div className={styles.home_media_banner_image_bg} style={{backgroundImage: `url(${mediaResearch.jetpack_featured_media_url})`}}></div>
										:
										<div className={styles.home_media_banner_image_bg} style={{backgroundImage: `url(${placeholder})`}}></div>
									}
								</div>
							</Link>
						</div>
					))}
				</div>
			</div>
    )
  }
}

export default withRouter(PublicationsRow);
