import React, { Component } from "react";
import BounceLoader from "react-spinners/BounceLoader";
import Fade from "react-reveal/Fade";
import NavBar from "../components/navbar";
import PublicationsRow from "../components/publicationsRow";
import SectionTitle from "../components/sectiontitle";
import Footer from "../components/footer";
import CachedPrevRender from "../components/CachedPrevRenderDisplay";
import commonStyles from "../css-modules/common.module.css";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import styles from "../css-modules/gallupInt.module.css";
import axios from "axios";
import Button from "@material-ui/core/Button";
import WorldMap from "../components/WorldMap";

import first from "../resources/timeline/1947 Loxwood, UK.jpg";
import second from "../resources/timeline/1968 Delhi, India.jpg";
import third from "../resources/timeline/1980 Selsdon Park, UK.jpg";
import fourth from "../resources/timeline/1983 San Francisco, USA​.jpg";
import fifth from "../resources/timeline/1987 Kawana.jpg";
import sixth from "../resources/timeline/1995 Seoul, Korea.jpg";
import seventh from "../resources/timeline/1997 London, UK.jpg";
import eigth from "../resources/timeline/1998 Melbourne,  Australia.jpg";
import ninth from "../resources/timeline/2000 Japan.jpg";
import tenth from "../resources/timeline/2004 Ho Chi Minh City, Vietnam.jpg";
import eleventh from "../resources/timeline/2005 Belgrade, Serbia.jpg";
import twelvth from "../resources/timeline/2007 Luxembourg.jpg";
import thirteenth from "../resources/timeline/2008 Madrid, Spain.jpg";
import fourteenth from "../resources/timeline/2010 Vienna, Austria.jpg";
import fifteenth from "../resources/timeline/2011 Moscow, Russia.jpg";
import sixteenth from "../resources/timeline/2012 Istanbul, Turkey.jpg";
import seventeenth from "../resources/timeline/2013 Italy.jpg";
import eighteenth from "../resources/timeline/2014 Canada.jpg";
import nineteenth from "../resources/timeline/2015 Dublin, Ireland.jpg";
import twenty from "../resources/timeline/2017 Greece.jpg";
import twentyone from "../resources/timeline/2019 Barcelona.jpg";
import { withRouter } from "react-router-dom";

class Team extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "0",
    };
  }

  async componentDidMount() {
    if (!this.props.loadedData) {
      let data = await axios.get(
        "https://gallup.com.pk/wp/wp-json/wp/v2/posts?categories=861&per_page=3"
      );

      this.props.setPageState("gallupInternational", {
        loadedData: true,
        latestPublications: data.data,
      });
    }
  }

  imageGallery = [
    {
      name: "Loxwood, UK",
      source: first,
      year: "1947",
    },
    {
      name: "Delhi, India",
      source: second,
      year: "1968",
    },
    {
      name: "Selsdon Park, UK",
      source: third,
      year: "1980",
    },
    {
      name: "San Francisco, USA​",
      source: fourth,
      year: "1983",
    },
    {
      name: "Kawana",
      source: fifth,
      year: "1987",
    },
    {
      name: "Seoul, Korea",
      source: sixth,
      year: "1995",
    },
    {
      name: "London, UK",
      source: seventh,
      year: "1997",
    },
    {
      name: "Melbourne,  Australia",
      source: eigth,
      year: "1998",
    },
    {
      name: "Japan",
      source: ninth,
      year: "2000",
    },
    {
      name: "Ho Chi Minh City, Vietnam",
      source: tenth,
      year: "2004",
    },
    {
      name: "Belgrade, Serbia",
      source: eleventh,
      year: "2005",
    },
    {
      name: "Luxembourg",
      source: twelvth,
      year: "20072007",
    },
    {
      name: "Madrid, Spain",
      source: thirteenth,
      year: "2008",
    },
    {
      name: "Vienna, Austria",
      source: fourteenth,
      year: "2010",
    },
    {
      name: "2011 Moscow, Russia",
      source: fifteenth,
      year: "2011",
    },
    {
      name: "Istanbul, Turkey",
      source: sixteenth,
      year: "2012",
    },
    {
      name: "Italy",
      source: seventeenth,
      year: "2013",
    },
    {
      name: "Canada",
      source: eighteenth,
      year: "2014",
    },
    {
      name: "Dublin, Ireland",
      source: nineteenth,
      year: "2015",
    },
    {
      name: "Greece",
      source: twenty,
      year: "2017",
    },
    {
      name: "Barcelona",
      source: twentyone,
      year: "2019",
    },
  ];

  JSX = {
    0: () => ({
      jsx: (
        <div className={styles.section_content} value="0">
          <div className="content_container">
            <div className={styles.flexed_title}>
              <p className={styles.section_title}>
                Gallup International Publications
              </p>
              <Button
                onClick={this.showAllInternationalPubs}
                className="white_button"
                variant="outlined"
              >
                View All
              </Button>
            </div>
            <PublicationsRow
              setPageState={this.props.setPageState}
              pageStateInitFunctions={this.props.pageStateInitFunctions}
              setPageStateWithoutUpdate={this.props.setPageStateWithoutUpdate}
              latestPublications={this.props.latestPublications}
            />
            <p style={{ marginTop: "30px" }} className="section_title">
              Member countries across the world
            </p>
            <WorldMap />
          </div>
        </div>
      ),
    }),
    1: () => ({
      jsx: (
        <div value="1" className={styles.section_content}>
          <div className="content_container">
            <p className="section_title">
              Gallup International Through the Years
            </p>
            <div className={styles.photo_card_container}>
              {this.imageGallery &&
                this.imageGallery.length > 0 &&
                this.imageGallery.map((galleryPost, index) => (
                  <div key={index} className={styles.photo_card}>
                    <div className={styles.photo_image_container}>
                      <img
                        className={styles.photo_card_photo}
                        src={galleryPost.source}
                      ></img>
                      <Fade bottom delay={index * 60}>
                        <div className={styles.photo_card_content}>
                          <p className={styles.photo_date}>
                            {galleryPost.year}
                          </p>
                          <p className={styles.photo_location}>
                            {galleryPost.name}
                          </p>
                        </div>
                      </Fade>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      ),
    }),
  };

  handleChange = (e, newValue) => {
    var value = newValue.toString();
    this.setState({
      value: value,
    });
  };

  openQuickLink(quickLinkId) {
    let defaultState =
      this.props.pageStateInitFunctions.getSearchResInitState();

    defaultState.filterState.onMountFilter = true;
    defaultState.filterState.filtered = true;

    defaultState.filterState.categoriesFilterSet.add(quickLinkId + "");
    defaultState.filterState.params.categories = [
      ["categories", quickLinkId + ""],
    ];

    this.props.setPageStateWithoutUpdate("searchRes", defaultState);

    this.props.history.push("/search");
  }

  render() {
    var jsxFunction;
    if (this.state.value) {
      jsxFunction = this.JSX[this.state.value]().jsx;
    }
    return (
      <div
        id="scroll_view"
        ref={this.props.animationRef}
        className={commonStyles.view}
      >
        {!(this.props.loadedData && this.props.loadedAppData) ? (
          <div className="loading">
            <BounceLoader
              sizeUnit={"px"}
              size={40}
              color={"#1E4D79"}
              loading={!(this.props.loadedData && this.props.loadedAppData)}
            />
          </div>
        ) : (
          <React.Fragment>
            <NavBar
              links={[
                { linkText: "Home", to: "" },
                { linkText: "ABOUT US", to: "about" },
                { linkText: "What We Do", to: "gallup" },
                { linkText: "News & Updates", to: "news" },
                { linkText: "Publications", to: "publications" },
                {
                  linkText: "Daily Polls",
                  to: "daily-polls",
                  onClick: () => this.openQuickLink("437,438"),
                },
                { linkText: "Interactives", to: "datavis" },
                { linkText: "Gallup International", to: "gallupinternational" },
                { linkText: "Contact Us", to: "contact" },
              ]}
            />
            <SectionTitle title="Gallup International" />
            <div className="padded_section">
              <div className="content_container">
                <div className={styles.tabs_container}>
                  <Paper square style={{ width: "fit-content" }}>
                    <Tabs
                      value={parseInt(this.state.value)}
                      indicatorColor="primary"
                      textColor="primary"
                      onChange={this.handleChange}
                    >
                      <Tab label="Gallup International" />
                      <Tab label="Timeline" />
                    </Tabs>
                  </Paper>
                </div>
              </div>
            </div>

            <div className={styles.tab_content_wrapper}>
              <CachedPrevRender
                value={this.state.value}
                shouldUpdate={(prevProps, currProps) => {
                  let update =
                    currProps.value && prevProps.value !== currProps.value;
                  return update;
                }}
              >
                {jsxFunction}
              </CachedPrevRender>
            </div>

            <Footer
              links={[
                { linkText: "Affiliations & Memberships", to: "about" },
                { linkText: "Contact Us", to: "contact" },
              ]}
              researchAreas={this.props.topSections.map((d) => {
                let data = this.props.idSectionMap[d];
                return { linkText: data.name, id: d };
              })}
              pageStateInitFunctions={this.props.pageStateInitFunctions}
              setPageStateWithoutUpdate={this.props.setPageStateWithoutUpdate}
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default withRouter(Team);
